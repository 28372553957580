<template>
  <div class="container pr pt68">
    <Header @input="input"></Header>
    <!-- <img v-for="(item, index) in 3" :key="index" class="img" src="../assets/static/images/aboutUs_banner.png" alt="" /> -->
    <div v-html="data.content"></div>
    <div @click="toOrder" class="colf tc btn fs18 cursor">立即报名</div>
    <Footer></Footer>
  </div>
</template>
<script>
import { useHead } from "@unhead/vue";
import { getCurrentInstance } from "vue";
import axios from "@/axios";
import router from "@/router";
import { ElMessage, ElMessageBox } from "element-plus";
export default {
  name: "productDetail",
  setup() {
    useHead({
      title: "参加活动",
      meta: [
        { name: "description", content: "This is my page description" },
        { property: "og:title", content: "My Page Title" },
        { property: "og:description", content: "This is my page description" },
        { property: "og:url", content: "https://example.com/my-page" },
        { property: "og:image", content: "https://example.com/my-image.jpg" },
      ],
      link: [{ rel: "canonical", href: "https://example.com/my-page" }],
    });
  },
  data() {
    return {

      activity_id: '',
      data: ''
    };
  },
  created() {
    this.activity_id = this.$route.params.activity_id
    this.getDetail();
  },
  methods: {
    toOrder() {
      router.push({ name: 'confirmActive', params: { activity_id: this.activity_id } });
    },
    getDetail() {
      axios.post('activity/activity_detail', {
        activity_id: this.activity_id
      }).then(res => {
        res.data.price = parseFloat(res.data.price)
        this.data = res.data
      })
    }
  },
};
</script>
<style lang="less" scoped>
.container {
  background: #fff;
  width: 100vw;
  overflow-x: hidden;
}

.img {
  width: 100%;
  height: auto;
  display: block;
  object-fit: cover;
}

.btn {
  width: 304px;
  text-align: center;
  height: 48px;
  line-height: 48px;
  background: #bba17b;
  border-radius: 5px;
  margin: 10px auto;
}
</style>