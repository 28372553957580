<!--
  时间：2024年05月15日 14:13:00
-->
<template>
    <div class="header_fix" ref="head_fix">
        <div class="head_nav flex-box">
            <router-link to="/">
                <img src="../assets/static/images/logo2.png" class="logo" alt="" />
            </router-link>
            <div class="flex-box flex-grow-1">
                <div class="flex-box fs14 mr45 cursor tab" @click="chooseNav(0)">
                    <div :class="nindex == 0 ? 'ac' : 'col6'">首页</div>
                </div>
                <div class="flex-box fs14 mr45 cursor tab" @click="chooseNav(1)">
                    <div :class="nindex == 1 ? 'ac' : 'col6'">商城</div>
                </div>
                <div class="flex-box fs14 mr45 cursor tab" @click="chooseNav(2)">
                    <div :class="nindex == 2 ? 'ac' : 'col6'">A CARE</div>
                </div>
                <div class="flex-box fs14 mr45 cursor tab" @click="chooseNav(3)">
                    <div :class="nindex == 3 ? 'ac' : 'col6'">售后</div>
                </div>
                <div class="flex-box fs14 mr45 cursor tab" @click="chooseNav(4)">
                    <div :class="nindex == 4 ? 'ac' : 'col6'">教程</div>
                </div>
                <div class="flex-box fs14 mr45 cursor tab" @click="chooseNav(5)">
                    <div :class="nindex == 5 ? 'ac' : 'col6'">活动</div>
                </div>
            </div>
            <div class="flex-box">
                <!-- <div class="ml30 flex-box fs12" :class="showinput ? 'search_part' : ''">
                    <input v-if="showinput" type="text" @input="input_name($event.target.value)" class="flex-grow-1"
                        placeholder="请输入关键词" />
                    <img @click="saerch" class="head_message cursor" src="../assets/static/icon/head_search.png"
                        alt="" />
                </div> -->
                <div class="ml30 pr" @click="viewMessage">
                    <img class="head_message cursor" src="../assets/static/icon/head_message.png" alt="" />
                    <div v-if="is_notice > 0" class="unred colf"></div>
                </div>
                <div class="ml30" @click="toCart">
                    <img class="head_message cursor" src="../assets/static/icon/head_cart.png" alt="" />
                </div>
                <div class="ml30" @click="toMine">
                    <img class="head_message cursor" src="../assets/static/icon/head_mine.png" alt="" />
                </div>
            </div>
        </div>
    </div>
    <!-- 消息 -->
    <div v-if="showMessage" class="message_part">
        <div v-for="(item, index) in notice_list" :key="index" class="message flex">
            <img class="icon mr15" src="../assets/static/icon/message_icon.png" alt="" />
            <div class="flex-grow-1">
                <div class="fs14 col10 fwb m-ellipsis">{{ item.title }}</div>
                <div class="main m-ellipsis-l2">
                    {{ item.desc }}
                </div>
                <div class="flex-box fs14">
                    <div @click="toDetail(index)" v-if="item.order_id > 0 || item.activity_id > 0 || item.repair_id > 0"
                        class="colb cursor">查看详情
                    </div>
                    <div class="flex-grow-1 tr cola">{{ item.createtime_text }}</div>
                </div>
            </div>
        </div>
    </div>
    <!-- 账号 -->
    <div v-if="showAccount" class=" account_part">
        <div v-for="(item, index) in 5" :key="index" class="message flex-box">
            <img class="head_message mr15" src="../assets/static/icon/head_mine.png" alt="" />
            <div class="fs14 col10 fwb m-ellipsis flex-grow-1">系统消息</div>
        </div>
    </div>
</template>

<script>
import router from "@/router";
import { useRoute } from 'vue-router';
import axios from "@/axios";
import { getCurrentInstance } from "vue";
import { ElMessage, ElMessageBox } from "element-plus";
export default {
    name: "Header",
    props: ["value"],
    // 局部注册的组件
    components: {},
    // 组件状态值
    data() {
        return {
            nindex: 0,
            showinput: false,
            showMessage: false,
            showAccount: false,
            is_notice: 0,
            notice_list: []
        };
    },
    // 侦听器
    watch: {
        nindex: function (newVal, oldVal) {
        },
    },
    created() {
        const route = useRoute();
        const currentPath = route.path;
        console.log(currentPath)
        switch (currentPath) {
            case '/':
                this.nindex = 0
                break;
            case '/shoppingCentre':
                this.nindex = 1
                break;
            case '/AGCARE':
                this.nindex = 2
                break;
            case '/afterSales':
                this.nindex = 3
                break;
            case '/tutorial':
                this.nindex = 4
                break;
            case '/activities':
                this.nindex = 5
                break;
            default:
                this.nindex = ''
                break;
        }
        this.user_notice()
    },
    mounted() {
    },
    // 组件方法
    methods: {
        user_notice() {
            axios.post('user/user_notice', {}).then(res => {
                this.is_notice = res.data
            })
        },
        toCart() {
            router.push('/shoppingCart');
        },
        toMine() {
            router.push('/myPage/0');
        },
        saerch() {
            this.showinput = true;
        },
        chooseNav(e) {
            let v = e;
            this.nindex = e;
            switch (e) {
                case 0:
                    router.push('/');
                    break;
                case 1:
                    router.push('/shoppingCentre');
                    break;
                case 2:
                    router.push('/AGCARE');
                    break;
                case 3:
                    router.push('/afterSales');
                    break;
                case 4:
                    router.push('/tutorial');
                    break;
                case 5:
                    router.push('/activities');
                    break;
            }
        },
        input_name(e) {
            this.$emit("input", e);
        },
        viewMessage() {
            if (this.showMessage) {
                // router.push('/noticeDetail/0')
                this.showMessage = !this.showMessage;
                this.showAccount = false;
            } else {
                axios.post('user/notice_list', {
                    page: 1,
                    pagesize: 10
                }).then(res => {
                    this.showMessage = !this.showMessage;
                    this.showAccount = false;
                    var data = res.data.data
                    for (var i in data) {
                        data[i].pay_price = parseFloat(data[i].pay_price)
                    }
                    this.notice_list = data
                })
            }

        },
        viewAccount() {
            this.showAccount = !this.showAccount;
            this.showMessage = false;
        },
        toDetail(index) {
            if (this.notice_list[index].is_detail == 1) {
                // router.push({
                //     name: 'noticeDetail',
                //     params: {
                //         notice_id: this.notice_list[index].id
                //     }
                // });
            } else if (this.notice_list[index].order_id) {
                router.push({
                    name: 'orderDetail',
                    params: {
                        order_id: this.notice_list[index].order_id
                    }
                });
            } else if (this.notice_list[index].activity_id) {
                router.push({
                    name: 'joinActive',
                    params: {
                        activity_id: this.notice_list[index].activity_id
                    }
                });
            } else if (this.notice_list[index].repair_id) {
                router.push({
                    name: 'myAfterSalesDetail',
                    params: {
                        id: this.notice_list[index].repair_id
                    }
                });
            }
        },
    },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<!--使用了scoped属性之后，父组件的style样式将不会渗透到子组件中，-->
<!--然而子组件的根节点元素会同时被设置了scoped的父css样式和设置了scoped的子css样式影响，-->
<!--这么设计的目的是父组件可以对子组件根元素进行布局。-->
<style lang="less" scoped>
.header_fix {
    width: 1440px;
    margin-left: auto;
    margin-right: auto;
    position: fixed;
    z-index: 99;
    width: 100%;
    height: 68px;
    background: #fff;
    left: 0;
    right: 0;
    top: 0;
}

.header_fix .head_nav {
    padding: 14px 120px 0 120px;
    height: 68;
}

.logo {
    width: 40px;
    height: 40px;
    display: block;
    border-radius: 50%;
    overflow: hidden;
    object-fit: cover;
    margin-right: 38px;
}

.ac,
.tab:hover {
    font-weight: bold;
    font-size: 14px;
    color: #0b0b0b !important;
}

.search_part {
    background: #eff0f1;
    border-radius: 17px;

    input {
        width: 195px;
        height: 34px;
        line-height: 34px;
        border-radius: 17px;
        padding-left: 17px;
        background: #eff0f1;
    }

    input::placeholder {
        color: #c0c1c2;
    }
}

.head_message {
    width: 34px;
    height: 34px;
    display: block;
    object-fit: cover;
    border-radius: 50%;
    overflow: hidden;
}

.unred {
    // font-size: 11px;
    // padding: 0 4px;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    overflow: hidden;
    // line-height: 15px;
    background: #ff3333;
    position: absolute;
    z-index: 2;
    top: -4px;
    right: -4px;
}

.message_part,
.account_part {
    width: 372px;
    max-height: 423px;
    overflow-y: scroll;
    background: #ffffff;
    box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.17);
    border-radius: 11px;
    position: fixed;
    top: 76px;
    right: 80px;
    z-index: 99;

    .message {
        border-bottom: 5px solid #f1f2f4;
        background: #fff;
        padding: 12px 20px 20px 28px;

        .icon {
            width: 45px;
            height: 45px;
            display: block;
            object-fit: cover;
        }
    }

    .main {
        font-size: 14px;
        color: #858586;
        line-height: 20px;
    }
}

.tab:hover {
    color: #0b0b0b !important;
}

.message:hover {
    background: #F1F2F4;
}
</style>
