<template>
  <div class="container pr">
    <Header @input="input"></Header>
    <div class="main">
      <div class="flex-box mb35 plr120">
        <div class="flex-grow-1 fs32 col10 fwb lh86">
          随心选择，超舒心购物体验
        </div>
        <!-- <img class="location mr5 cursor" src="../assets/static/icon/location_icon.png" alt="" />
        <div class="colb lh28 fs18 cursor">查看您附近的门店信息</div> -->
      </div>
      <div class="flex flex-wrap plr120">
        <div
          @click="toGoodsDetail(item.id)"
          v-for="(item, index) in newList"
          :key="index"
          class="part1 mr20 mb20 cursor"
        >
          <img class="img part_h" :src="oss + item.pc2_image" alt="" />
          <div class="title tc m-ellipsis">{{ item.name }}</div>
        </div>
      </div>

      <template v-if="categoryList.length > 0">
        <div class="fs24 col10 fwb lh86 plr120">{{ categoryList[0].name }}</div>
        <div class="tab-all mb20">
          <div class="tab-four">
            <swiper
              :loop="false"
              :autoplay="false"
              :slides-per-view="3.38"
              :slides-per-group="1"
              :space-between="0"
              :centered-slides="false"
              :modules="modules"
            >
              <swiper-slide
                @click="toGoodsDetail(item.id)"
                v-for="(item, index) in categoryList[0].goodsList"
                :key="index"
              >
                <img class="part cursor part_h" :src="oss + item.pc2_image" alt="" />
              </swiper-slide>
            </swiper>
          </div>
        </div>
      </template>

      <template v-if="categoryList.length > 1">
        <div class="fs24 col10 fwb lh86 plr120">{{ categoryList[1].name }}</div>
        <div class="tab-all mb20">
          <div class="tab-four">
            <swiper
              :loop="false"
              :autoplay="false"
              :slides-per-view="3.38"
              :slides-per-group="1"
              :space-between="0"
              :centered-slides="false"
              :modules="modules"
            >
              <swiper-slide
                @click="toGoodsDetail(item.id)"
                v-for="(item, index) in categoryList[1].goodsList"
                :key="index"
              >
                <img
                  class="part part2  cursor part_h"
                  :src="oss + item.pc2_image"
                  alt=""
                />
              </swiper-slide>
            </swiper>
          </div>
        </div>
      </template>

      <template v-if="categoryList.length > 2">
        <div class="fs24 col10 fwb lh86 plr120">{{ categoryList[2].name }}</div>
        <!-- <div class="scroll_part mb30">
          <img
            @click="toGoodsDetail(item.id)"
            v-for="(item, index) in categoryList[2].goodsList"
            :key="index"
            class="part part3 mr20 cursor"
            :src="oss + item.pc2_image"
            alt=""
          />
        </div> -->
        <div class="tab-all mb30">
          <div class="tab-four">
            <swiper
              :loop="false"
              :autoplay="false"
              :slides-per-view="3.38"
              :slides-per-group="1"
              :space-between="0"
              :centered-slides="false"
              :modules="modules"
            >
              <swiper-slide
                @click="toGoodsDetail(item.id)"
                v-for="(item, index) in categoryList[2].goodsList"
                :key="index"
              >
                <img
                  class="part part3  cursor part_h"
                  :src="oss + item.pc2_image"
                  alt=""
                />
              </swiper-slide>
            </swiper>
          </div>
        </div>
      </template>
    </div>
    <Footer></Footer>
  </div>
</template>
<script>
import { useHead } from "@unhead/vue";
import axios from "@/axios";
import { getCurrentInstance } from "vue";
import router from "@/router";
import { Autoplay, Scrollbar } from "swiper";
import { Swiper, SwiperSlide } from "../../node_modules/swiper/vue/swiper-vue";
import "swiper/swiper.min.css";
import "swiper/swiper-bundle.min.css";
export default {
  name: "productDetail",
  setup() {
    useHead({
      title: "商城",
      meta: [
        { name: "description", content: "This is my page description" },
        { property: "og:title", content: "My Page Title" },
        { property: "og:description", content: "This is my page description" },
        { property: "og:url", content: "https://example.com/my-page" },
        { property: "og:image", content: "https://example.com/my-image.jpg" },
      ],
      link: [{ rel: "canonical", href: "https://example.com/my-page" }],
    });
  },
  components: {
    Swiper,
    SwiperSlide,
  },
  data() {
    return {
      modules: [Autoplay, Scrollbar],
      midActive: 0,
      oss: getCurrentInstance().appContext.config.globalProperties.$oss,
      newList: [],
      categoryList: [],
    };
  },
  created() {
    this.nindex = 1;
    this.getNewList();
    this.getCategoryList();
  },
  methods: {
    onSwiper(e) {
      // console.log(e);
    },
    onSlideChange(e) {
      console.log(e.realIndex);
    },
    changeItem(e) {
      this.scrollIndex = e;
    },

    slideChange() {
      console.log(this.$refs.mySwiperMid.swiper.realIndex);
      this.midActive = this.$refs.mySwiperMid.swiper.realIndex;
    },
    toGoodsDetail(id) {
      router.push({ name: "productDetail", params: { goods_id: id } });
    },
    getNewList() {
      axios
        .post("xilushop/goods/goods_lists", {
          new: 1,
        })
        .then((res) => {
          this.newList = res.data.data;
        })
        .catch((error) => {
          console.error(error);
        });
    },
    getCategoryList() {
      axios
        .post("xilushop/category/index", {})
        .then((res) => {
          this.categoryList = res.data;
          this.getGoodsList();
        })
        .catch((error) => {
          console.error(error);
        });
    },
    getGoodsList() {
      axios
        .post("xilushop/goods/goods_lists", {
          category_id: this.categoryList[0].id,
        })
        .then((res) => {
          this.categoryList[0].goodsList = res.data.data;
        })
        .catch((error) => {
          console.error(error);
        });
      axios
        .post("xilushop/goods/goods_lists", {
          category_id: this.categoryList[1].id,
        })
        .then((res) => {
          this.categoryList[1].goodsList = res.data.data;
        })
        .catch((error) => {
          console.error(error);
        });
      axios
        .post("xilushop/goods/goods_lists", {
          category_id: this.categoryList[2].id,
        })
        .then((res) => {
          this.categoryList[2].goodsList = res.data.data;
        })
        .catch((error) => {
          console.error(error);
        });
      // for (var i in this.categoryList) {
      //   axios.post('xilushop/goods/goods_lists', {
      //     category_id: this.categoryList[i].id,
      //   })
      //     .then(res => {
      //       this.categoryList[i].goodsList = res.data.data
      //     })
      //     .catch(error => {
      //       console.error(error);
      //     });
      // }
    },
  },
};
</script>
<style lang="less" scoped>
.container {
  background: #f6f6f6;
  width: 100vw;
  overflow-x: hidden;
  padding-top: 101px;
}

// .main {
//   padding-left: 120px;
//   // padding-right: 120px;
// }
.plr120{
  padding-left: 120px;
  padding-right: 120px;
}


.location {
  widows: 24px;
  height: 24px;
  display: block;
  object-fit: contain;
}

.part1 {
  width: 141px;

  .img {
    width: 99px;
    height: 99px;
    display: block;
    object-fit: contain;
    margin: 0 auto 12px;
    background: #fff;
  }

  .title {
    font-weight: 400;
    font-size: 18px;
    color: #000000;
    line-height: 28px;
  }
}


.tab-all {
  width: calc(100vw - 120px);
margin-left: 120px;
  .part {
    width: 372px;
    height: 494px;
    display: block;
    object-fit: contain;
    background: #fff;
    border-radius: 28px;
    overflow: hidden;
    transition: all 0.5s;
    -webkit-transition: all 0.5s;
    margin-right: 20px;
  }
  .swiper {
    width: 100%;
    height: 494px;
  }
  /deep/ .swiper-slide {
    position: relative;

    overflow: hidden;
    width: 372px;
    height: 494px;
  }
  /deep/ .swiper-slide-active {
    position: relative;
    overflow: visible;
    z-index: 11;
    width: 372px;
    height: 494px;
    .img {
      transform-origin: center !important;
      -webkit-transform-origin: center !important;
      position: absolute;
      left: 0;
    }
    z-index: 111;
  }
  .swiper-slide-active::after {
    display: none;
  }
  .tab-four {
    width: 100%;
    .swiper-slide-active {
      .img {
        border: none;
      }
    }
  }
  .icon {
    width: 55px;
    height: 55px;
    display: block;
    object-fit: contain;
  }
  //  /deep/ .swiper-slide:first-of-type{
  //   margin-left: 120px;
  // }
  .part:last-of-type{
    margin-right: 120px!important;
  }
}

</style>