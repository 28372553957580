<template>
  <div class="container">
    <Header @input="input"></Header>
    <div class="main pr pt68 flex">
      <Mine></Mine>
      <div class="right_part flex-grow-1 ml20">
        <div class="mt10 lh28 fs20 fwb mb50">
          <span class="col6">我的售后/</span>
          <span class="col3">售后详情</span>
        </div>
        <div class="flex plr30 state_part mb30">
          <div class="flex-grow-1">
            <img class="state_icon" src="../assets/static/icon/orderProgress_on.png" alt="" />
            <div class="tc fs14 lh20 col0c">{{ data.check_status == 1 ? '审核通过' : (data.check_status == 2) ? '审核拒绝' :
              '审核中' }}</div>
          </div>
          <div :class="data.check_status == 1 ? 'line1_on' : 'line1'"></div>
          <div class="flex-grow-1">
            <img v-if="data.freight_status >= 1" class="state_icon" src="../assets/static/icon/orderProgress_on.png"
              alt="" />
            <img v-else class="state_icon" src="../assets/static/icon/orderProgress.png" alt="" />
            <div class="tc fs14 lh20" :class="state >= 1 ? 'col0c' : 'col3_5'">
              待寄件
            </div>
          </div>
          <div :class="data.freight_status >= 1 ? 'line2_on' : 'line2'"></div>
          <div class="flex-grow-1">
            <img v-if="data.freight_status >= 2" class="state_icon" src="../assets/static/icon/orderProgress_on.png"
              alt="" />
            <img v-else class="state_icon" src="../assets/static/icon/orderProgress.png" alt="" />
            <div class="tc fs14 lh20" :class="state >= 2 ? 'col0c' : 'col3_5'">
              运输中
            </div>
          </div>
          <div :class="data.freight_status >= 2 ? 'line3_on' : 'line3'"></div>
          <div class="flex-grow-1">
            <img v-if="data.repair_status >= 1" class="state_icon" src="../assets/static/icon/orderProgress_on.png"
              alt="" />
            <img v-else class="state_icon" src="../assets/static/icon/orderProgress.png" alt="" />
            <div class="tc fs14 lh20" :class="state >= 3 ? 'col0c' : 'col3_5'">
              已受理检测
            </div>
          </div>
          <div :class="data.repair_status >= 1 ? 'line4_on' : 'line4'"></div>
          <div class="flex-grow-1">
            <img v-if="data.repair_status == 2" class="state_icon" src="../assets/static/icon/orderProgress_on.png"
              alt="" />
            <img v-else class="state_icon" src="../assets/static/icon/orderProgress.png" alt="" />
            <div class="tc fs14 lh20" :class="state >= 4 ? 'col0c' : 'col3_5'">
              维修完成
            </div>
          </div>
          <!-- <div :class="state >= 4 ? 'line5_on' : 'line5'"></div>
          <div class="flex-grow-1">
            <img v-if="state >= 5" class="state_icon" src="../assets/static/icon/orderProgress_on.png" alt="" />
            <img v-else class="state_icon" src="../assets/static/icon/orderProgress.png" alt="" />
            <div class="tc fs14 lh20" :class="state >= 5 ? 'col0c' : 'col3_5'">
              已寄回
            </div>
          </div>
          <div :class="state >= 5 ? 'line6_on' : 'line6'"></div>
          <div class="flex-grow-1">
            <img v-if="state >= 6" class="state_icon" src="../assets/static/icon/orderProgress_on.png" alt="" />
            <img v-else class="state_icon" src="../assets/static/icon/orderProgress.png" alt="" />
            <div class="tc fs14 lh20" :class="state >= 6 ? 'col0c' : 'col3_5'">
              维修完成
            </div>
          </div> -->
          <div :class="data.repair_status == 2 ? 'line7_on' : 'line7'"></div>
          <div class="flex-grow-1">
            <img v-if="data.freight_status == 4" class="state_icon" src="../assets/static/icon/orderProgress_on.png"
              alt="" />
            <img v-else class="state_icon" src="../assets/static/icon/orderProgress.png" alt="" />
            <div class="tc fs14 lh20" :class="state >= 7 ? 'col0c' : 'col3_5'">
              已寄回
            </div>
          </div>
        </div>
        <div class="fs20 col3 fwb lh28 mb30 plr10">订单信息</div>
        <div class="flex flex-between fs14 col3 plr10 mb25 lh20">
          <div class="col3">订单号: {{ data.order_no }}</div>
          <div>申请时间: {{ data.createtime_text }}</div>
        </div>
        <!-- <div class="fs14 col3 lh20 mb40 plr10">支付方式：微信支付</div> -->
        <div class="fs20 col10 fwb lh40 plr10 mb15">收货地址</div>
        <div class="fs15 col10 fwb lh28 plr10 mb5">
          {{ data.city }}{{ data.address }}
        </div>
        <div class="plr10 fs14 col80 lh28 mb50">
          <span class="fs14 col89 mr60">{{ data.name }}</span>
          <span>{{ data.mobile }}</span>
        </div>
        <div class="fs20 col10 fwb lh40 mb15">设备信息</div>
        <div class="pb20 flex product">
          <img class="img" :src="oss+data.device.goods.images+w500" alt="" />
          <div class="flex-grow-1">
            <div class="fs20 col10 fwb lh44 m-ellipsis mb10">
              {{ data.device.goods.name }}
            </div>
            <div class="fs18 col85 lh28 mb15">{{ data.device.goods.sub_name }}</div>
            <div class="flex-box">
              <div class="fs18 col85 lh28 flex-grow-1">
                仪器编号:{{ data.device.goods_no }}
              </div>
              <div class="fs18 col85 lh28">购买日期:{{ data.buy_date }}</div>
            </div>
          </div>
        </div>
        <div class="info mb40">
          <div class="fs18 col6 lh28 mb15">故障内容</div>
          <div class="fs18 col10 lh35">
            {{ data.problem_content }}
          </div>
        </div>
        <!-- <template v-if="state == 7">
          <div class="fs20 col10 fwb lh40 mb20">售后报告</div>
          <div class="fs18 col10 lh35 mb20">
            美容仪开不了机，充上电还是开不了机前几天还用过，目前不知道什么原因开不了机无法使用
          </div>
          <div class="flex flex-wrap mb20">
            <img v-for="(ite, index) in 2" :key="index" class="gz_imgs mr20 mb20"
              src="../assets/static/images/device1.png" alt="" />
          </div>
        </template> -->
        <div class="fs20 col10 fwb lh40 mb15">故障照片</div>
        <div class="flex flex-wrap mb20">
          <img v-for="(item, index) in data.problem_images" :key="index" class="gz_imgs mr20 mb20" :src="item" alt="" />
        </div>
        <div class="fs20 col10 fwb lh40 mb15">拆机检查</div>
        <div class="label1 fs15 col10 fwb tc mb40">{{ data.chai_test == 1 ? '同意拆机检查' : '不同意拆机检查' }}</div>
        <div class="fs20 col10 fwb lh40 mb15">皮肤测试</div>
        <div class="label1 fs15 col10 fwb tc mb40">{{ data.skin_test == 1 ? '同意皮肤测试' : '不同意皮肤测试' }}</div>
        <div class="fs20 col10 fwb lh40 mb15">联系信息</div>
        <div class="fs18 col3 lh28 mb10">联系电话:{{ data.call_mobile }}</div>
        <div class="fs18 col3 lh28 mb40">时间段:{{ data.call_time_desc }}</div>
        <div class="flex-box flex-end">
          <!-- <template v-if="data.check_status == 0 || data.freight_status == 1">
            <div class="btn_buy1 col9 tc fs18 ml40 cursor">取消售后</div>
          </template> -->
          <div @click="jijian" v-if="data.check_status == 1 && data.freight_status <= 1"
            class="fs18 colf tc btn_buy2 ml40 cursor">
            一键寄件
          </div>
          <!-- <div @click="user_wuliu" v-if="data.freight_status > 1" class="fs18 colf tc btn_buy2 ml40">
            查看寄出物流
          </div>
          <div @click="back_wuliu" v-if="data.freight_status == 4" class="fs18 colf tc btn_buy2 ml40">
            查看寄回物流
          </div> -->
        </div>
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>
<script>
import { useHead } from "@unhead/vue";
import { ElMessage, ElMessageBox } from "element-plus";
import axios from "@/axios";
import router from "@/router";
import { getCurrentInstance } from "vue";
export default {
  name: "index",

  setup() {
    useHead({
      title: "订单详情",
      meta: [
        { name: "description", content: "This is my page description" },
        { property: "og:title", content: "My Page Title" },
        { property: "og:description", content: "This is my page description" },
        { property: "og:url", content: "https://example.com/my-page" },
        { property: "og:image", content: "https://example.com/my-image.jpg" },
      ],
      link: [{ rel: "canonical", href: "https://example.com/my-page" }],
    });
  },
  data() {
    return {
      oss: getCurrentInstance().appContext.config.globalProperties.$oss,
      w1000: getCurrentInstance().appContext.config.globalProperties.$w1000,
      w500: getCurrentInstance().appContext.config.globalProperties.$w500,
      state: 0, //0待审核  1待取件  2运输中  3待发货 4已受理 5开始检测 6维修完成 7已寄回
      data: { problem_images: [], device: { goods: '' } },
      id: '',

    };
  },
  created() {
    this.id = this.$route.params.id
    this.getDetail()
  },
  methods: {
    jijian() {
      ElMessageBox.confirm('请选择顺丰上门收件地址', '寄回设备', {
        confirmButtonText: '去选地址',
        cancelButtonText: '暂不寄件',
        type: 'warning'
      }).then(() => {
        // 确定按钮被点击的回调
        console.log('确定按钮被点击');
        router.push({
          name: 'myAddress', params: {
            order: 0,
            device_id: 0,
            repair_id: this.id,
            order_goods_id: 0
          }
        })
      }).catch(() => {
        // 取消按钮被点击的回调
        console.log('取消按钮被点击');
      });
    },
    getDetail() {
      axios.post('ag/repair_detail', {
        id: this.id,
      })
        .then(res => {
          if (res.code == 1) {
            if (res.data.problem_images) {
              res.data.problem_images = res.data.problem_images.split(',')
            } else {
              res.data.problem_images = []
            }
            this.data = res.data
          } else {
            ElMessage.error(res.msg)
          }
        })
    },
  },
};
</script>

<style lang="less" scoped>
.container {
  background: #f6f6f6;
  width: 100vw;
}

.main {
  width: 1200px;
  margin: 20px auto;
}

.mw200 {
  max-width: 200px;
}

.border_b {
  border-bottom: 1px solid #eeeff0;
}

.right_part {
  background: #fdfdfd;
  border-radius: 13px;
  overflow: hidden;
  padding: 35px 20px;

  .state_part {
    position: relative;
  }

  .state_icon {
    width: 22px;
    height: 22px;
    display: block;
    object-fit: cover;
    margin: 0 auto 5px;
    position: relative;
    z-index: 2;
  }

  .line1_on,
  .line1,
  .line2,
  .line2_on,
  .line3_on,
  .line3,
  .line4_on,
  .line4,
  .line5_on,
  .line5,
  .line6_on,
  .line6,
  .line7_on,
  .line7 {
    width: 97px;
    height: 1px;
    background: #0c0c0c;
    position: absolute;
    z-index: 1;
    top: 10px;
    left: 95px;
  }

  .line1,
  .line2,
  .line3,
  .line4,
  .line5,
  .line6,
  .line7 {
    background-color: #bcbcbc;
  }

  .line2_on,
  .line2 {
    left: 215px;
  }

  .line3_on,
  .line3 {
    left: 330px;
  }

  .line4_on,
  .line4 {
    left: 445px;
  }

  // .line5_on,
  // .line5 {
  //   left: unset;
  //   right: 255px;
  // }

  .line6_on,
  .line6 {
    left: unset;
    right: 198px;
  }

  .line7_on,
  .line7 {
    left: unset;
    right: 98px;
  }

  .border_t {
    border-top: 1px solid #e3e3e3;
  }

  .img {
    width: 129px;
    height: 129px;
    display: block;
    object-fit: cover;
    margin-right: 20px;
  }

  .mb8 {
    margin-bottom: 8px;
  }

  .pl223 {
    padding-left: 149px;
  }

  .pb37 {
    padding-bottom: 37px;
  }

  .btn_buy1,
  .btn_buy2,
  .btn_buy3 {
    width: 158px;
    height: 55px;
    line-height: 55px;
    background: #bba17b;
    border-radius: 5px;
  }

  .btn_buy1,
  .btn_buy3 {
    background: #fff;
    line-height: 53px;
    border: 1px solid #bba17b;
    color: #bba17b;
  }

  .btn_buy3 {
    border-color: #bba17b;
    color: #bba17b;
  }

  .label_sh {
    width: 69px;
    text-align: center;
    height: 23px;
    line-height: 23px;
    background: #f0f0f7;
    border-radius: 12px;
  }

  .info {
    width: 100%;
    padding: 16px 23px;
    background: #f8f8f8;
    border-radius: 6px;
  }

  .gz_imgs {
    width: 106px;
    height: 106px;
    border-radius: 10px;
    border: 1px solid #dddddd;
  }

  .label1 {
    width: 404px;
    text-align: center;
    height: 77px;
    line-height: 77px;
    background: #f5f5f5;
    border-radius: 7px;
  }
}
</style>