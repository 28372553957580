<!--
  时间：2024年05月20日 15:38:24
-->
<template>
  <div class="footer_nav">
    <div class="footer_link1 maxw1200">
      <div class="flex mb55">
        <div class="flex-grow-1 flex pr60">
          <div class="w205">
            <div class="fs20 colf fwb lh28 mb10 cu">选购及了解</div>
            <router-link :to="'/productDetail/' + item.id" v-for="item in goods_list" class="mb15">
              <div class="fs15 col80 lh35 plans">{{ item.name }}</div>
            </router-link>
          </div>
          <div class="w205">
            <div class="fs20 colf fwb lh28 mb10 cu">服务支持</div>
            <!-- <router-link to="" class="mb15">
              <div class="fs15 col80 lh35 plans">支付流程</div>
            </router-link>
            <router-link to="" class="mb15">
              <div class="fs15 col80 lh35 plans">充值流程</div>
            </router-link> -->
            <router-link to="/tradeInTheOld/0" class="mb15">
              <div class="fs15 col80 lh35 plans">以旧换新</div>
            </router-link>
            <router-link to="/bindDevice" class="mb15">
              <div class="fs15 col80 lh35 plans">绑定设备</div>
            </router-link>
            <!-- <router-link to="" class="mb15">
              <div class="fs15 col80 lh35 plans">帮助中心</div>
            </router-link> -->
          </div>
        </div>
        <div class="mt15 flex-box">
          <div class="mr40">
            <div class="fs16 col80 fwb lh28 mb17 tc">微信小程序</div>
            <div class="border mb5">
              <img :src="oss + bottom_data.pc_miniapp_image + w500" class="code" alt="" />
            </div>
            <div class="tc fs12 col80">微信扫一扫打开小程序</div>
          </div>
          <div class="">
            <div class="fs16 col80 fwb lh28 mb17 tc">其他应用入口</div>
            <div class="border mb5">
              <img :src="oss + bottom_data.pc_app_image + w500" class="code" alt="" />
            </div>
            <div class="tc fs12 col80">APP扫码下载</div>
          </div>
        </div>
      </div>
      <div class="tc fs11 col80 lh16">
        {{ bottom_data.beian }}
      </div>
    </div>
  </div>
</template>

<script>
import axios from "@/axios";
import router from "@/router";
import { getCurrentInstance } from "vue";
import { ElMessage, ElMessageBox } from "element-plus";

export default {
  // 局部注册的组件
  components: {},
  // 组件状态值
  data() {
    return {
      oss: getCurrentInstance().appContext.config.globalProperties.$oss,
      w1000: getCurrentInstance().appContext.config.globalProperties.$w1000,
      w500: getCurrentInstance().appContext.config.globalProperties.$w500,
      goods_list: [],
      bottom_data: ''
    };
  },
  // 计算属性
  computed: {},
  // 侦听器
  watch: {},
  created() {
    this.bottom_goods()
    this.bottom_config()
  },
  // 组件方法
  methods: {
    bottom_goods() {
      axios.post('index/bottom_goods', {}).then(res => {
        this.goods_list = res.data
      })
    },
    bottom_config() {
      axios.post('index/bottom_config', {}).then(res => {
        this.bottom_data = res.data
      })
    }
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<!--使用了scoped属性之后，父组件的style样式将不会渗透到子组件中，-->
<!--然而子组件的根节点元素会同时被设置了scoped的父css样式和设置了scoped的子css样式影响，-->
<!--这么设计的目的是父组件可以对子组件根元素进行布局。-->
<style lang="less" scoped>
.footer_nav {
  background: #000;
  padding-left: 312px;
  padding-right: 268px;
}

.maxw1200 {
  //   max-width: 904px;
  margin-left: auto;
  margin-right: auto;
  padding-top: 43px;
  padding-bottom: 25px;
  padding-left: 44px;
}

.w205 {
  width: 205px;
  // width: calc((100vw - 32px - 783px) / 2);
}

.border {
  width: 158px;
  height: 158px;
  padding: 10px 0;
  border-radius: 25px;
  border: 4px solid #808080;
  margin: 0 auto 5px;
}

.code {
  width: 130px;
  height: 130px;
  display: block;
  margin: 0 auto;
}

.col80 {
  color: #808080;
}
</style>
