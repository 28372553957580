<template>
  <div class="container">
    <Header @input="input"></Header>
    <div class="main pr pt68 flex">
      <Mine></Mine>
      <div class="right_part flex-grow-1 ml20">
        <div class="flex-box mb20">
          <div class="tablist mr40 fs20 fwb col3 lh28 active">我的活动</div>
          <!-- <div class="selecct1 flex-box">
            <div class="flex-1 flex-grow-1 m-ellipsis fs14 col3 fwb">
              全部订单
            </div>
            <img
              class="arrow"
              src="../assets/static/icon/arrow_down.png"
              alt=""
            />
          </div> -->
          <div class="selecct1 flex-box cursor pr">
            <!--
            <img
              class="arrow"
              src="../assets/static/icon/arrow_down.png"
              alt=""
            /> -->
            <el-select class="select" style="
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background-color: transparent;
                border: none;
                box-shadow: none;
              " v-model="tabIndex" @change="tab" placeholder="请选择">
              <el-option v-for="(item, index) in tabList" :label="item" :value="index"></el-option>
            </el-select>
          </div>
        </div>
        <div v-for="(item, index) in list" :key="index" class="product_part1">
          <div class="product_top flex-box pl20 fs14 col3">
            <div class="tab1">活动名称</div>
            <div class="tab3 tc">开始时间</div>
            <div class="tab4 tc">状态</div>
            <div class="tab5 tc">订单金额</div>
            <div class="flex-grow-1 tc">操作</div>
          </div>
          <div class="flex-box pt13 pb20 pl20 fs14 col3 lh20">
            <div class="tab1 flex-box">
              <img class="imgs1" :src="oss + item.image + w500" alt="" />
              <div class="flex-grow-1 fs14 col3 lh20">
                {{ item.activity_name }}
              </div>
            </div>
            <div class="tab3 tc fs14 col3">{{ item.starttime_text }}</div>
            <div class="tab4 tc">
              <div v-if="item.cancel_status == 1" class="zt_btn1 pointer cursor">已取消</div>
              <div v-else-if="item.start_status == 1" class="zt_btn1 pointer cursor">已开始</div>
              <div v-else-if="item.pay_status == 1" class="zt_btn1 pointer cursor">已报名</div>
              <div v-else-if="item.pay_status == 0" class="zt_btn1 pointer cursor">待支付</div>
            </div>
            <div class="tab5 tc">{{ item.pay_price == 0 ? '免费' : 'RMB ' + item.pay_price }}</div>
            <div class="flex-grow-1 tc">
              <div @click="cancel(item.id)" v-if="item.cancel_status == 0 && item.start_status == 0"
                class="mb10 fs14 colbba lh20 pointer plans cursor plans">
                取消订单
              </div>
              <!-- <div class="mb10 fs14 colbba lh20 pointer plans cursor plans">
                立即支付
              </div> -->
              <div @click="toDetail(item.id)" class="lh20 fs14 colbba tc pointer plans cursor plans">
                查看详情
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>
<script>
import { useHead } from "@unhead/vue";
import axios from "@/axios";
import { getCurrentInstance } from "vue";
import router from "@/router";
import { ElMessage, ElMessageBox } from "element-plus";
var page;
export default {
  name: "index",

  setup() {
    useHead({
      title: "我的活动",
      meta: [
        { name: "description", content: "This is my page description" },
        { property: "og:title", content: "My Page Title" },
        { property: "og:description", content: "This is my page description" },
        { property: "og:url", content: "https://example.com/my-page" },
        { property: "og:image", content: "https://example.com/my-image.jpg" },
      ],
      link: [{ rel: "canonical", href: "https://example.com/my-page" }],
    });
  },
  data() {
    return {
      oss: getCurrentInstance().appContext.config.globalProperties.$oss,
      w1000: getCurrentInstance().appContext.config.globalProperties.$w1000,
      w500: getCurrentInstance().appContext.config.globalProperties.$w500,
      list: [],
      tabList: ["全部", "已报名", "已开始", "已取消"],
      tabIndex: 0
    };
  },
  created() {
    page = 1
    this.getList()
  },
  methods: {
    tab(e) {
      this.tabIndex = e
      page = 1;
      this.getList()
    },
    toDetail(id) {
      router.push({
        name: 'myActivitiesDeatil',
        params: {
          order_id: id
        }
      })
    },
    cancel(id) {
      ElMessageBox.confirm('确认取消报名？', '提示', {
        confirmButtonText: '确认',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        // 确定按钮被点击的回调
        console.log('确定按钮被点击');
        axios.post('activity/cancel_order', {
          order_id: id
        }).then(res => {
          if (res.code == 1) {
            ElMessageBox.alert(res.msg, '提示', {
              confirmButtonText: '确定',
            });
            this.getList()
          } else {
            ElMessageBox.alert(res.msg, '提示', {
              confirmButtonText: '确定',
            });
          }
        })
      }).catch(() => {
        // 取消按钮被点击的回调
        console.log('取消按钮被点击');
      });
    },
    getList() {
      axios.post('activity/order_list', {
        page: page,
        pagesize: 999,
        tab: this.tabIndex === 0 ? -1 : this.tabIndex
      }).then(res => {
        var data = res.data.data
        for (var i in data) {
          data[i].pay_price = parseFloat(data[i].pay_price)
        }
        if (page == 1) {
          this.list = data
        } else {
          this.list = this.list.concat(data)
        }
      })
    },
  },
};
</script>

<style lang="less" scoped>
.container {
  background: #f6f6f6;
  width: 100vw;
}

.main {
  width: 1200px;
  margin: 20px auto;
}

.mw200 {
  max-width: 200px;
}

.border_b {
  border-bottom: 1px solid #eeeff0;
}

.part1 {
  width: 385px;
  padding: 67px 20px 67px 20px;
  background: #fdfdfd;
  border-radius: 13px;

  .avatar_part {
    width: 118px;
    height: 118px;
    border-radius: 50%;
    border: 1px solid #eeeeee;
    padding: 6px 0;
    margin: 5px auto;

    img {
      width: 107px;
      height: 107px;
      display: block;
      object-fit: cover;
      margin: 0 auto;
      border-radius: 50%;
      overflow: hidden;
    }
  }

  .v1 {
    width: 20px;
    height: 20px;
    display: block;
    object-fit: contain;
  }

  .part2 {
    width: 168px;
    background: #f1f2f4;
    border-radius: 8px;
    padding: 19px 13px 18px 20px;

    .btn {
      width: 45px;
      text-align: center;
      height: 23px;
      line-height: 23px;
      background: #bba17b;
      border-radius: 12px;
    }
  }

  .part3 {
    width: 345px;
    padding: 20px 25px;
    background: #f1f2f4;
    border-radius: 8px;
  }
}

.right_part {
  background: #fdfdfd;
  border-radius: 13px;
  overflow: hidden;
  padding: 25px 20px;

  .selecct1 {
    width: 169px;
    padding: 0 15px 0 20px;
    margin-left: auto;
    margin-right: 0;
    height: 46px;
    line-height: 46px;
    border-radius: 6px;
    border: 1px solid #e7e7e7;

    .arrow {
      width: 10px;
      height: 7px;
      display: block;
      object-fit: contain;
    }
  }

  .product_part1 {
    border-radius: 4px;
    border: 1px solid #e2e2e2;
    margin-bottom: 20px;
    word-wrap: break-word;
    word-break: normal;

    .pt13 {
      padding-top: 13px;
    }

    .product_top {
      height: 44px;
      line-height: 44px;
      background: #f3f3f3;
      border-radius: 4px;
    }

    .tab1 {
      width: 226px;

      .imgs1 {
        width: 85px;
        height: 85px;
        display: block;
        object-fit: cover;
        overflow: hidden;
        margin-right: 15px;
        border-radius: 10px;
        overflow: hidden;
      }
    }

    .tab3 {
      width: 126px;
      margin-right: 27px;
      margin-left: 35px;
    }

    .tab4 {
      width: 82px;
      margin-right: 30px;

      .zt_btn1,
      .zt_btn2,
      .zt_btn3 {
        width: 69px;
        text-align: center;
        height: 26px;
        line-height: 26px;
        background: #0c0c0c;
        border-radius: 5px;
        font-size: 12px;
        color: #ffffff;
        margin: 0 auto;
      }
    }

    .tab5 {
      width: 69px;
      margin-right: 35px;
    }
  }
}
</style>