<template>
    <div class="container pr pt68">
        <Header @input="input"></Header>
        <div class="main">
            <div class="flex-box">
                <div class="flex-grow-1 fs32 col10 fwb lh86">{{ goodsDetail.name }}</div>
                <div class="label fs15 col10">以旧换新最高抵扣 {{ goodsDetail.max_change_price }}</div>
            </div>
            <div class="flex-box mb30">
                <div class="fs14 col0">RMB</div>
                <div class="fs22 col0 fwb mlr2">7999</div>
                <div class="fs16 col0">起</div>
            </div>
            <div class="swiper_link cursor">
                <swiper :navigation="{
                    nextEl: '.swiper-button-next',
                    prevEl: '.swiper-button-prev',
                }" :pagination="{ clickable: true }" :autoplay="false" @swiper="onSwiper" @slideChange="onSlideChange"
                    class="swiper_sw" :modules="modules" loop>
                    <swiper-slide class="swiper_item" v-for="(item, index) in goodsDetail.pc_images_arr" :key="index">
                        <div class="top_banner cu flex-box flex-center flex-col"
                            :style="{ backgroundImage: `url(${oss}${item})` }"></div>
                    </swiper-slide>
                    <div class="swiper-button-prev">
                        <img @click="bannerSwiperPrev" class="left_icon" src="../assets/static/icon/left_icon.png"
                            alt="" />
                    </div>
                    <div class="swiper-button-next">
                        <img class="left_icon right_icon" src="../assets/static/icon/right_icon.png" alt="" />
                    </div>
                </swiper>
            </div>
            <block v-for="(item, index) in goodsDetail.specData.spec_attr">
                <div class="fs24 col10 lh40">
                    <span class="fwb">{{ item.group_name }}：</span><span>{{ item.group_desc }}</span>
                </div>
                <div v-if="item.spec_items[0].image" class="flex flex-wrap mb50 tab1">
                    <div class="tablist mt30 cursor" v-for="(item2, index2) in item.spec_items" :key="index2"
                        @click="testTabClick(index, index2)" :class="item2.check ? 'active' : 'unactive'">
                        <div class="color_part" :style="{ backgroundImage: `url(${oss}${item2.image})` }"></div>
                        <div class="tc">{{ item2.spec_value }}</div>
                    </div>
                </div>
                <div v-else class="flex flex-wrap mb50 tab2">
                    <div class="tablist mt30" v-for="(item2, index2) in item.spec_items" :key="index2"
                        @click="testTabClick(index, index2)" :class="item2.check ? 'active' : 'unactive'">
                        {{ item2.spec_value }}
                    </div>
                </div>
            </block>
            <block v-if="goodsDetail.change_auth">
                <div class="fs24 col10 lh40">
                    <span class="fwb">以旧换新：</span><span>换购新美容仪可优惠 {{ goodsDetail.max_change_price }} RMB </span>
                </div>
                <div @click="setChange()" class="flex flex-wrap mb50 tab2">
                    <div class="tablist mt30" :key="index" @click="testTabClick3(0)"
                        :class="change == 1 ? 'active' : 'unactive'">
                        <div class="tc fs15 col10 fwb lh15 pt20">
                            {{ goodsDetail.change_goods ? goodsDetail.change_goods.name : '选择旧仪器' }}</div>
                        <div class="tc fs15 col85 mt10 lh15">
                            <text v-if="goodsDetail.change_goods">优惠{{ goodsDetail.change_goods.price }}</text>
                            <text v-else>回答几个问题，获得折低估价格</text>
                        </div>
                    </div>
                    <!--                    <div-->
                    <!--                            class="tablist mt30"-->
                    <!--                            :key="index"-->
                    <!--                            @click="testTabClick3(1)"-->
                    <!--                            :class="tabIndex3 == 1 ? 'active' : 'unactive'"-->
                    <!--                    >-->
                    <!--                        <div class="tc fs15 col10 fwb lh15 pt30">不使用以旧换新</div>-->
                    <!--                    </div>-->
                </div>
            </block>
            <block v-if="goodsDetail.have_acare == 1">
                <div class="fs24 col10 lh40">
                    <span class="fwb">A CARE服务：</span><span>给新宙斯多一份保护。</span>
                </div>
                <div class="flex flex-wrap mb50 tab4">
                    <div class="tablist mt30 part1" :key="index" @click="testTabClick4(1)"
                        :class="tabIndex4 == 1 ? 'active' : 'unactive'">
                        <div class="fs14 col-10 fwb mb10">A CARE服务计划</div>
                        <div class="fs14 col-10">RMB {{ goodsDetail.acare_goods.price }}</div>
                        <div class="fs14 col-6 lh20 border_t mt5 pt7">
                            <div v-html="goodsDetail.acare_goods.content"></div>
                        </div>
                    </div>
                    <div class="tablist mt30 fs15 col10 part2 tc" :key="index" @click="testTabClick4(0)"
                        :class="tabIndex4 == 0 ? 'active' : 'unactive'">
                        不加入 A CARE服务计划
                    </div>
                </div>
            </block>

        </div>
        <div class="bg-main">
            <div class="main flex">
                <img class="imgs" src="../assets/static/images/device1.png" alt="" />
                <div class="flex-grow-1">
                    <div class="fs24 col10 fwb lh44 m-ellipsis mb20">
                        DrArrivo 家用脸面部提拉紧致微电流 宙斯二代美容仪
                    </div>
                    <div class="fs20 col10 lh28 mb15">
                        <span class="mr15">RMB</span>
                        <span class="mr15">7,999</span>
                    </div>
                    <div class="fs20 col85 lh28">黑色*1</div>
                </div>
                <div @click="addCart" class="btn1 tc cursor">加入购物车</div>
                <div @click="toOrder" class="btn2 tc cursor">结账</div>
            </div>
        </div>
        <Footer></Footer>
    </div>
</template>
<script>
import { useHead } from "@unhead/vue";
import {
    Navigation,
    Pagination,
    Scrollbar,
    A11y,
    EffectFade,
    Parallax,
} from "swiper";
import { Swiper, SwiperSlide } from "../../node_modules/swiper/vue/swiper-vue";
import "swiper/swiper.min.css";
import "swiper/swiper-bundle.min.css";
import { getCurrentInstance } from "vue";
import axios from "@/axios";
import router from "@/router";
import { ElMessage } from "element-plus";

export default {
    name: "buy",
    components: {
        Swiper,
        SwiperSlide,
    },
    setup() {
        useHead({
            title: "购买",
            meta: [
                { name: "description", content: "This is my page description" },
                { property: "og:title", content: "My Page Title" },
                { property: "og:description", content: "This is my page description" },
                { property: "og:url", content: "https://example.com/my-page" },
                { property: "og:image", content: "https://example.com/my-image.jpg" },
            ],
            link: [{ rel: "canonical", href: "https://example.com/my-page" }],
            navigation: {
                nextEl: ".swiper-button-next",
                prevEl: ".swiper-button-prev",
            },
        });
    },

    data() {
        return {
            modules: [Navigation, Pagination, Scrollbar, A11y, EffectFade, Parallax],
            midActive: 0,
            swiperList1: [
                { banner: require("../assets/static/images/buy_banner.png") },
                { banner: require("../assets/static/images/buy_banner.png") },
                { banner: require("../assets/static/images/index_banner.png") },
            ],

            tabIndex: 0,
            tablist: [
                {
                    color: "#D4B4A7",
                    title: "玫瑰金",
                },
                {
                    color: "#333333",
                    title: "高级黑",
                },
                {
                    color: "#CBC0B8",
                    title: "奶茶色",
                },
                {
                    color: "#977F76",
                    title: "时尚咖",
                },
            ],
            tabIndex2: 0,
            tablist2: ["常规版本", "旗舰版本"],
            tabIndex3: 0,
            tabIndex4: 0,
            oss: getCurrentInstance().appContext.config.globalProperties.$oss,
            goods_id: '',
            goodsDetail: {
                id: '',
                specData: {
                    spec_attr: ''
                },
                change_auth: '',
                change_goods: ''
            },
            change: 0,
            spec_list_index: '',
            device_id: '',
            old_price: 0,
            coupon_id: 0,
            coupon_price: 0,
            coupon_name: ''
        };
    },
    created() {
        this.goods_id = this.$route.params.goods_id
        this.device_id = this.$route.params.device_id
        this.coupon_id = this.$route.params.coupon_id || 0
        this.coupon_price = this.$route.params.coupon_price || 0
        this.coupon_name = this.$route.params.coupon_name || ''
        this.getGoodsDetail()
    },
    methods: {
        addCart() {
            if (this.tabIndex4 == 1) {
                axios.post('xilushop/cart/add', {
                    goods_id: this.goodsDetail.acare_goods.id,
                    goods_num: 1,
                    goods_sku_id: 0,
                    is_acare: 1,
                    bind_goods_id: this.goods_id,
                })
                    .then(res => {

                    })
                    .catch(error => {
                        console.error(error);
                    });
            }
            var goods_sku_id = this.goodsDetail.spec_type == '20' ? this.goodsDetail.specData.spec_list[this
                .spec_list_index].goods_spec_id : this.goodsDetail.specData.spec_list[0].goods_spec_id;
            axios.post('xilushop/cart/add', {
                goods_id: this.goods_id,
                goods_num: 1,
                goods_sku_id: goods_sku_id,
                is_acare: 0
            })
                .then(res => {
                    if (res.code == 1) {
                        ElMessage.success(res.msg);
                    }
                })
                .catch(error => {
                    console.error(error);
                });
        },
        toOrder() {
            var goods_sku_id = this.goodsDetail.spec_type == '20' ? this.goodsDetail.specData.spec_list[this
                .spec_list_index].goods_spec_id : this.goodsDetail.specData.spec_list[0].goods_spec_id;
            if (this.goodsDetail.have_acare == 1 && this.tabIndex4 == 1) {
                router.push({
                    name: 'confirmOrder', params: {
                        ids: this.goods_id,
                        device_id: this.device_id,
                        goods_sku_id: goods_sku_id,
                        goods_num: 1,
                        acare_goods_id: this.goodsDetail.acare_goods.id,
                        coupon_id: this.coupon_id,
                        acare_device_id: 0,
                        type: 'detail'
                    }
                });
            } else {
                router.push({
                    name: 'confirmOrder', params: {
                        ids: this.goods_id,
                        device_id: this.device_id,
                        goods_sku_id: goods_sku_id,
                        goods_num: 1,
                        acare_goods_id: 0,
                        coupon_id: this.coupon_id,
                        acare_device_id: 0,
                        type: 'detail'
                    }
                });
            }
        },
        setChange() {
            router.push({
                name: 'tradeInTheOld',
                params: {
                    goods_id: this.goods_id
                }
            })
        },
        getGoodsDetail() {
            axios.post('xilushop/goods/goods_detail', {
                goods_id: this.goods_id,
                device_id: this.device_id,
            })
                .then(res => {
                    if (res.data.spec_type == '20') {
                        var spec_index_arr = []
                        var spec_attr = res.data.specData.spec_attr
                        var spec_rel = res.data.spec_rel
                        for (var i in spec_attr) {
                            for (var ii in spec_attr[i].spec_items) {
                                if (ii == 0) {
                                    spec_attr[i].spec_items[ii].check = true
                                    spec_index_arr.push(spec_attr[i].spec_items[ii].item_id)
                                } else {
                                    spec_attr[i].spec_items[ii].check = false
                                }
                                for (var i3 in spec_rel) {
                                    if (spec_rel[i3].id == spec_attr[i].spec_items[ii].item_id) {
                                        spec_attr[i].spec_items[ii].image = spec_rel[i3].pivot.image
                                        break
                                    }
                                }
                            }
                        }
                        spec_index_arr.sort((a, b) => {
                            return a - b
                        });
                        var spec_sku_id = spec_index_arr.join('_')
                        var spec_list = res.data.specData.spec_list
                        for (var i in spec_list) {
                            if (spec_list[i].spec_sku_id == spec_sku_id) {
                                this.spec_list_index = i
                                break
                            }
                        }
                        res.data.specData.spec_attr = spec_attr

                    } else {
                        res.data.specData = {
                            spec_attr: [],
                            spec_list: res.data.specData.spec_list
                        }
                    }
                    res.data.max_change_price = parseFloat(res.data.max_change_price)

                    if (res.data.spec_type == '20') {
                        this.old_price = res.data.specData.spec_list[this.spec_list_index].form.goods_price
                    } else {
                        this.old_price = res.data.goods_price
                    }
                    if (this.tabIndex4 == 1) {
                        this.old_price = this.old_price + parseFloat(this.goodsDetail.acare_goods.price)
                    } else {
                        this.old_price = this.old_price
                    }
                    res.data.pc_images_arr = res.data.pc_images.split(',');
                    this.goodsDetail = res.data
                    if (res.data.change_goods) {
                        this.change = 1;
                    }
                })
                .catch(error => {
                    console.error(error);
                });
        },
        onSwiper(e) {
            // console.log(e);
        },
        bannerSwiperPrev(e) {
            this.swiperDom.slidePrev();
        },
        onSlideChange(e) {
            console.log(e.realIndex);
        },
        changeItem(e) {
            this.scrollIndex = e;
        },

        slideChange() {
            console.log(this.$refs.mySwiperMid.swiper.realIndex);
            this.midActive = this.$refs.mySwiperMid.swiper.realIndex;
        },
        testTabClick(index) {
            console.log(index);
            this.tabIndex = index;
        },
        testTabClick2(index) {
            console.log(index);
            this.tabIndex2 = index;
        },
        testTabClick3(index) {
            console.log(index);
            this.tabIndex3 = index;
        },
        testTabClick4(index) {
            console.log(index);
            if (this.tabIndex4 != index) {
                if (index == 1) {
                    this.old_price = (parseFloat(this.old_price) + parseFloat(this.goodsDetail.acare_goods.price))
                        .toFixed(2)
                } else {
                    this.old_price = (parseFloat(this.old_price) - parseFloat(this.goodsDetail.acare_goods.price))
                        .toFixed(2)
                }
            }
            this.tabIndex4 = index;
        },
    },
};
</script>
<style lang="less" scoped>
.container {
    background: #fff;
    width: 100vw;
    overflow-x: hidden;
}

.main {
    width: 1200px;
    margin: 33px auto 51px;

    .label {
        padding: 0 30px;
        height: 48px;
        line-height: 48px;
        background: #f5f5f5;
        border-radius: 35px;
    }

    .mlr2 {
        margin-left: 2px;
        margin-right: 2px;
    }

    .swiper_link {
        width: 1200px;
        height: 482px;
        position: relative;
        margin-bottom: 37px;

        .swiper_sw {
            width: 100%;
            height: 100%;
            //   border-radius: 15px;
            //   overflow: hidden;
            position: relative;
        }

        .swiper_sw_item {
            width: 100%;
            height: 100%;
        }

        .top_banner {
            width: 100%;
            height: 100%;
            background-repeat: no-repeat;
            background-size: 100% 100%;
            background-position: center;
            display: block;
        }
    }
}

.left_icon,
.right_icon {
    width: 32px;
    height: 32px;
    display: block;
    object-fit: contain;
    position: absolute;
    z-index: 10;
    top: 50%;
    transform: translateY(-50%);
    left: 35px;
}

.right_icon {
    left: unset;
    right: 35px;
}
</style>
<style lang="less">
.swiper_sw .swiper-pagination .swiper-pagination-bullet {
    cursor: pointer;
    background: rgba(255, 255, 255, 0.5) !important;
    opacity: 1;
}

.swiper_sw .swiper-pagination .swiper-pagination-bullet-active {
    background: #ffffff !important;
    cursor: pointer;
}

.tab1,
.tab2 {
    .tablist {
        width: 284px;
        height: 76px;
        border-radius: 7px;
        overflow: hidden;
        margin-right: 20px;
        font-size: 15px;
        color: #101010;
        line-height: 15px;
        text-align: center;
    }

    .tablist:nth-of-type(4n) {
        margin-right: 0;
    }

    .color_part {
        width: 21px;
        height: 21px;
        border-radius: 50%;
        overflow: hidden;
        margin: 0 auto 8px;
    }

    .active,
    .tablist:hover {
        padding-top: 14px;
        padding-bottom: 16px;
        background: #ffffff;
        border: 1px solid #bba17b;
    }

    .unactive {
        padding-top: 15px;
        padding-bottom: 17px;
        background: #f5f5f5;
    }
}

.tab2 {
    .tablist {
        width: 587px;
        height: 76px;
        border-radius: 7px;
        overflow: hidden;
        margin-right: 19px;
        font-size: 15px;
        color: #101010;
        line-height: 15px;
        text-align: center;
    }

    .tablist:nth-of-type(2n) {
        margin-right: 0;
    }

    .active,
    .tablist:hover {
        line-height: 74px;
        padding-top: unset;
        padding-bottom: unset;
        background: #ffffff;
        border: 1px solid #bba17b;
    }

    .unactive {
        line-height: 76px;
        padding-top: unset;
        padding-bottom: unset;
        background: #f5f5f5;
    }
}

.border_t {
    border-top: 1px solid rgba(151, 151, 151, 0.2);
}

.tab4 {
    .tablist {
        width: 587px;
        min-height: 312px;
        border-radius: 7px;
        overflow: hidden;
        margin-right: 19px;
    }

    .tablist:nth-of-type(2n) {
        margin-right: 0;
    }

    .part1 {
        padding: 16px 37px 41px 23px;
    }

    .active,
    .tablist:hover {
        background: #ffffff;
        border: 1px solid #bba17b;
    }

    .unactive {
        background: #f5f5f5;
    }

    .part2 {
        padding-top: 108px;
        text-align: center;
    }
}

.bg-main {
    width: 100vw;
    background: #f5f5f5;
    padding: 40px 0;

    .imgs {
        width: 150px;
        height: 150px;
        display: block;
        object-fit: cover;
        margin-right: 30px;
    }

    .mian {
        width: 1200px;
        margin: 0px auto;
    }
}

.btn1,
.btn2 {
    width: 160px;
    height: 48px;
    border-radius: 24px;
    border: 1px solid #4971c7;
    line-height: 46px;
    color: #4088f6;
    font-size: 17px;
    margin: 50px 0 0 33px;
}

.btn2 {
    background: #bba17b;
    line-height: 48px;
    color: #fff;
    border: none;
}
</style>
<style lang="less" scoped>
//定义箭头默认颜色
.swiper-button-prev,
.swiper-button-next {
    color: rgba(16, 16, 16, 0);
    // &:hover {
    //   color: skyblue;
    // }
}

.swiper-button-prev {
    left: 30px;
    color: rgba(16, 16, 16, 0);

    // 使用iconfont字体对应的unicode作为箭头图案
    &::after {
        content: "\eb0a";
    }
}

.swiper-button-next {
    right: 30px;

    &::after {
        content: "\eb09";
    }
}

//定义箭头样式
.swiper-button-prev:：after,
.swiper-button-next:：after {
    //指定字体采用iconfont
    font-family: iconfont;
    font-weight: 800;
    font-size: 0px;
}
</style>