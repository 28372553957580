<template>
  <div class="container bgf">
    <div class="wrap">
      <!-- 底部 -->
      <div class="infoBox">
        <!-- 左边用户列表 -->
        <div class="userList">
          <div class="top">
            <div class="flex mb15">
              <img
                src="../assets/static/images/imgs2.png"
                alt=""
                class="avatar"
              />
              <div class="flex-grow-1">
                <div class="fs16 colf lh16 m-ellipsis mb10">官方客服</div>
                <div class="vip_label fs12 fwb">V1会员</div>
              </div>
            </div>
            <input
              placeholder="搜索最近联系人"
              v-model="search"
              class="fs12 col6 pl15"
              size="mini"
              @input="inquire"
              type="text"
            />
          </div>
          <div class="userListBox">
            <div
              v-for="(item, index) in userListData"
              :key="index"
              @click="getAct(item, index)"
              :class="item.id == act ? 'userFlexAct' : 'userFlex'"
              class="flex-box"
            >
              <img
                :src="item.url"
                alt="头像"
                class="head_portrait2"
                style="margin-left: 20px"
              />
              <div class="flex-grow-1 ml10">
                <div class="fs16 col3 fwb lh22 mb5">{{ item.username }}</div>
                <div class="fs12 col6 lh18">{{ item.timer }}</div>
              </div>
            </div>
          </div>
        </div>
        <!-- 右边输入框和信息展示 -->
        <div class="infoList flex-grow-1">
          <div class="name_top flex-box pl20">
            <img
              class="avatar mr10"
              src="../assets/static/images/imgs2.png"
              alt=""
            />
            <div class="flex-grow-1 m-ellipsis fs16 col3 fwb lh22">客服001</div>
          </div>
          <!-- 信息 -->
          <div class="infoTop" ref="scrollBox" id="box">
            <div
              :class="
                item.position == 'left' ? 'chatInfoLeft' : 'chatInfoRight'
              "
              v-for="(item, index) in userInfoList"
              :key="index"
            >
              <img :src="item.url" alt="头像" class="head_portrait2" />
              <div :class="item.position == 'left' ? 'chatLeft' : 'chatRight'">
                <div class="text" v-html="item.info"></div>
              </div>
            </div>
          </div>
          <!-- 输入框 -->
          <div class="infoBottom pl15">
            <div class="infoIcon">
              <img
                class="icon cursor"
                @click="extend('照片上传')"
                src="../assets/static/icon/upload_icon.png"
                alt=""
              />
              <input class="file_input cursor" type="file" />
            </div>
            <textarea
              type="textarea"
              class="infoInput fs14 col3"
              v-model="textarea"
              @keydown.enter.exact="handlePushKeyword($event)"
              @keyup.ctrl.enter="lineFeed"
              :disabled="isshow == 1 ? false : true"
              placeholder="请输入"
            />
            <div
              class="fasong fs16 col3"
              @click="setUp"
              v-show="isshow == 1 ? true : false"
            >
              发送
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
  
  <script>
export default {
  data() {
    return {
      // 在线状态
      state: 1,
      //搜索用户
      search: "",
      //用户列表渲染数据
      userListData: [
        {
          id: 0,
          url: "https://img1.baidu.com/it/u=592570905,1313515675&fm=253&fmt=auto&app=138&f=JPEG?w=500&h=500",
          username: "花间一壶酒",
          info: "在吗",
          timer: "2022/8/9",
        },
        {
          id: 1,
          url: "https://img2.baidu.com/it/u=2859542338,3761174075&fm=253&fmt=auto&app=138&f=JPEG?w=501&h=500",
          username: "超人不换内裤",
          info: "在吗在吗",
          timer: "2022/8/1",
        },
        {
          id: 2,
          url: "https://img1.baidu.com/it/u=2029513305,2137933177&fm=253&fmt=auto&app=138&f=JPEG?w=500&h=472",
          username: "王子变蛤蟆",
          info: "你好，在吗",
          timer: "2022/8/9",
        },
        {
          id: 3,
          url: "https://img1.baidu.com/it/u=1960292808,1761809160&fm=253&fmt=auto&app=138&f=JPEG?w=500&h=500",
          username: "皇太子",
          info: "给我看一下这个情况呢",
          timer: "2022/8/2",
        },
        {
          id: 4,
          url: "https://img2.baidu.com/it/u=3684117954,695988885&fm=253&fmt=auto&app=138&f=JPEG?w=400&h=400",
          username: "飞天小女警",
          info: "模拟数据发送一下呢",
          timer: "2022/8/4",
        },
        {
          id: 5,
          url: "https://img2.baidu.com/it/u=4122738859,2522601053&fm=253&fmt=auto&app=138&f=JPEG?w=500&h=500",
          username: "罗大大大",
          info: "在吗",
          timer: "2022/8/5",
        },
        {
          id: 6,
          url: "https://img0.baidu.com/it/u=661161858,172661768&fm=253&fmt=auto&app=138&f=JPEG?w=500&h=500",
          username: "王子花",
          info: "好的，我知道了",
          timer: "2022/8/9",
        },
        {
          id: 7,
          url: "https://img2.baidu.com/it/u=835899845,548435859&fm=253&fmt=auto&app=138&f=JPEG?w=500&h=500",
          username: "开盖有奖",
          info: "试试看吧，再发一次",
          timer: "2022/8/9",
        },
        {
          id: 8,
          url: "https://img0.baidu.com/it/u=4065107391,2142799144&fm=253&fmt=auto&app=138&f=JPEG?w=527&h=500",
          username: "日本大爆炸",
          info: "在吗",
          timer: "2022/8/5",
        },
        {
          id: 9,
          url: "https://img2.baidu.com/it/u=2860188096,638334621&fm=253&fmt=auto&app=138&f=JPEG?w=500&h=500",
          username: "秋天的第一杯奶茶",
          info: "好的，我知道了",
          timer: "2022/8/9",
        },
        {
          id: 10,
          url: "https://img0.baidu.com/it/u=1694074520,2517635995&fm=253&fmt=auto&app=138&f=JPEG?w=500&h=500",
          username: "潮汐",
          info: "试试看吧，再发一次",
          timer: "2022/8/9",
        },
      ],
      //用户列表筛选数据
      userListDatas: [
        {
          id: 0,
          url: "https://img1.baidu.com/it/u=592570905,1313515675&fm=253&fmt=auto&app=138&f=JPEG?w=500&h=500",
          username: "花间一壶酒",
          info: "在吗",
          timer: "2022/8/9",
        },
        {
          id: 1,
          url: "https://img2.baidu.com/it/u=2859542338,3761174075&fm=253&fmt=auto&app=138&f=JPEG?w=501&h=500",
          username: "超人不换内裤",
          info: "在吗在吗",
          timer: "2022/8/1",
        },
        {
          id: 2,
          url: "https://img1.baidu.com/it/u=2029513305,2137933177&fm=253&fmt=auto&app=138&f=JPEG?w=500&h=472",
          username: "王子变蛤蟆",
          info: "你好，在吗",
          timer: "2022/8/9",
        },
        {
          id: 3,
          url: "https://img1.baidu.com/it/u=1960292808,1761809160&fm=253&fmt=auto&app=138&f=JPEG?w=500&h=500",
          username: "皇太子",
          info: "给我看一下这个情况呢",
          timer: "2022/8/2",
        },
        {
          id: 4,
          url: "https://img2.baidu.com/it/u=3684117954,695988885&fm=253&fmt=auto&app=138&f=JPEG?w=400&h=400",
          username: "飞天小女警",
          info: "模拟数据发送一下呢",
          timer: "2022/8/4",
        },
        {
          id: 5,
          url: "https://img2.baidu.com/it/u=4122738859,2522601053&fm=253&fmt=auto&app=138&f=JPEG?w=500&h=500",
          username: "罗大大大",
          info: "在吗",
          timer: "2022/8/5",
        },
        {
          id: 6,
          url: "https://img0.baidu.com/it/u=661161858,172661768&fm=253&fmt=auto&app=138&f=JPEG?w=500&h=500",
          username: "王子花",
          info: "好的，我知道了",
          timer: "2022/8/9",
        },
        {
          id: 7,
          url: "https://img2.baidu.com/it/u=835899845,548435859&fm=253&fmt=auto&app=138&f=JPEG?w=500&h=500",
          username: "开盖有奖",
          info: "试试看吧，再发一次",
          timer: "2022/8/9",
        },
        {
          id: 8,
          url: "https://img0.baidu.com/it/u=4065107391,2142799144&fm=253&fmt=auto&app=138&f=JPEG?w=527&h=500",
          username: "日本大爆炸",
          info: "在吗",
          timer: "2022/8/5",
        },
        {
          id: 9,
          url: "https://img2.baidu.com/it/u=2860188096,638334621&fm=253&fmt=auto&app=138&f=JPEG?w=500&h=500",
          username: "秋天的第一杯奶茶",
          info: "好的，我知道了",
          timer: "2022/8/9",
        },
        {
          id: 10,
          url: "https://img0.baidu.com/it/u=1694074520,2517635995&fm=253&fmt=auto&app=138&f=JPEG?w=500&h=500",
          username: "潮汐",
          info: "试试看吧，再发一次",
          timer: "2022/8/9",
        },
      ],
      //用户点击选中变色
      act: Number,
      // 加号弹框
      dialogVisible: false,
      //模拟花间一壶酒用户的历史信息
      userInfoList2: [
        {
          url: "https://img1.baidu.com/it/u=592570905,1313515675&fm=253&fmt=auto&app=138&f=JPEG?w=500&h=500",
          username: "花间一壶酒",
          info: "在吗",
          timer: "2022/8/9",
          position: "left",
        },
        {
          url: "https://img1.baidu.com/it/u=592570905,1313515675&fm=253&fmt=auto&app=138&f=JPEG?w=500&h=500",
          username: "花间一壶酒",
          info: "在吗",
          timer: "2022/8/9",
          position: "left",
        },
        {
          url: "https://img2.baidu.com/it/u=2859542338,3761174075&fm=253&fmt=auto&app=138&f=JPEG?w=501&h=500",
          username: "超人不换内裤",
          info: "在吗在吗",
          timer: "2022/8/1",
          position: "right",
        },
        {
          url: "https://img1.baidu.com/it/u=592570905,1313515675&fm=253&fmt=auto&app=138&f=JPEG?w=500&h=500",
          username: "花间一壶酒",
          info: "在吗",
          timer: "2022/8/9",
          position: "left",
        },
        {
          url: "https://img2.baidu.com/it/u=2859542338,3761174075&fm=253&fmt=auto&app=138&f=JPEG?w=501&h=500",
          username: "超人不换内裤",
          info: "在吗在吗",
          timer: "2022/8/1",
          position: "right",
        },
        {
          url: "https://img1.baidu.com/it/u=592570905,1313515675&fm=253&fmt=auto&app=138&f=JPEG?w=500&h=500",
          username: "花间一壶酒",
          info: "在吗在吗在吗在吗在吗在吗在吗",
          timer: "2022/8/9",
          position: "left",
        },
        {
          url: "https://img2.baidu.com/it/u=2859542338,3761174075&fm=253&fmt=auto&app=138&f=JPEG?w=501&h=500",
          username: "超人不换内裤",
          info: "在吗在吗在吗在吗在吗在吗在吗在吗在吗在吗",
          timer: "2022/8/1",
          position: "right",
        },
        {
          url: "https://img1.baidu.com/it/u=592570905,1313515675&fm=253&fmt=auto&app=138&f=JPEG?w=500&h=500",
          username: "花间一壶酒",
          info: "在吗在吗在吗在吗在吗在吗在吗在吗在吗在吗在吗在吗在吗在吗在吗在吗在吗在吗在吗在吗在吗在吗在吗在吗在吗在吗在吗在吗在吗在吗在吗在吗在吗在吗在吗在吗在吗在吗在吗在吗在吗在吗在吗",
          timer: "2022/8/9",
          position: "left",
        },
        {
          url: "https://img2.baidu.com/it/u=2859542338,3761174075&fm=253&fmt=auto&app=138&f=JPEG?w=501&h=500",
          username: "超人不换内裤",
          info: "在吗在吗在吗在吗在吗在吗",
          timer: "2022/8/1",
          position: "right",
        },
      ],
      //模拟超人不换内裤用户的历史信息
      userInfoList3: [
        {
          url: "https://img1.baidu.com/it/u=592570905,1313515675&fm=253&fmt=auto&app=138&f=JPEG?w=500&h=500",
          username: "花间一壶酒",
          info: "测试测试",
          timer: "2022/8/9",
          position: "left",
        },
        {
          url: "https://img1.baidu.com/it/u=592570905,1313515675&fm=253&fmt=auto&app=138&f=JPEG?w=500&h=500",
          username: "花间一壶酒",
          info: "测试",
          timer: "2022/8/9",
          position: "left",
        },
        {
          url: "https://img2.baidu.com/it/u=2859542338,3761174075&fm=253&fmt=auto&app=138&f=JPEG?w=501&h=500",
          username: "超人不换内裤",
          info: "测试测试测试测试",
          timer: "2022/8/1",
          position: "right",
        },
      ],
      //历史信息
      userInfoList: [],
      //输入框
      textarea: "",
      //滚动条距离顶部距离
      scrollTop: 0,
      //发送和输入显隐
      isshow: 0,
    };
  },
  methods: {
    //切换客服状态
    uploadState(state) {
      if (state !== 4) {
        this.state = state;
      } else {
        this.$confirm("是否退出登录?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            this.$message({
              type: "success",
              message: "退出成功!",
            });
          })
          .catch(() => {
            this.$message({
              type: "info",
              message: "已取消退出",
            });
          });
      }
    },
    //搜索icon
    handleIconClick() {
      console.log(1);
    },
    //点击用户
    getAct(val, index) {
      this.isshow = 1;
      // 点击用户切换数据时先清除监听滚动事件，防止出现没有历史数据的用户，滚动条为0，会触发滚动事件
      this.$refs.scrollBox.removeEventListener("scroll", this.srTop);
      //点击变色
      this.act = val.id;
      //清空消息数组
      this.userInfoList = [];
      // 模拟一下点击用户出现历史记录的样子，实际开发中是axios请求后数组赋值然后调用setPageScrollTo
      if (val.username == "花间一壶酒") {
        this.userInfoList = this.userInfoList2;
        // 直接调用不生效：因为你历史数据刚给，渲染的时候盒子高度还没有成型，所以直接调用拿不到，用个定时器让他在下一轮循环中调用，盒子就已经生成了
        this.$nextTick(() => {
          // 一定要用nextTick
          this.setPageScrollTo();
          //页面滚动条距离顶部高度等于这个盒子的高度
          this.$refs.scrollBox.scrollTop = this.$refs.scrollBox.scrollHeight;
        });
      } else if (val.username == "超人不换内裤") {
        this.userInfoList = this.userInfoList3;
        // 直接调用不生效：因为你历史数据刚给，渲染的时候盒子高度还没有成型，所以直接调用拿不到，用个定时器让他在下一轮循环中调用，盒子就已经生成了
        this.$nextTick(() => {
          // 一定要用nextTick
          this.setPageScrollTo();
          //页面滚动条距离顶部高度等于这个盒子的高度
          this.$refs.scrollBox.scrollTop = this.$refs.scrollBox.scrollHeight;
        });
      } else if (val.username == "王子变蛤蟆") {
        this.$nextTick(() => {
          // 一定要用nextTick
          this.setPageScrollTo();
          //页面滚动条距离顶部高度等于这个盒子的高度
          this.$refs.scrollBox.scrollTop = this.$refs.scrollBox.scrollHeight;
        });
      }
    },
    // 模糊搜索用户
    inquire() {
      let fuzzy = this.search;
      if (fuzzy) {
        this.userListData = this.userListDatas.filter((item) => {
          return item.username.includes(fuzzy);
        });
      } else {
        this.userListData = this.userListDatas;
      }
    },
    //发送
    setUp() {
      console.log("发送内容:", this.textarea);
      this.userInfoList2.push({
        url: "https://img2.baidu.com/it/u=2859542338,3761174075&fm=253&fmt=auto&app=138&f=JPEG?w=501&h=500",
        username: "超人不换内裤",
        info: this.textarea,
        timer: "2022/8/1",
        position: "right",
      });
      this.textarea = "";
      // 页面滚动到底部
      this.$nextTick(() => {
        // 一定要用nextTick
        this.setPageScrollTo();
        //页面滚动条距离顶部高度等于这个盒子的高度
        this.$refs.scrollBox.scrollTop = this.$refs.scrollBox.scrollHeight;
      });
    },
    // 监听键盘回车阻止换行并发送
    handlePushKeyword(event) {
      console.log(event);
      if (event.keyCode === 13) {
        event.preventDefault(); // 阻止浏览器默认换行操作
        this.setUp(); //发送文本
        return false;
      }
    },
    // 监听按的是ctrl + 回车，就换行
    lineFeed() {
      console.log("换行");
      this.textarea = this.textarea + "\n";
    },
    //点击icon
    extend(val) {
      alert("你点击了：" + val);
    },
    //滚动条默认滚动到最底部
    setPageScrollTo(s, c) {
      //获取中间内容盒子的可见区域高度
      this.scrollTop = document.querySelector("#box").offsetHeight;
      setTimeout((res) => {
        //加个定时器，防止上面高度没获取到，再获取一遍。
        if (this.scrollTop != this.$refs.scrollBox.offsetHeight) {
          this.scrollTop = document.querySelector("#box").offsetHeight;
        }
      }, 100);
      //scrollTop：滚动条距离顶部的距离。
      //把上面获取到的高度座位距离，把滚动条顶到最底部
      this.$refs.scrollBox.scrollTop = this.scrollTop;
      //判断是否有滚动条,有滚动条就创建一个监听滚动事件，滚动到顶部触发srTop方法
      if (this.$refs.scrollBox.scrollTop > 0) {
        this.$refs.scrollBox.addEventListener("scroll", this.srTop);
      }
    },
    //滚动条到达顶部
    srTop() {
      //判断：当滚动条距离顶部为0时代表滚动到顶部了
      if (this.$refs.scrollBox.scrollTop == 0) {
        //逻辑简介：
        //到顶部后请求后端的方法，获取第二页的聊天记录，然后插入到现在的聊天数据前面。
        //如何插入前面：可以先把获取的数据保存在 A 变量内，然后 this.userInfoList=A.concat(this.userInfoList)把数组合并进来就可以了

        //拿聊天记录逻辑:
        //第一次调用一个请求拉历史聊天记录，发请求时参数带上页数 1 传过去，拿到的就是第一页的聊天记录，比如一次拿20条。你显示出来
        //然后向上滚动到顶部时，触发新的请求，在请求中把分页数先 +1 然后再请求，这就拿到了第二页数据，然后通过concat合并数组插入进前面，依次类推，功能完成！
        alert("已经到顶部了");
      }
    },
  },
};
</script>
  
  <style lang="less" scoped>
.container {
  width: 100vw;
  height: 100vh;
  padding-top: 50px;
}
.wrap {
  width: 917px;
  height: calc(100vh -100px);
  background: #fbfbfb;
  box-shadow: 1px 0px 0px 0px rgba(200, 200, 200, 0.5);
  border-radius: 6px;
  margin: 0 auto;
  border: 1px solid #e0e0e0;
}

.infoBottom {
  height: 168px;
  display: flex;
  flex-direction: column;
}
/* 输入框 */
.infoInput {
  height: 135px;
  width: 100%;
  border: none;
  resize: none;
  box-sizing: border-box;
  background-color: #fff;
  color: #333;
}
.fasong {
  color: #333333;
  margin-right: 37px;
  width: 81px;
  text-align: center;
  height: 43px;
  line-height: 43px;
  border-radius: 6px;
  border: 1px solid #c3c3c3;
  cursor: pointer;
  margin-left: auto;
  margin-right: 37px;
  margin-bottom: 19px;
}
.infoIcon {
  padding: 10px 0 0 0;
  position: relative;
  .icon {
    width: 24px;
    height: 24px;
    display: block;
    object-fit: cover;
    cursor: pointer;
  }
  .file_input {
    width: 60px;
    height: 30px;
    position: absolute;
    z-index: 2;
    top: 0;
    left: -30px;
    cursor: pointer;
    opacity: 0;
  }
}

/* 头像 */
.head_portrait {
  width: 44px;
  height: 44px;
  border-radius: 50%;
}
.head_portrait2 {
  width: 44px;
  height: 44px;
  border-radius: 50%;
}
.stateBox {
  margin-left: 20px;
  padding: 1px 8px;
  background-color: #fff;
  border-radius: 10px;
  text-align: center;
  cursor: pointer;
}
.stateBox2 {
  margin-left: 20px;
  padding: 1px 8px;
  background-color: #fff;
  border-radius: 10px;
  text-align: center;
  cursor: pointer;
}
.stateBox2:hover {
  background-color: #dcdcdc;
}

/* 退出登录 */
.state4 {
  display: inline-block;
  height: 10px;
  width: 10px;
  border-radius: 50%;
  background-color: #7e7e7e;
}
.stateText {
  font-size: 14px;
  margin-left: 5px;
}
/* 列表和信息 */
.infoBox {
  height: 90%;
  width: 100%;
  display: flex;
}
.top {
  width: 100%;
  height: 132px;
  background: #bba17b;
  border-radius: 6px 0px 0px 0px;
  padding: 19px 20px 15px 20px;
  .avatar {
    width: 48px;
    height: 48px;
    display: block;
    object-fit: cover;
    border-radius: 50%;
    overflow: hidden;
    margin-right: 13px;
  }
  .vip_label {
    width: 50px;
    text-align: center;
    height: 20px;
    line-height: 20px;
    background: #fde427;
    border-radius: 10px;
    color: #774949;
    font-size: 12px;
  }
  input {
    width: 279px;
    height: 34px;
    display: block;
    line-height: 34px;
    background: #ffffff;
    border-radius: 17px;
  }
}
/* 用户列表大盒子 */
.userList {
  height: calc(100vh - 100px);
  overflow-y: scroll;
  width: 318px;
  background: #fbfbfb;
  box-shadow: 1px 0px 0px 0px rgba(200, 200, 200, 0.5);
  border-radius: 6px 0px 0px 6px;
}
/* 用户列表 */
.userListBox {
  flex: 1;
  width: 100%;
  height: calc(100vh - 232px);
  overflow: auto;
}
/* 信息外层盒子 */
.infoList {
  height: calc(100vh - 100px);
  background: #fff;
}
.name_top {
  width: 100%;
  height: 87px;
  background: #fbfbfb;
  border-radius: 6px 6px 0px 0px;
  .avatar {
    width: 48px;
    height: 48px;
    display: block;
    object-fit: cover;
    border-radius: 50%;
  }
}
/* 信息列表 */
.infoTop {
  height: calc(100vh - 355px);
  width: 100%;
  border-bottom: 1px solid #ccc;
  padding: 10px;
  box-sizing: border-box;
  overflow: auto;
}
/* 对方发的信息样式 */
.chatInfoLeft {
  min-height: 70px;
  margin-left: 10px;
  margin-top: 10px;
  display: flex;
}
.chatLeft {
  margin-left: 10px;
  flex: 1;
}
.chatLeft .text {
  font-size: 14px;
  color: #000000;
  line-height: 20px;
  margin-top: 8px;
  background: #e4e4e4;
  border-radius: 1px 8px 8px 8px;
  display: inline-block;
  padding: 6px 12px;
  max-width: 40%;
  /* 忽略多余的空白，只保留一个空白 */
  white-space: normal;
  /* 换行显示全部字符 */
  word-break: break-all;
  white-space: pre-wrap;
}

/* 自己发的信息样式 */
.chatInfoRight {
  height: 70px;
  margin-left: 10px;
  margin-top: 10px;
  display: flex;
  flex-direction: row-reverse;
}
.chatRight {
  margin-right: 10px;
  flex: 1;
  /* 用align-items把元素靠右对齐 */
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
.chatRight .text {
  font-size: 14px;
  color: #ffffff;
  line-height: 20px;
  margin-top: 8px;
  background: #bba17b;
  border-radius: 8px 1px 8px 8px;
  display: inline-block;
  padding: 6px 12px;
  max-width: 40%;
  /* 忽略多余的空白，只保留一个空白 */
  white-space: normal;
  /* 换行显示全部字符 */
  word-break: break-all;
  white-space: pre-wrap;
}

/* 点击用户变色 */
.userFlexAct {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 77px;
  cursor: pointer;
  background: #e8e8e8;
}
/* 用户默认颜色 */
.userFlex {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 77px;

  background: #fbfbfb;
  cursor: pointer;
}
.userFlex:last-of-type {
  border-bottom: 1px solid #dfdfdf;
}
/* 用户名 */
.userName {
  width: 100px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
/* 简略信息 */
.userInfo {
  width: 100px;
  font-size: 14px;
  color: #ccc;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-top: 3px;
}

/* 滚动条样式 */
::-webkit-scrollbar {
  width: 5px;
  height: 10px;
}
::-webkit-scrollbar-thumb {
  background-color: #dbd9d9;
  border-radius: 3px;
}
</style>
  