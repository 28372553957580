<template>
  <div class="container">
    <Header @input="input"></Header>
    <div class="main pr pt68 flex">
      <Mine></Mine>
      <div class="right_part flex-grow-1 ml20">
        <div class="mt10 lh28 fs20 fwb mb50">
          <span class="col6 cursor">我的订单/</span>
          <span class="col3 cursor">订单详情</span>
        </div>
        <div class="flex plr35 state_part mb30">
          <div class="flex-grow-1">
            <img class="state_icon" src="../assets/static/icon/orderProgress_on.png" alt="" />
            <div class="tc fs14 lh20 col0c">待支付</div>
          </div>
          <div :class="data.pay_status == 1 ? 'line1_on' : 'line1'"></div>
          <div class="flex-grow-1">
            <img v-if="data.pay_status == 1" class="state_icon" src="../assets/static/icon/orderProgress_on.png"
              alt="" />
            <img v-else class="state_icon" src="../assets/static/icon/orderProgress.png" alt="" />
            <div class="tc fs14 lh20" :class="data.pay_status == 1 ? 'col0c' : 'col3_5'">
              待开始
            </div>
          </div>
          <div :class="data.start_status == 1 ? 'line2_on' : 'line2'"></div>
          <div class="flex-grow-1">
            <img v-if="data.start_status == 1" class="state_icon" src="../assets/static/icon/orderProgress_on.png"
              alt="" />
            <img v-else class="state_icon" src="../assets/static/icon/orderProgress.png" alt="" />
            <div class="tc fs14 lh20" :class="data.start_status == 1 ? 'col0c' : 'col3_5'">
              已开始
            </div>
          </div>
          <div :class="data.cancel_status == 1 ? 'line3_on' : 'line3'"></div>
          <div class="flex-grow-1">
            <img v-if="data.cancel_status == 1" class="state_icon" src="../assets/static/icon/orderProgress_on.png"
              alt="" />
            <img v-else class="state_icon" src="../assets/static/icon/orderProgress.png" alt="" />
            <div class="tc fs14 lh20" :class="data.cancel_status == 1 ? 'col0c' : 'col3_5'">
              已取消
            </div>
          </div>
        </div>
        <div class="fs20 col3 fwb lh28 mb30 plr10">订单信息</div>
        <div class="flex fs14 col3 plr10 mb25 lh20">
          <div class="col3 flex-grow-1">订单号: {{ data.order_no }}</div>
          <div v-if="data.pay_status == 1" class="col3 flex-grow-1">报名时间: {{ data.paytime_text }}</div>
        </div>
        <div class="flex fs14 col3 plr10 mb40 lh20">
          <div class="col3 flex-grow-1">姓名: {{ data.name }}</div>
          <div class="col3 flex-grow-1">联系电话: {{ data.mobile }}</div>
          <!-- <div v-if="data.pay_price > 0" class="col3 flex-grow-1">支付方式：{{ data.pay_type == 1 ? '微信支付' : '支付宝支付' }}</div> -->
        </div>
        <div class="fs20 col10 fwb lh40 mb15">您报名的活动</div>
        <div class="ptb20 flex product">
          <img class="img" :src="oss+data.image+w500" alt="" />
          <div class="flex-grow-1">
            <div class="fs20 col10 fwb lh44 m-ellipsis">
              {{ data.activity_name }}
            </div>
            <div class="fs16 col6 lh32 mb10">开始时间：{{ data.starttime_text }}</div>
            <div class="fs16 col6 lh28">
              地址：{{ data.address }}
            </div>
          </div>
        </div>
        <div class="border_t pt20 pl223">
          <div class="flex-box">
            <div class="fs16 col85 lh22">报名费</div>
            <div class="flex-grow-1 fs16 col10 fwb lh40 tr">{{ data.pay_price == 0 ? '免费' : 'RMB ' + data.pay_price }}
            </div>
          </div>
          <div v-if="data.pay_price > 0" class="border_t flex-box mb40">
            <div class="fs18 col10 fwb lh44">总计</div>
            <div class="flex-grow-1 tr fs18 col10 fwb lh44">RMB {{ data.pay_price }}</div>
          </div>
          <div class="flex-box flex-end mb50">
            <div @click="cancel()" v-if="data.pay_status == 1 && data.start_status == 0 && data.cancel_status == 0"
              class="btn_buy1 col9 tc fs18 ml40 cursor">取消订单</div>
            <div v-if="data.pay_status == 0 && data.start_status == 0 && data.cancel_status == 0"
              class="fs18 colf tc btn_buy2 ml40 cursor">
              立即支付
            </div>
          </div>
        </div>
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>
<script>
import { useHead } from "@unhead/vue";
import axios from "@/axios";
import router from "@/router";
import { getCurrentInstance } from "vue";
import { ElMessage, ElMessageBox } from "element-plus";
export default {
  name: "index",

  setup() {
    useHead({
      title: "报名详情",
      meta: [
        { name: "description", content: "This is my page description" },
        { property: "og:title", content: "My Page Title" },
        { property: "og:description", content: "This is my page description" },
        { property: "og:url", content: "https://example.com/my-page" },
        { property: "og:image", content: "https://example.com/my-image.jpg" },
      ],
      link: [{ rel: "canonical", href: "https://example.com/my-page" }],
    });
  },
  data() {
    return {
      oss: getCurrentInstance().appContext.config.globalProperties.$oss,
      w1000: getCurrentInstance().appContext.config.globalProperties.$w1000,
      w500: getCurrentInstance().appContext.config.globalProperties.$w500,
      state: 0, //0待支付  1待开始  2已开始  3已结束
      data: '',
      order_id: ''
    };
  },
  created() {
    this.order_id = this.$route.params.order_id
    this.getDetail()
  },
  methods: {
    cancel() {
      ElMessageBox.confirm('确认取消报名？', '提示', {
        confirmButtonText: '确认',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        // 确定按钮被点击的回调
        console.log('确定按钮被点击');
        axios.post('activity/cancel_order', {
          order_id: this.order_id
        }).then(res => {
          if (res.code == 1) {
            ElMessageBox.alert(res.msg, '提示', {
              confirmButtonText: '确定',
            });
            this.getDetail()
          } else {
            ElMessageBox.alert(res.msg, '提示', {
              confirmButtonText: '确定',
            });
          }
        })
      }).catch(() => {
        // 取消按钮被点击的回调
        console.log('取消按钮被点击');
      });
    },
    getDetail() {
      axios.post('activity/order_detail', {
        order_id: this.order_id,
      })
        .then(res => {
          if (res.code == 1) {
            res.data.pay_price = parseFloat(res.data.pay_price)
            this.data = res.data
          } else {
            ElMessage.error(res.msg)
          }
        })
        .catch(error => {
          console.error(error);
        });
    },
  },
};
</script>

<style lang="less" scoped>
.container {
  background: #f6f6f6;
  width: 100vw;
}

.main {
  width: 1200px;
  margin: 20px auto;
}

.mw200 {
  max-width: 200px;
}

.border_b {
  border-bottom: 1px solid #eeeff0;
}

.right_part {
  background: #fdfdfd;
  border-radius: 13px;
  overflow: hidden;
  padding: 25px 20px;

  .state_part {
    position: relative;
  }

  .state_icon {
    width: 22px;
    height: 22px;
    display: block;
    object-fit: cover;
    margin: 0 auto 5px;
    position: relative;
    z-index: 2;
  }

  .line1_on,
  .line1,
  .line2,
  .line2_on,
  .line3_on,
  .line3 {
    width: 153px;
    height: 1px;
    background: #0c0c0c;
    position: absolute;
    z-index: 2;
    top: 10px;
    left: 130px;
  }

  .line1,
  .line2,
  .line3 {
    background-color: #bcbcbc;
  }

  .line2_on,
  .line2 {
    left: 302px;
  }

  .line3_on,
  .line3 {
    right: 132px;
    left: unset;
  }

  .border_t {
    border-top: 1px solid #e3e3e3;
  }

  .img {
    width: 129px;
    height: 129px;
    display: block;
    object-fit: cover;
    margin-right: 32px;
    border-radius: 10px;
    overflow: hidden;
  }

  .mb8 {
    margin-bottom: 8px;
  }

  .pl223 {
    padding-left: 149px;
  }

  .pb37 {
    padding-bottom: 37px;
  }

  .btn_buy1,
  .btn_buy2,
  .btn_buy3 {
    width: 158px;
    height: 55px;
    line-height: 55px;
    background: #bba17b;
    border-radius: 5px;
  }

  .btn_buy1,
  .btn_buy3 {
    background: #fff;
    line-height: 53px;
    border: 1px solid #e4e4e4;
  }

  .btn_buy3 {
    border-color: #bba17b;
    color: #bba17b;
  }

  .label_sh {
    width: 69px;
    text-align: center;
    height: 23px;
    line-height: 23px;
    background: #f0f0f7;
    border-radius: 12px;
  }
}
</style>