<template>
  <div class="container">
    <Header @input="input"></Header>
    <div class="main pr pt68">
      <div class="change flex-box mb20">
        <div @click="toCity()" class="col-10 cursor">{{ city_name }}</div>
        <img @click="toCity()" class="arrow cursor" src="../assets/static/icon/arrow_down.png" alt="" />
      </div>
      <div class="flex flex-wrap">
        <view v-for="(item, index) in nowList.data" :key="index" class="part3 mb30 mr20 cursor">
          <img class="store_imgs mb30" :src="oss + item.image + w500" alt="" />
          <div class="m-ellipsis fs30 col0 fwb lh34 mb15">
            {{ item.shop_name }}
          </div>
          <div class="pr85 m-ellipsis-l2 lh28 fs20 col85 mb30">
            {{ item.address }}
          </div>
          <div class="fs18 col3 lh24">{{ item.time_desc }}</div>
        </view>
      </div>
      <div class="nomore" v-if="isShowNavBarLoading">没有更多了~</div>
      <!-- sumCount是数据总数50条 perCount是每页有几条 pageTurn是页数切换的方法 每页显示多少条在组件pagination.vue里修改 -->
      <Pagination v-else :sumCount="nowList.total" :perCount="pageSize" @pageTurn="pageTurn"></Pagination>
    </div>
    <Footer></Footer>
  </div>
</template>
<script>
import { useHead } from "@unhead/vue";
import Pagination from "../components/pagination.vue";
import axios from "@/axios";
import { getCurrentInstance } from "vue";
import router from "@/router";
import { ElMessage } from "element-plus";
export default {
  name: "index",

  setup() {
    useHead({
      title: "门店列表",
      meta: [
        { name: "description", content: "This is my page description" },
        { property: "og:title", content: "My Page Title" },
        { property: "og:description", content: "This is my page description" },
        { property: "og:url", content: "https://example.com/my-page" },
        { property: "og:image", content: "https://example.com/my-image.jpg" },
      ],
      link: [{ rel: "canonical", href: "https://example.com/my-page" }],
    });
  },
  components: {
    Pagination,
  },
  data() {
    return {
      oss: getCurrentInstance().appContext.config.globalProperties.$oss,
      w1000: getCurrentInstance().appContext.config.globalProperties.$w1000,
      w500: getCurrentInstance().appContext.config.globalProperties.$w500,
      isShowNavBarLoading: false,
      pageSize: 9,
      nowList: {
        total: 0,
        current_page: 0,
        last_page: 1,
        data: [],
      },
      city_name: '选择城市',
      shop_country_id: '',
      shop_city_id: '',
      home: 0,
    };
  },
  created() {
    this.home = this.$route.params.home
    this.shop_country_id = this.$route.params.shop_country_id
    this.shop_city_id = this.$route.params.shop_city_id
    this.city_name = this.$route.params.city_name
    this.pageTurn(0);
  },
  methods: {
    toCity() {
      if (this.home == 1) {
        router.push({
          name: "selectCity"
        });
      } else {
        window.history.back();
      }
    },
    pageTurn(page) {
      this.getList(page + 1);
    },

    getList(page) {
      axios
        .post("index/shop_list_pc", {
          shop_country_id: this.shop_country_id,
					shop_city_id: this.shop_city_id,
          page: page,
          pagesize: this.pageSize,
        })
        .then((res) => {
          this.nowList.total = res.data.total;
          this.nowList.current_page = page;
          this.nowList.last_page = res.data.last_page;
          var data = res.data.data;
          if (data) {
            this.nowList.data = data;
          }
        });
    },
  },
};
</script>

<style lang="less" scoped>
.change {
  line-height: 86px;
  font-size: 32px;
  color: #101010;
  font-weight: bold;

}

.arrow {
  width: 16px;
  height: 16px;
  display: block;
  object-fit: contain;
  margin-left: 10px;
}

.container {
  background: #f6f6f6;
  width: 100vw;
  padding-top: 68px;
}

.main {
  width: calc(100vw - 50px);
  margin: 0 auto 30px;
}

.part3 {
  width: 450px;

  .store_imgs {
    width: 450px;
    height: 312px;
    object-fit: cover;
  }
}

.part3:nth-of-type(3n) {
  margin-right: 0;
}
</style>