<template>
    <div class="container bgf flex">
        <img class="login_bj" src="../assets/static/images/login_bj.png" alt="" />
        <div class="flex-grow-1 bgf pt174">
            <div class="main">
                <div class="fs22 col10 fwb lh33 mb35">手机验证码登录</div>
                <div class="input_box fs15 flex-box mb20">
                    <img class="phone_icon" src="../assets/static/icon/phone_icon.png" alt="" />
                    <div class="fs15 col89">手机号码</div>
                    <input type="text" class="flex-grow-1 tr fs15 col10 input1" placeholder="请输入电话号码"
                        v-model="mobile" />
                </div>
                <div class="input_box fs15 flex-box mb20">
                    <img class="phone_icon" src="../assets/static/icon/code_icon.png" alt="" />
                    <input type="text" class="flex-grow-1 fs15 col89 input2" placeholder="输入短信验证码" v-model="code" />
                    <a href="javascript:;" class="send colb fs15" v-if="show" @click="getCode">{{ tips }}</a>
                </div>
                <div class="flex-box fs14 mb60">
                    <img @click="clickagree" v-if="agree" class="checked cursor" src="../assets/static/icon/checked.png"
                        alt="" />
                    <img @click="clickagree" v-else class="checked cursor" src="../assets/static/icon/check.png"
                        alt="" />
                    <div class="col6">阅读并同意</div>
                    <div class="colb cursor" @click="rule1">《服务协议》</div>及
                    <div class="colb cursor" @click="rule2">《隐私政策》</div>
                </div>
                <div @click="login" class="btn fs16 colf tc cursor">登录</div>
            </div>
        </div>
    </div>
</template>
<script>
import { useHead } from "@unhead/vue";
import axios from "@/axios";
import { ElMessage } from "element-plus";
import router from "@/router";

export default {
    name: "lohin",
    setup() {
        useHead({
            title: "登录",
            meta: [
                { name: "description", content: "This is my page description" },
                { property: "og:title", content: "My Page Title" },
                { property: "og:description", content: "This is my page description" },
                { property: "og:url", content: "https://example.com/my-page" },
                { property: "og:image", content: "https://example.com/my-image.jpg" },
            ],
            link: [{ rel: "canonical", href: "https://example.com/my-page" }],
        });
    },
    data() {
        return {
            show: true,
            code: "",
            count: "",
            agree: false,
            mobile: '',
            secend: 60,
            tips: '发送验证码',
            canGetCode: true,
        };
    },
    methods: {
        rule1() {
            window.open('https://ag.p.xilukeji.com/index.php/index/index/rule_user', '_blank');
        },
        rule2() {
            window.open('https://ag.p.xilukeji.com/index.php/index/index/rule_secret', '_blank');
        },
        getCode() {
            if (this.canGetCode) {
                if (this.mobile == '') {
                    ElMessage.error('请填写手机号码')
                    return;
                }
                axios.post('sms/send', {
                    mobile: this.mobile
                }).then(res => {
                    if (res.code == 1) {
                        this.canGetCode = false;
                        ElMessage.success('验证码已发送')
                        this.secend = 59;
                        var s = setInterval(res => {
                            this.tips = this.secend + '秒'
                            this.secend--;
                            if (this.secend < 0) {
                                clearInterval(s)
                                this.canGetCode = true;
                                this.tips = '发送验证码';
                            }
                        }, 1000)
                    } else {
                        ElMessage.error(res.msg)
                        return;
                    }
                }).catch(error => {
                    console.error(error);
                });
            } else {
                ElMessage.error('倒计时结束后再发送')
            }
        },
        login() {
            if (!this.agree) {
                ElMessage.warning('请阅读并同意下方内容');
                return;
            }
            var mobile = this.mobile;
            if (mobile == '') {
                ElMessage.warning('请填写手机号码');
                return;
            }
            var reg = /^1\d{10}$/;
            if (!reg.test(mobile)) {
                ElMessage.warning('手机号码格式错误');
                return;
            }
            var code = this.code;
            if (code == '') {
                ElMessage.warning('请填写验证码');
                return;
            }
            axios.post('user/mobileLogin', {
                mobile: this.mobile,
                captcha: this.code,
            })
                .then(res => {
                    localStorage.setItem('token', res.data)
                    ElMessage.success('登录成功');
                    setTimeout(res => {
                        router.push('/')
                    }, 1500)
                })
                .catch(error => {
                    console.error(error);
                });
        },
        clickagree() {
            this.agree = !this.agree;
        },
        randomNum(min, max) {
            return Math.floor(Math.random() * (max - min) + min);
        },
        sendSms: function () {
            var that = this;
            const TIME_COUNT = 60;

            if (!that.timer) {
                that.count = TIME_COUNT;
                that.show = false;
                that.timer = setInterval(() => {
                    if (that.count > 0 && that.count <= TIME_COUNT) {
                        that.count--;
                    } else {
                        that.show = true;

                        clearInterval(that.timer);

                        that.timer = null;
                    }
                }, 1000);
            }
            var event = "register";
            if (this.forgetPop) {
                event = "resetpwd";
            }
        },
    },
};
</script>
<style lang="less" scoped>
.container {
    height: 100vh;
    overflow: hidden;
}

.login_bj {
    width: 68%;
    height: 100%;
    display: block;
    object-fit: cover;
}

.main {
    width: 320px;
    margin: 174px auto 0;

    .input_box {
        width: 320px;
        padding: 0 15px;
        height: 50px;
        line-height: 50px;
        border-radius: 5px;
        border: 1px solid #eeeff0;

        input {
            border: none;
            background: none;
            padding: unset;
        }

        .phone_icon {
            width: 15px;
            height: 15px;
            display: block;
            object-fit: cover;
            margin-right: 5px;
        }
    }

    .checked {
        width: 14px;
        height: 14px;
        display: block;
        margin-right: 6px;
    }

    .btn {
        width: 320px;
        height: 48px;
        line-height: 48px;
        background: #bba17b;
        border-radius: 5px;
        margin: 0 auto;
    }
}

.input1::placeholder {
    font-size: 15px;
    color: #101010 !important;
}

.input2::placeholder {
    font-size: 15px;
    color: #898989 !important;
}
</style>
