import axios from 'axios';
import getBuiltIn from "core-js/internals/get-built-in";
import router from "@/router";
import {ElMessage} from 'element-plus';

var Promise = getBuiltIn('Promise');

const instance = axios.create({
    // baseURL: 'http://ag-test.com/index.php/api/',
    baseURL: 'https://ag-test.p.xilukeji.com/index.php/api/',
    headers: {
        "Content-Type": "application/json",
    }
});

// 添加请求拦截器
instance.interceptors.request.use((config) => {
    // 获取凭证
    const token = localStorage.getItem('token');
    // 如果有凭证就加上此凭证
    config.headers.Token = `${token}`;
    return config;
}, (error) => {
    return Promise.reject(error)
});

// 添加响应拦截器
instance.interceptors.response.use(
    (response) => {
        const {data} = response
        // 统一处理响应结果
        if (data.code === 1) {
            return data;
        } else {
            // ElMessage.success('这是成功提示消息');
            // ElMessage.error('这是错误提示消息');
            ElMessage.warning(data.msg);
            // return Promise.reject(data);
        }
    },
    (error) => {
        if (error.response.data.code == 401) {
            router.replace('/login');
        }
        // 统一处理错误信息
        return Promise.reject(error);
    }
);

export default instance;