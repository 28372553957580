<template>
  <div class="container">
    <Header @input="input"></Header>
    <div class="main pr pt68 flex">
      <Mine></Mine>
      <div class="right_part flex-grow-1 ml20">
        <div class="mt10 lh28 fs20 fwb mb50">
          <span class="col6">我的订单/</span>
          <span class="col3">订单详情</span>
        </div>
        <div class="flex plr35 state_part mb30">
          <div class="flex-grow-1">
            <img
              class="state_icon"
              src="../assets/static/icon/orderProgress_on.png"
              alt=""
            />
            <div class="tc fs14 lh20 col0c">待支付</div>
          </div>
          <div :class="true ? 'line1_on' : 'line1'"></div>
          <div class="flex-grow-1">
            <img
              v-if="state >= 1"
              class="state_icon"
              src="../assets/static/icon/orderProgress_on.png"
              alt=""
            />
            <img
              v-else
              class="state_icon"
              src="../assets/static/icon/orderProgress.png"
              alt=""
            />
            <div class="tc fs14 lh20" :class="state >= 1 ? 'col0c' : 'col3_5'">
              待发货
            </div>
          </div>
          <div :class="state >= 1 ? 'line2_on' : 'line2'"></div>
          <div class="flex-grow-1">
            <img
              v-if="state >= 2"
              class="state_icon"
              src="../assets/static/icon/orderProgress_on.png"
              alt=""
            />
            <img
              v-else
              class="state_icon"
              src="../assets/static/icon/orderProgress.png"
              alt=""
            />
            <div class="tc fs14 lh20" :class="state >= 2 ? 'col0c' : 'col3_5'">
              已发货
            </div>
          </div>
          <div :class="state >= 2 ? 'line3_on' : 'line3'"></div>
          <div class="flex-grow-1">
            <img
              v-if="state >= 3"
              class="state_icon"
              src="../assets/static/icon/orderProgress_on.png"
              alt=""
            />
            <img
              v-else
              class="state_icon"
              src="../assets/static/icon/orderProgress.png"
              alt=""
            />
            <div class="tc fs14 lh20" :class="state >= 2 ? 'col0c' : 'col3_5'">
              已完成
            </div>
          </div>
        </div>
        <div class="fs20 col3 fwb lh28 mb30 plr10">订单信息</div>
        <div class="flex flex-between fs14 col3 plr10 mb25 lh20">
          <div class="col3">订单号: 678767868767678</div>
          <div>下单时间: 2024.04.16 15:11:66</div>
          <div>下单时间: 2024.04.16 15:11:66</div>
        </div>
        <div class="fs14 col3 lh20 mb40 plr10">支付方式：微信支付</div>
        <div class="fs20 col10 fwb lh40 mb15">您购买的商品</div>
        <div class="ptb20 flex product plr10">
          <img class="img" src="../assets/static/images/device1.png" alt="" />
          <div class="flex-grow-1">
            <div class="fs20 col10 fwb lh44 m-ellipsis">
              DrArrivo 家用脸面部提拉紧致微电流 宙斯二代美容仪
            </div>
            <div class="fs12 col6  lh20">DrArrivo 家用脸面部提拉紧致微电流 宙斯二代美容仪</div>
            <div class="fs18 col10 fwb lh44 mb5">RMB 7,999</div>
            <div class="flex-box">
              <div class="flex-grow-1 fs18 col85">黑色*1</div>
              <div class="colb fs14 tc label_sh cursor">申请售后</div>
            </div>
          </div>
        </div>
        <div class="border_t pt20 pl223 plr10">
          <div class="flex-box">
            <div class="fs16 col85 lh22">订单金额</div>
            <div class="flex-grow-1 fs16 col10 fwb lh40 tr">RMB 1,9990</div>
          </div>
          <div class="flex-box">
            <div class="fs16 col85 lh24">优惠</div>
            <div class="flex-grow-1 fs16 col10 fwb lh40 tr">RMB 5990</div>
          </div>
          <div class="flex-box mb10">
            <div class="fs16 col85 lh22">运费</div>
            <div class="flex-grow-1 fs16 col10 fwb lh44 tr">RMB 24</div>
          </div>
          <div class="border_t flex-box mb40">
            <div class="fs18 col10 fwb lh44">总计</div>
            <div class="flex-grow-1 tr fs18 col10 fwb lh44">RMB 1,5999</div>
          </div>
          <!-- <div class="flex-box flex-end mb50">
              <template v-if="state == 0">
                <div class="btn_buy1 col9 tc fs18 ml40">取消订单</div>
                <div class="fs18 colf tc btn_buy2 ml40">立即支付</div>
              </template>
              <div v-if="state == 2" class="fs18 colf tc btn_buy2 ml40">
                查看物流
              </div>
              <template v-if="state == 3">
                <div class="btn_buy1 btn_buy3 tc fs18 ml40">查看物流</div>
                <div class="fs18 colf tc btn_buy2 ml40">A CARE</div>
              </template>
            </div> -->
        </div>
      </div>
    </div>
    <Footer></Footer>
  </div>
</template> <script>
import { useHead } from "@unhead/vue";
export default {
  name: "index",

  setup() {
    useHead({
      title: "订单详情",
      meta: [
        { name: "description", content: "This is my page description" },
        { property: "og:title", content: "My Page Title" },
        { property: "og:description", content: "This is my page description" },
        { property: "og:url", content: "https://example.com/my-page" },
        { property: "og:image", content: "https://example.com/my-image.jpg" },
      ],
      link: [{ rel: "canonical", href: "https://example.com/my-page" }],
    });
  },
  data() {
    return {
      state: 3, //0待支付  1待发货  2待收货  3已完成
    };
  },

  methods: {},
};
</script>
    
    <style lang="less" scoped>
.container {
  background: #f6f6f6;
  width: 100vw;
}
.main {
  width: 1200px;
  margin: 20px auto;
}
.mw200 {
  max-width: 200px;
}
.border_b {
  border-bottom: 1px solid #eeeff0;
}

.right_part {
  background: #fdfdfd;
  border-radius: 13px;
  overflow: hidden;
  padding: 25px 20px;
  .state_part {
    position: relative;
  }
  .state_icon {
    width: 22px;
    height: 22px;
    display: block;
    object-fit: cover;
    margin: 0 auto 5px;
    position: relative;
    z-index: 2;
  }
  .line1_on,
  .line1,
  .line2,
  .line2_on,
  .line3_on,
  .line3 {
    width: 153px;
    height: 1px;
    background: #0c0c0c;
    position: absolute;
    z-index: 2;
    top: 10px;
    left: 130px;
  }
  .line1,
  .line2,
  .line3 {
    background-color: #bcbcbc;
  }
  .line2_on,
  .line2 {
    left: 302px;
  }
  .line3_on,
  .line3 {
    right: 132px;
    left: unset;
  }
  .border_t {
    border-top: 1px solid #e3e3e3;
  }
  .img {
    width: 129px;
    height: 129px;
    display: block;
    object-fit: cover;
    margin-right: 28px;
  }
  .mb8 {
    margin-bottom: 8px;
  }
  .pl223 {
    padding-left: 149px;
  }
  .pb37 {
    padding-bottom: 37px;
  }
  .btn_buy1,
  .btn_buy2,
  .btn_buy3 {
    width: 158px;
    height: 55px;
    line-height: 55px;
    background: #bba17b;
    border-radius: 5px;
  }
  .btn_buy1,
  .btn_buy3 {
    background: #fff;
    line-height: 53px;
    border: 1px solid #e4e4e4;
  }
  .btn_buy3 {
    border-color: #bba17b;
    color: #bba17b;
  }
  .label_sh {
    width: 69px;
    text-align: center;
    height: 23px;
    line-height: 23px;
    background: #f0f0f7;
    border-radius: 12px;
  }
}
</style>
              
    
      
      