<template>
  <div class="container pr pt68">
    <Header @input="input"></Header>
    <div class="main">
      <div class="flex border_b pb30 mb30">
        <img class="imgs mr50" src="../assets/static/images/device1.png" alt="" />
        <div class="flex-grow-1">
          <div class="m-ellipsis fs24 col10 fwb lh44 mb10">
            {{ order_goods.goods_name }}
          </div>
          <div class="fs24 col10 fwb lh44 mb45">RMB {{ order_goods.sku_price }}</div>
          <div class="fs20 col85">{{ order_goods.sku_title }} * {{ order_goods.total_num }}</div>
        </div>
      </div>
      <div class="fs28 col10 fwb lh60 mb20">申请类型：选择申请类型</div>
      <div class="flex flex-wrap mb20 tab2">
        <div class="tablist mb20 cursor" v-for="(item, index) in tablist" :key="index" @click="testTabClick1(index)"
          :class="tabIndex == index ? 'active' : 'unactive'">
          {{ item }}
        </div>
      </div>
      <div class="fs28 col10 fwb lh60 mb20">申请原因：请选择申请原因</div>
      <div class="flex flex-wrap mb20 tab2 cursor">
        <div class="tablist mb20" v-for="(item, index) in tablist2" :key="index" @click="testTabClick2(index)"
          :class="tabIndex2 == index ? 'active' : 'unactive'">
          {{ item }}
        </div>
      </div>
      <div class="fs28 col10 fwb lh60 mb20">退款金额：请编辑您的退款金额</div>
      <div class="part1 mb40 flex-box fs14">
        <div class="col10 flex-grow-1 tr fwb">RMB</div>
        <input disabled class="flex-grow-1 tl col10" :value="order_goods.real_pay" placeholder="请输入" type="text" />
      </div>
      <div class="fs28 col10 fwb lh60 mb20">申请说明：请编辑你的申请说明</div>
      <textarea class="textarea fs14 mb40" v-model="content" placeholder="请描述您的设备故障" name="" id=""></textarea>
      <div class="fs28 col10 fwb lh60 mb35">申请照片：请上传申请照片。</div>
      <div class="flex flex-wrap mb50">
        <div v-for="(item, index) in images" :key="index" class="part2 mb20">
          <div class="pr">
            <img class="img" :src="item" alt="" />
            <div @click="uploadDel(index)" class="close_part cursor">
              <img class="icon" src="../assets/static/icon/close_icon.png" alt="" />
            </div>
          </div>
          <!-- <div class="tc fs16 col10 m-ellipsis">设备照片</div> -->
        </div>
        <el-upload class="avatar-uploader" action="https://ag-test.p.xilukeji.com/api/common/upload"
          :show-file-list="false" multiple="true" :on-change="handleChange" :on-success="handleAvatarSuccess">
          <div class="upload cursor">
            <img class="icon" src="../assets/static/icon/uppload_icon.png" alt="" />
          </div>
        </el-upload>

      </div>

      <div @click="submit" class="btn cursor fs20 colf">确认提交</div>
    </div>
    <Footer></Footer>
  </div>
</template>
<script>
import { useHead } from "@unhead/vue";
import axios from "@/axios";
import router from "@/router";
import { getCurrentInstance } from "vue";
import { ElMessage, ElMessageBox } from "element-plus";
export default {
  name: "applyAfterSales",
  setup() {
    useHead({
      title: "售后申请",
      meta: [
        { name: "description", content: "This is my page description" },
        { property: "og:title", content: "My Page Title" },
        { property: "og:description", content: "This is my page description" },
        { property: "og:url", content: "https://example.com/my-page" },
        { property: "og:image", content: "https://example.com/my-image.jpg" },
      ],
      link: [{ rel: "canonical", href: "https://example.com/my-page" }],
    });
  },
  data() {
    return {
      oss: getCurrentInstance().appContext.config.globalProperties.$oss,
      w1000: getCurrentInstance().appContext.config.globalProperties.$w1000,
      w500: getCurrentInstance().appContext.config.globalProperties.$w500,
      tabIndex: 0,
      tablist: ['仅退款', '退货退款'],
      tabIndex2: 0,
      tablist2: ['不喜欢', '空包裹', '一直未送达', '颜色/尺码不符', '质量问题', '少件漏发', '假冒品牌'],
      data: '',
      images: [],
      content: '',
      order_goods: '',
      order_id: '',
      order_goods_id: ''
    };
  },
  created() {
    this.order_id = this.$route.params.order_id
    this.order_goods_id = this.$route.params.order_goods_id
    this.getDetail()
  },
  methods: {
    uploadDel(index) {
      (this.images).splice(index, 1)
    },
    handleAvatarSuccess(e) {
      this.images.push(e.data.fullurl)
    },
    getDetail() {
      axios.post('xilushop/order/order_detail', {
        type: 1,
        order_id: this.order_id,
      }).then(res => {
        if (res.code == 1) {
          this.data = res.data
          for (var i in res.data.order_goods) {
            if (res.data.order_goods[i].id == this.order_goods_id) {
              this.order_goods = res.data.order_goods[i]
              break;
            }
          }
        } else {
          ElMessage.error(res.msg)
        }
      }).catch(error => {
        console.error(error);
      });
    },
    submit() {
      if (!this.content) {
        ElMessage.error('请填写申请说明')
        return false
      }
      if (this.images[0]) {
        var images_str = this.images.join(',')
      } else {
        var images_str = ''
      }
      axios.post('xilushop/aftersale/apply_aftersale', {
        order_id: this.order_id,
        order_goods_id: this.order_goods_id,
        expressType: 1,
        type: this.tabIndex,
        reason: this.tablist2[this.tabIndex2],
        refund_content: this.content,
        num: this.order_goods.total_num,
        price: this.order_goods.real_pay,
        images: images_str
      }).then(res => {
        if (res.code == 1) {
          ElMessage.success(res.msg)
          setTimeout(res => {
            window.history.back();
          }, 1500)
        } else {
          ElMessage.error(res.msg)
        }
      })
    },
    testTabClick1(index) {
      console.log(index);
      this.tabIndex = index;
    },
    testTabClick2(index) {
      console.log(index);
      this.tabIndex2 = index;
    },
  },
};
</script>
<style lang="less" scoped>
.container {
  background: #fff;
  width: 100vw;
  overflow-x: hidden;
}

.main {
  width: 805px;
  margin: 40px auto 85px;
}

.imgs {
  width: 173px;
  height: 173px;
  display: block;
  object-fit: cover;
}

.border_b {
  border-bottom: 1px solid #e3e3e3;
}

.imgs2 {
  width: 144px;
  height: 144px;
  display: block;
  object-fit: cover;
}

.part1 {
  width: 386px;
  height: 77px;
  line-height: 77px;
  background: #f1f2f4;
  border-radius: 8px;

  input {
    background: transparent;
    padding: 0;
    margin: 0;
    font-weight: 400;
    font-size: 14px;
    color: #101010;
  }
}

.part3 {
  background: none;
}

.part1:nth-of-type(2n) {
  margin-right: 0;
  line-height: 20px;
}

.textarea {
  border: none;
  resize: none;
  /* 去除选中后的边框 */
  outline: none;
  resize: none;
  width: 805px;
  min-height: 147px;
  background: #f5f5f5;
  border-radius: 8px;
  padding: 20px;
}

.part2 {
  width: 108px;
  margin-right: 10px;

  .img {
    width: 108px;
    height: 108px;
    display: block;
    object-fit: cover;
    border-radius: 9px;
    overflow: hidden;
    border: 1px solid #dddddd;
    margin: 0 auto 12px;
  }

  .close_part {
    width: 100%;
    height: 20px;
    background: rgba(0, 0, 0, 0.4);
    border-radius: 0px 0px 9px 9px;
    position: absolute;
    z-index: 2;
    left: 0;
    bottom: 0;
    padding: 3px 0;

    .icon {
      width: 13px;
      height: 13px;
      display: block;
      object-fit: cover;
      margin: 0 auto;
    }
  }
}

.upload {
  width: 106px;
  height: 106px;
  background: #f1f2f4;
  border-radius: 9px;
  padding: 44px 0;

  .icon {
    width: 18px;
    height: 18px;
    display: block;
    object-fit: cover;
    margin: 0 auto;
  }
}

.arrow_down {
  width: 10px;
  height: 7px;
  display: block;
  object-fit: cover;
}

.btn {
  width: 236px;
  text-align: center;
  height: 60px;
  line-height: 60px;
  background: #bba17b;
  border-radius: 5px;
  margin: 15px auto 0;
}

.textarea::placeholder {
  color: #666 !important;
}

input::placeholder {
  color: #666 !important;
}

.tab2 {
  .tablist {
    width: 390px;
    height: 76px;
    border-radius: 7px;
    overflow: hidden;
    margin-right: 20px;
    font-size: 15px;
    color: #101010;
    line-height: 15px;
    text-align: center;
  }

  .tablist:nth-of-type(2n) {
    margin-right: 0;
  }

  .active {
    line-height: 74px;

    background: #ffffff;
    border: 1px solid #bba17b;
  }

  .unactive {
    line-height: 76px;

    background: #f5f5f5;
  }
}
</style>