<template>
  <div class="container pr pt68">
    <Header @input="input"></Header>
    <div class="main">
      <div class="fs28 col10 fwb lh86 mb20">确认您活动报名信息</div>
      <div class="flex flex-erap">
        <div class="flex-box input_parrt mb40 pl25">
          <div class="fs14 col6">姓名</div>
          <input class="pr30 tr flex-grow-1 col10" v-model="name" type="text" />
        </div>
        <div class="flex-box input_parrt mb40 pl25">
          <div class="fs14 col6">联系电话</div>
          <input class="pr30 tr flex-grow-1 col10" v-model="mobile" type="number" />
        </div>
      </div>
      <div class="fs28 col10 fwb lh86 mb15">您报名的活动</div>
      <div class="pb30 flex part">
        <img class="img br10" :src="oss + data.image" alt="" />
        <div class="flex-grow-1">
          <div class="fs24 col10 fwb lh52 m-ellipsis mb10">
            {{ data.name }}
          </div>
          <div class="fs14 col6 lh20 mb40">{{ data.starttime_text }}</div>
          <div class="fs16 col6 lh20">
            {{ data.address }}
          </div>
        </div>
      </div>
      <div v-if="data.price > 0" class="border_t pt20 pl223">
        <div class="flex-box mb10">
          <div class="fs18 col85 lh24">报名费</div>
          <div class="flex-grow-1 fs18 col10 fwb lh44 tr">RMB {{ data.price }}</div>
        </div>
        <!-- <div class="flex-box mb10">
          <div class="fs18 col85 lh24">优惠券</div>
          <div class="flex-grow-1 fs18 col10 fwb lh44 tr">-RMB 1000</div>
        </div> -->
        <div class="border_t pt30 flex-box pb37">
          <div class="fs24 col10 fwb lh44">总计</div>
          <div class="flex-grow-1 tr fs24 col10 fwb lh44">RMB {{ data.price }}</div>
        </div>
      </div>
      <div v-if="data.price > 0" class="flex-box mt40 mb60">
        <div class="fs28 col10 fwb lh86">选择支付方式</div>
        <div class="pay_part ml45 flex-box flex-center cursor">
          <img class="icon" src="../assets/static/icon/pay_icon1.png" alt="" />
          <div class="lh28 fs20 col3 fwb">微信支付</div>
        </div>
        <div class="pay_part ml45 flex-box flex-center cursor">
          <img class="icon" src="../assets/static/icon/pay_icon2.png" alt="" />
          <div class="lh28 fs20 col3 fwb">支付宝</div>
        </div>
      </div>
      <div class="border_t pt40 flex-box flex-end">
        <div v-if="data.price > 0" class="fs24 col10 fwb lh44 mr45">需支付: RMB {{ data.price }}</div>
        <div @click="submit" class="btn_buy2 colf tc fs20 cursor">{{ data.price > 0 ? '立即支付' : '立即报名' }}</div>
      </div>
    </div>
    <Popup :visible="sucPop" :confirmText="false" :cancleText="false" @close="closeSuc">
      <div class="popup">
        <img @click="closeSuc" class="close_icon mb10 cursor" src="../assets/static/icon/close_pop.png" alt="" />
        <div class="fs24 col3 fwb lh34 mb30">订单信息</div>
        <div class="fs14 col3 lh20 mb50">
          <span>订单号: 678767868767678</span><span class="ml145">支付金额: ¥200</span>
        </div>
        <div class="fs24 col3 fwb lh34 mb40">支付活动费用</div>
        <img class="code" src="../assets/static/images/imgs2.png" alt="" />
        <div class="tc fs16 col3 lh22 mb50">打开手机微信扫码支付</div>
        <div @click="closeSuc" class="pop_btn colf tc fs18 cursor">我已支付</div>
      </div>
    </Popup>
    <Footer></Footer>
  </div>
</template>
<script>
import { useHead } from "@unhead/vue";
import Popup from "../components/popup.vue";
import { getCurrentInstance } from "vue";
import axios from "@/axios";
import router from "@/router";
import { ElMessage, ElMessageBox } from "element-plus";
export default {
  name: "shoppingCart",
  setup() {
    useHead({
      title: "购物车",
      meta: [
        { name: "description", content: "This is my page description" },
        { property: "og:title", content: "My Page Title" },
        { property: "og:description", content: "This is my page description" },
        { property: "og:url", content: "https://example.com/my-page" },
        { property: "og:image", content: "https://example.com/my-image.jpg" },
      ],
      link: [{ rel: "canonical", href: "https://example.com/my-page" }],
    });
  },
  components: {
    Popup,
  },
  data() {
    return {
      oss: getCurrentInstance().appContext.config.globalProperties.$oss,

      sucPop: false,
      activity_id: '',
      data: '',
      name: '',
      mobile: ''
    };
  },
  created() {
    this.activity_id = this.$route.params.activity_id
    this.getDetail();
  },
  methods: {
    getDetail() {
      axios.post('activity/activity_detail', {
        activity_id: this.activity_id
      }).then(res => {
        res.data.price = parseFloat(res.data.price)
        this.data = res.data
      })
    },
    submit() {
      if (!this.name) {
        ElMessage.error('请填写报名人姓名')
        return false
      }
      if (!this.mobile) {
        ElMessage.error('请填写报名人电话')
        return false
      }
      axios.post('activity/create_order', {
        method: 'scan',
        pay_type: this.tabIndex,
        activity_id: this.activity_id,
        name: this.name,
        mobile: this.mobile,
        weixin: 0,
      }).then(res => {
        if (res.code == 1) {
          var wxconfig = res.data.wxconfig;
          if (wxconfig == 'ok') {
            ElMessage.success('报名成功')
            setTimeout(res => {
              router.push('/myActivities');
            }, 1500)
          } else {
            ElMessage.error('支付暂不可用');
            return false;
            if (parseInt(this.weixin) == 1) {
              app.pay(wxconfig, r => {
                uni.redirectTo({
                  url: '/pages/MyActivities/MyActivities'
                })
              }, r => {
                uni.showToast({
                  title: '支付失败',
                  icon: 'none'
                })
              })
            } else {
              var tab = this.type == 'acare' ? 1 : 0;
              app.payApp(wxconfig, r => {
                uni.redirectTo({
                  url: '/pages/MyActivities/MyActivities'
                })
              }, r => {
                uni.showToast({
                  title: '支付失败',
                  icon: 'none'
                })
              })
            }
          }
        } else {
          ElMessage.error(res.msg);
        }
      })
    },
    closeSuc() {
      this.sucPop = !this.sucPop;
    },
  },
};
</script>
<style lang="less" scoped>
.container {
  background: #fff;
  width: 100vw;
  overflow-x: hidden;
}

.main {
  width: 960px;
  margin: 30px auto 150px;
}

.add_part {
  width: 380px;
  height: 244px;
  background: #f5f5f5;
  border-radius: 17px;
  padding-top: 67px;

  .icon {
    width: 88px;
    height: 88px;
    display: block;
    object-fit: cover;
    margin: 0 auto 10px;
  }
}

.border_t {
  border-top: 1px solid #e3e3e3;
}

.img {
  width: 213px;
  height: 159px;
  display: block;
  object-fit: cover;
  margin-right: 43px;
}

.mb8 {
  margin-bottom: 8px;
}

.pl223 {
  padding-left: 223px;
}

.pb37 {
  padding-bottom: 37px;
}

.btn_buy2 {
  width: 236px;
  text-align: center;
  height: 60px;
  line-height: 60px;
  background: #bba17b;
  border-radius: 5px;
}

.pay_part {
  width: 286px;
  height: 75px;
  border-radius: 10px;
  border: 1px solid #979797;

  .icon {
    width: 32px;
    height: 32px;
    display: block;
    object-fit: contain;
    margin-right: 7px;
  }
}

.pay_part:hover {
  border: 1px solid #bba17b;
}

.popup {
  width: 1210px;
  background: #ffffff;
  box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.11);
  border-radius: 6px;
  padding: 16px 16px 62px 52px;

  .close_icon {
    width: 24px;
    height: 24px;
    display: block;
    object-fit: contain;
    margin-left: auto;
    margin-right: 0;
  }

  .code {
    width: 212px;
    height: 212px;
    display: block;
    object-fit: cover;
    margin: 0 auto 10px;
  }

  .pop_btn {
    width: 220px;
    text-align: center;
    height: 55px;
    line-height: 55px;
    background: #bba17b;
    border-radius: 5px;
    margin: 0 auto;
  }

  .ml145 {
    margin-left: 145px;
  }
}

.input_parrt {
  width: 464px;
  height: 48px;
  line-height: 48px;
  background: #f1f2f4;
  border-radius: 8px;
  margin-right: 39px;

  input {
    font-size: 14px;
  }
}

.input_parrt:nth-of-type(2n) {
  margin-right: 0;
}
</style>