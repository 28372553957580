<template>
  <div class="container">
    <Header @input="input"></Header>
    <div class="container pr pt68">
      <div class="part1">
        <img class="avatar" src="../assets/static/images/imgs1.png" alt="" />
        <div class="lh22 tc fs16 mb10">
          <span class="colf">您的当前会员等级 </span
          ><span class="colffb">V1会员</span>
        </div>
        <div class="tc colf6 fs12 mb20">您还需消费999，成为V2会员</div>
        <div class="tab-all">
          <div class="tab-four">
            <swiper
              :loop="true"
              :autoplay="false"
              :slides-per-view="3"
              :centered-slides="true"
              :modules="modules"
            >
              <swiper-slide v-for="(item, index) in fourList" :key="index">
                <div
                  class="img cursor"
                  :class="
                    item.title == 'V1会员'
                      ? 'v1_part'
                      : item.title == 'V2会员'
                      ? 'v2_part'
                      : 'v3_part'
                  "
                >
                  <div class="flex mb40">
                    <img class="icon mr10" :src="item.imgurl" />
                    <div class="flex-grow-1 pt5">
                      <div class="title fs20 lh20 fwb mb10 fwb col1">
                        {{ item.title }}
                      </div>
                      <div class="fs12 lh12 col2">消费1999，成为V3会员</div>
                    </div>
                  </div>
                  <div class="pr ptb2 mb10">
                    <div class="circle" style="left:28px"></div>
                    <div class="line_on" style="width:100px"></div>
                    <div class="line"></div>
                  </div>
                  <div class="flex lh12">
                    <div class="fs12 col3 flex-grow-1">V2会员999-1999</div>
                    <div v-if="index < (fourList.length - 1)" class="col1 fs12  ">V2会员</div>
                  </div>
                </div>
              </swiper-slide>
            </swiper>
          </div>
        </div>
      </div>
      <div class="part2">
        <div class="tc fs36 col10 fwb lh36 mb60">会员权益</div>
        <div class="flex flex-center">
          <div class="mr180">
            <img
              class="vip_icon"
              src="../assets/static/icon/vip_icon.png"
              alt=""
            />
            <div class="tc fs15 col10 fwb lh15 mb15">权益名称一</div>
            <div class="tc fs12 col89">
              会员权益说明描述介绍说明描述介绍说明
            </div>
          </div>
          <div class="mr180">
            <img
              class="vip_icon"
              src="../assets/static/icon/vip_icon.png"
              alt=""
            />
            <div class="tc fs15 col10 fwb lh15 mb15">权益名称二</div>
            <div class="tc fs12 col89">
              会员权益说明描述介绍说明描述介绍说明
            </div>
          </div>
          <div class="mr180">
            <img
              class="vip_icon"
              src="../assets/static/icon/vip_icon.png"
              alt=""
            />
            <div class="tc fs15 col10 fwb lh15 mb15">权益名称三</div>
            <div class="tc fs12 col89">
              会员权益说明描述介绍说明描述介绍说明
            </div>
          </div>
        </div>
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>
  <script>
import { useHead } from "@unhead/vue";
import { Autoplay, Scrollbar } from "swiper";
import { Swiper, SwiperSlide } from "../../node_modules/swiper/vue/swiper-vue";
import "swiper/swiper.min.css";
import "swiper/swiper-bundle.min.css";
export default {
  name: "index",
  components: {
    Swiper,
    SwiperSlide,
  },
  setup() {
    useHead({
      title: "会员介绍",
      meta: [
        { name: "description", content: "This is my page description" },
        { property: "og:title", content: "My Page Title" },
        { property: "og:description", content: "This is my page description" },
        { property: "og:url", content: "https://example.com/my-page" },
        { property: "og:image", content: "https://example.com/my-image.jpg" },
      ],
      link: [{ rel: "canonical", href: "https://example.com/my-page" }],
    });
  },
  data() {
    return {
      modules: [Autoplay, Scrollbar],
      midActive: 0,
      fourList: [
        {
          imgurl: require("../assets/static/icon/v1.png"),
          title: "V1会员",
        },
        {
          imgurl: require("../assets/static/icon/v2.png"),
          title: "V2会员",
        },
        {
          imgurl: require("../assets/static/icon/v3.png"),
          title: "V3会员",
        },
      ],
    };
  },
  created() {},
  methods: {
    onSwiper(e) {
      // console.log(e);
    },
    onSlideChange(e) {
      console.log(e.realIndex);
    },
    changeItem(e) {
      this.scrollIndex = e;
    },

    slideChange() {
      console.log(this.$refs.mySwiperMid.swiper.realIndex);
      this.midActive = this.$refs.mySwiperMid.swiper.realIndex;
    },
    input(e) {
      console.log(e);
    },
    a() {
      console.log("a");
    },
    chooseMid(index) {
      this.nindex = index;
    },
  },
};
</script>
  <style lang="less" scoped>
.container {
  background: #fff;
  width: 100vw;
  overflow-x: hidden;
}
.part1 {
  width: 100%;
  background: #000000;
  padding: 50px 0px 55px 0px;
  .avatar {
    width: 86px;
    height: 86px;
    display: block;
    object-fit: cover;
    border-radius: 50%;
    overflow: hidden;
    margin: 0 auto 20px;
  }
  .colffb {
    color: #ffb03e;
  }
  .colf6 {
    color: #f6f6f6;
  }
}
.part2 {
  background: #fff;
  padding: 80px 200px 150px 200px;
  .vip_icon {
    width: 68px;
    height: 68px;
    display: block;
    object-fit: cover;
    margin: 0 auto 20px;
  }
  .mr180 {
    margin-right: 180px;
  }
  .mr180:last-of-type {
    margin-right: 0;
  }
}
.tab-all {
  width: 988px;
  margin: 0 auto;
  .swiper {
    padding-top: 35px;
    width: 100%;
    height: 238px;
  }
  .img {
    width: 335px;
    height: 170px;
    display: block;
    border-radius: 8px;
    transition: all 0.5s;
    -webkit-transition: all 0.5s;
    padding: 23px 18px 18px 16px;
  }
  /deep/ .swiper-slide {
    position: relative;
    border-radius: 8px;
    overflow: hidden;
    height: 170px;
  }
  /deep/ .swiper-slide-active {
    position: relative;
    overflow: visible;
    z-index: 11;
    height: 238px;
    .img {
      transform: scale(1.4);
      -webkit-transform: scale(1.4);
      transform-origin: center !important;
      -webkit-transform-origin: center !important;
      position: absolute;
      left: 0;
    }
    z-index: 111;
  }
  .swiper-slide-active::after {
    display: none;
  }
  .tab-four {
    width: 100%;
    .swiper-slide-active {
      .img {
        border: none;
        border-radius: 11px;
      }
    }
  }
  .icon {
    width: 55px;
    height: 55px;
    display: block;
    object-fit: contain;
  }
  .ptb2 {
    padding-top: 2px;
    padding-bottom: 2px;
  }
  .line {
    width: 300px;
    height: 3px;
    background: rgba(255, 255, 255, 0.4);
    border-radius: 5px;
  }
  .line_on {
    height: 3px;
    border-radius: 5px;
    position: absolute;
    z-index: 2;
    left: 0;
    top: 2;
  }
  .circle {
    width: 7px;
    height: 7px;
    border-radius: 50%;
    overflow: hidden;
    position: absolute;
    z-index: 3;
    top: 50%;
    transform: translateY(-50%);
  }
}
.v1_part {
  background: linear-gradient(180deg, #ebf1ff 0%, #dae6fd 100%);
  .col1 {
    color: #002366;
  }
  .col2 {
    color: rgba(0, 35, 102, 0.5);
  }
  .col3 {
    color: #7187b1;
  }
  .line_on {
    background: linear-gradient(270deg, #cedcff 0%, #83abf6 100%);
  }
  .circle {
    background: #83abf6;
  }
}
.v2_part {
  background: linear-gradient(180deg, #fff7e8 0%, #ead3ae 100%);
  .col1 {
    color: #784a00;
  }
  .col2 {
    color: rgba(120, 74, 0, 0.5);
  }
  .col3 {
    color: #b69968;
  }
  .line_on {
    background: linear-gradient(90deg, #d5af70 0%, #fddfa5 100%);
  }
  .circle {
    background: #d5af70;
  }
}
.v3_part {
  background: linear-gradient(180deg, #e7ebff 0%, #dbdbfa 100%);
  .col1 {
    color: #252489;
  }
  .col2 {
    color: rgba(64, 63, 177, 0.5);
  }
  .col3 {
    color: rgba(144, 145, 215, 1);
  }
  .line_on {
    background: linear-gradient(90deg, #9f9ef8 0%, #c3cfff 100%);
  }
  .circle {
    background: #9f9ef8;
  }
}
</style>
  
  
  