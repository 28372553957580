<template>
  <div class="container">
    <Header @input="input"></Header>
    <div class="main pr pt68 flex">
      <Mine></Mine>
      <div class="right_part flex-grow-1 ml20">
        <div class="flex-box mt10 mb45">
          <div class="fs24 col0 fwb lh34 mr20">A CARE</div>
          <div @click="toRule(4)" class=" flex-grow-1 colb fs16 cursor">进一步了解</div>
          <img @click="toBind" class="add_device cursor" src="../assets/static/icon/add_device.png" alt="" />
          <div @click="toBind" class="fs20 colb lh30 cursor">添加设备</div>
        </div>
        <div v-for="(item, index) in list" :key="index" class=" flex mb40 product_part">
          <img class="imgs1 mr10 cursor" :src="item.goods.image" alt="" />
          <div class="flex-grow-1">
            <div class="m-ellipsis fs20 col10 fwb lh44 mb48">
              {{ item.goods.goods_name }}
            </div>

            <div v-if="item.status == 1" class="flex-box">
              <div @click.stop="toRepair()" class="label mr10 fs14 tc colb cursor">申请维保</div>
              <div v-if="item.type == 1" @click="refund(item.id)" class="label mr10 fs14 tc colb cursor">申请退款</div>
              <div @click.stop="toChange()" class="label mr10 fs14 tc colb cursor">以旧换新</div>
              <div @click.stop="toService(item.id)" class="label mr10 fs14 tc colb cursor">查看服务</div>
            </div>
            <div v-if="item.status == 2" class="flex-box">
              <div class="label mr10 fs14 tc colb cursor">已退款</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>
<script>
import { useHead } from "@unhead/vue";
import { getCurrentInstance } from "vue";
import axios from "@/axios";
import router from "@/router";
import { ElMessage, ElMessageBox } from "element-plus";
export default {
  name: "index",
  setup() {
    useHead({
      title: "订单详情",
      meta: [
        { name: "description", content: "This is my page description" },
        { property: "og:title", content: "My Page Title" },
        { property: "og:description", content: "This is my page description" },
        { property: "og:url", content: "https://example.com/my-page" },
        { property: "og:image", content: "https://example.com/my-image.jpg" },
      ],
      link: [{ rel: "canonical", href: "https://example.com/my-page" }],
    });
  },
  data() {
    return {
      list: []
    };
  },
  created() {
    this.getList()
  },
  methods: {
    toService(id) {
      router.push({
        name: 'servicesList',
        params: {
          acare_log_id: id
        }
      })
    },
    toRule(id) {
      router.push({
        name: 'rule',
        params: {
          id: id
        }
      })
    },
    toBind() {
      router.push({
        name: 'bindDevice'
      })
    },
    getList() {
      axios.post('acare/my_acare_list', {}).then(res => {
        for (var i in res.data) {
          res.data[i].price = parseFloat(res.data[i].price)
        }
        this.list = res.data
      })
    },
    refund(id) {
      ElMessageBox.confirm('退款将退回到账户余额，确认退款？', '退款确认', {
        confirmButtonText: '确认',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        // 确定按钮被点击的回调
        console.log('确定按钮被点击');
        axios.post('xilushop/order/acare_refund', {
          id: id
        }).then(res => {
          if (res.code == 1) {
            ElMessageBox.alert(res.msg, '退款成功', {
              confirmButtonText: '确定',
            });
            this.getList()
          } else {
            ElMessageBox.alert(res.msg, '退款失败', {
              confirmButtonText: '确定',
            });
          }
        })
      }).catch(() => {
        // 取消按钮被点击的回调
        console.log('取消按钮被点击');
      });
    },
    toRepair() {
      router.push({ name: 'afterSales' });
    },
    toChange() {
      router.push({ name: 'tradeInTheOld', params: { goods_id: 0 } });
    }
  },
};
</script>

<style lang="less" scoped>
.container {
  background: #f6f6f6;
  width: 100vw;
}

.main {
  width: 1200px;
  margin: 20px auto;
}

.mw200 {
  max-width: 200px;
}

.border_b {
  border-bottom: 1px solid #eeeff0;
}

.right_part {
  background: #fdfdfd;
  border-radius: 13px;
  overflow: hidden;
  padding: 25px 20px;

  .add_device {
    width: 16px;
    height: 16px;
    display: block;
    object-fit: contain;
  }

  .imgs1 {
    width: 122px;
    height: 122px;
    display: block;
    object-fit: cover;
  }

  .label {
    padding: 0 9px;
    height: 23px;
    line-height: 23px;
    background: #f0f0f7;
    border-radius: 12px;
  }

  .product_part:last-of-type {
    margin-bottom: 45px;
  }
}
</style>