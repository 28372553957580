<template>
  <div class="container">
    <div class="container pr pt68 plr120">
      <div v-html="content"></div>
    </div>
  </div>
</template>
<script>
import { useHead } from "@unhead/vue";
import router from "@/router";
import axios from "@/axios";
import { getCurrentInstance } from "vue";

export default {
  name: "rule",
  setup() {
    useHead({
      title: "内容",
      meta: [
        { name: "description", content: "This is my page description" },
        { property: "og:title", content: "My Page Title" },
        { property: "og:description", content: "This is my page description" },
        { property: "og:url", content: "https://example.com/my-page" },
        { property: "og:image", content: "https://example.com/my-image.jpg" },
      ],
      link: [{ rel: "canonical", href: "https://example.com/my-page" }],
    });
  },
  data() {
    return {
      oss: getCurrentInstance().appContext.config.globalProperties.$oss,
      id: "",
      name: "",
      content: "",
    };
  },
  created() {
    this.id = this.$route.params.id;
    this.getDetail();
  },
  methods: {
    getDetail() {
      axios
        .post("xilushop/Singlepage/get_article_by_id", {
          singlepage_id: this.id,
        })
        .then((res) => {
          this.name = res.data.name;
          this.content = res.data.content;
        });
    },
  },
};
</script>
<style lang="less" scoped>
.container {
  background: #fff;
  width: 100vw;
  overflow-x: hidden;

  .imgs1 {
    widows: 100%;
    display: block;
    height: auto;
  }
}
.plr120 {
  padding-left: 120px;
  padding-right: 120px;
}
</style>