<!--
  时间：2024年05月15日 14:13:00
-->
<template>
    <div class="part1">
        <div class="avatar_part cursor">
            <img :src="userInfo.avatar" alt="" />
        </div>
        <div class="flex-box flex-center mb40">
            <div class="fs16 col10 mr5 m-ellipsis mw200">{{ userInfo.nickname }}</div>
            <img class="v1" src="../assets/static/icon/v1.png" alt="" />
        </div>
        <div class="flex mb10">
            <div @click="jump('/myIncome')" class="part2 mr10 cursor">
                <div class="fs14 col85 lh14 mb15">余额</div>
                <div class="flex-box">
                    <div class="flex-grow-1 col10">
                        <span class="fs12">¥</span>
                        <span class="fs22 fwb">{{ userInfo.account ? userInfo.account : 0 }}</span>
                    </div>
                    <!-- <div class="btn colf tc fs12 cursor">提现</div> -->
                </div>
            </div>
            <div @click="jump('/AGCAREList')" class="part2 cursor">
                <div class="fs14 col85 lh14 mb15">A CARE</div>
                <div class="flex-grow-1 col10 fs22 fwb">{{ userInfo.acare_num }}</div>
            </div>
        </div>
        <div class="part3 flex flex-between mb15">
            <div @click="jump('/myPage/0')" class="tc cursor">
                <div class="fs22 col10 fwb lh22 mb15">{{ userInfo.order_num }}</div>
                <div class="fs14 col85 lh14">订单</div>
            </div>
            <div @click="jump('/myCoupons/0/0/0')" class="tc cursor">
                <div class="fs22 col10 fwb lh22 mb15">{{ userInfo.user_coupon_count }}</div>
                <div class="fs14 col85 lh14">优惠券</div>
            </div>
            <div @click="jump('/myDeviceList')" class="tc cursor">
                <div class="fs22 col10 fwb lh22 mb15">{{ userInfo.device_num }}</div>
                <div class="fs14 col85 lh14">设备</div>
            </div>
            <div @click="jump('/myAfterSales')" class="tc cursor">
                <div class="fs22 col10 fwb lh22 mb15">{{ userInfo.device_repair_count }}</div>
                <div class="fs14 col85 lh14">售后</div>
            </div>
        </div>
        <!--        <div @click="jump('')" class="ptb30 border_b fs18 col10 cursor orderplans">我的维保</div>-->
        <div @click="jump('/myActivities')" class="ptb30 border_b fs18 col10 cursor orderplans">我的活动</div>
        <div @click="jump('/myAddress/0/0/0/0')" class="ptb30 border_b fs18 col10 cursor orderplans">收货地址</div>
        <div @click="jump('/tradeInTheOld/0')" class="ptb30 border_b fs18 col10 cursor orderplans">以旧换新</div>
        <div @click="jump('/myDeviceList')" class="ptb30 border_b fs18 col10 cursor orderplans">设备易主</div>
        <div @click="logout" class="ptb30 border_b fs18 col10 cursor orderplans">退出登录</div>
    </div>
</template>

<script>
import axios from "@/axios";
import router from "@/router";
import { ElMessage } from "element-plus";

export default {
    name: "mine",
    props: ["value"],
    // 局部注册的组件
    components: {},
    // 组件状态值
    data() {
        return {
            userInfo: ''
        };
    },
    // 侦听器
    watch: {},
    created() {
        this.getUser();
    },
    mounted() {
    },
    // 组件方法
    methods: {
        jump(url) {
            router.push(url);
        },
        getUser() {
            axios.post('xilushop/user/info', {})
                .then(res => {
                    this.userInfo = res.data
                })
                .catch(error => {
                    console.error(error);
                });
        },
        logout() {
            localStorage.setItem('token', '')
            ElMessage.success('退出成功');
            router.push('/')
        }
    },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<!--使用了scoped属性之后，父组件的style样式将不会渗透到子组件中，-->
<!--然而子组件的根节点元素会同时被设置了scoped的父css样式和设置了scoped的子css样式影响，-->
<!--这么设计的目的是父组件可以对子组件根元素进行布局。-->
<style lang="less" scoped>
.part1 {
    width: 385px;
    padding: 67px 20px 67px 20px;
    background: #fdfdfd;
    border-radius: 13px;

    .avatar_part {
        width: 118px;
        height: 118px;
        border-radius: 50%;
        border: 1px solid #eeeeee;
        padding: 6px 0;
        margin: 5px auto;

        img {
            width: 107px;
            height: 107px;
            display: block;
            object-fit: cover;
            margin: 0 auto;
            border-radius: 50%;
            overflow: hidden;
        }
    }

    .v1 {
        width: 20px;
        height: 20px;
        display: block;
        object-fit: contain;
    }

    .part2 {
        width: 168px;
        background: #f1f2f4;
        border-radius: 8px;
        padding: 19px 13px 18px 20px;

        .btn {
            width: 45px;
            text-align: center;
            height: 23px;
            line-height: 23px;
            background: #bba17b;
            border-radius: 12px;
        }
    }

    .part3 {
        width: 345px;
        padding: 20px 25px;
        background: #f1f2f4;
        border-radius: 8px;
    }
}
</style>