<template>
  <div class="container pr pt68">
    <Header @input="input"></Header>
    <div class="main">
      <div class="flex">
        <div class="flex-grow-1">
          <div class="fs32 col10 fwb lh86">{{ goodsDetail.name }}{{ goodsDetail.name2 }}</div>
          <div class="col0 flex-box mb30">
            <div class="fs14">RMB </div>
            <div class="fs22 fwb mlr2">{{ goodsDetail.price }}</div>
            <!-- <div class="fs16">起</div> -->
          </div>
        </div>
        <!-- <div class="btn1 fs18 tc cursor">添加购物车</div> -->
        <div @click="toOrder" class="btn2 fs18 tc cursor">结账</div>
      </div>
      <div class="part mb60">
        <div class="fs14 col10 fwb mb10">A CARE服务计划</div>
        <div class="fs14 col10 mb5">RMB {{ goodsDetail.price }}</div>
        <div class="border_t pt5">
          <div class="fs14 col6 lh20 mt10" v-html="goodsDetail.content"></div>
        </div>
      </div>
    </div>
    <div class="main2">
      <div class="part flex-box">
        <div class="flex-grow-1">
          <div class="fs24 col10 fwb lh44 mb20">{{ goodsDetail.name }}{{ goodsDetail.name2 }}</div>
          <div class="fs20 col10 lh28">
            <span class="mr15">RMB </span>
            <span>{{ goodsDetail.price }}</span>
          </div>
        </div>
        <!-- <div class="btn1 fs18 tc cursor">添加购物车</div> -->
        <div @click="toOrder" class="btn2 fs18 tc cursor">结账</div>
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>
<script>
import { useHead } from "@unhead/vue";
import axios from "@/axios";
import router from "@/router";
import { getCurrentInstance } from "vue";
import { ElMessage, ElMessageBox } from "element-plus";
export default {
  name: "A CARE",
  setup() {
    useHead({
      title: "A CARE",
      meta: [
        { name: "description", content: "This is my page description" },
        { property: "og:title", content: "My Page Title" },
        { property: "og:description", content: "This is my page description" },
        { property: "og:url", content: "https://example.com/my-page" },
        { property: "og:image", content: "https://example.com/my-image.jpg" },
      ],
      link: [{ rel: "canonical", href: "https://example.com/my-page" }],
    });
  },

  data() {
    return {
      oss: getCurrentInstance().appContext.config.globalProperties.$oss,
      w1000: getCurrentInstance().appContext.config.globalProperties.$w1000,
      w500: getCurrentInstance().appContext.config.globalProperties.$w500,
      goodsDetail: '',
      goods_id: ''
    };
  },
  created() {
    this.goods_id = this.$route.params.goods_id
    this.getGoodsDetail()
  },
  methods: {
    toOrder() {
      router.push({
        name: 'myDevice',
        params: {
          type: 'acare',
          ids: this.goods_id,
          goods_num: 1
        }
      })
    },
    getGoodsDetail() {
      axios.post('acare/acare_goods_detail', {
        goods_id: this.goods_id,
      }).then(res => {
        this.goodsDetail = res.data
      })
    }
  },
};
</script>
<style lang="less" scoped>
.container {
  background: #ffffff;
  width: 100vw;
  overflow-x: hidden;
}

.main {
  width: 1200px;
  margin: 33px auto;

  .btn1,
  .btn2 {
    width: 160px;
    height: 48px;
    border-radius: 24px;
    border: 1px solid #4971c7;
    line-height: 46px;
    color: #4088f6;
    font-size: 17px;
    margin-left: 33px;
  }

  .btn2 {
    background: #bba17b;
    line-height: 48px;
    color: #fff;
    border: none;
  }

  .mlr2 {
    margin-left: 2px;
    margin-right: 2px;
  }

  .part {
    border-radius: 7px;
    border: 1px solid #e6e6e6;
    padding: 20px 15px;
  }
}

.border_t {
  border-top: 1px solid #e6e6e6;
}

.main2 {
  background: #f5f5f5;
  padding: 40px;

  .part {
    width: 1200px;
    margin: 0 auto;
  }
}
</style>