<template>
  <div class="container">
    <Header @input="input"></Header>
    <div class="container pr pt68">
      <!-- <img class="customer" src="../assets/static/icon/customer.png" alt="" /> -->
      <div class="swiper_link cursor part_h">
        <swiper :pagination="false" :autoplay="{ delay: 2500, disableOnInteraction: false }" @swiper="onSwiper"
          @slideChange="onSlideChange" class="swiper_sw" :modules="modules" loop>
          <swiper-slide @click="bannerJump(index)" class="swiper_item" v-for="(item, index) in bannerList" :key="index">
            <div class="top_banner cu flex-box flex-center flex-col"
              :style="{ backgroundImage: `url(${oss}${item.thumb_image})` }"></div>
          </swiper-slide>
        </swiper>
      </div>
      <img @click="toGoodsDetail(item.id)" v-for="(item, index) in recList" :key="index" class="index_imgs1 mb5 cursor part_h"
        :src="oss + item.pc1_image" alt="" />
      <div class="flex flex-wrap flex-center plr20">
        <div @click="toGoodsDetail(item.id)" v-for="(item, index) in newList" :key="index"
          class="cursor part1 mr20 mt20">
          <img class="imgs1 part_h" :src="oss + item.pc2_image" alt="" />
        </div>
      </div>
      <img @click="toAcare" class="index_imgs1 mb20 cursor part_h" src="../assets/static/images/serve_imgs1.png" alt="" />
      <div class="flex-box flex-center plr20 mb10">
        <div @click="toBind" class="part2 cursor tc mr20 part_h">
          <div class="fs24 col0 fwb">添加设备</div>
          <div class="fs20 lh40 col62 mb17">暂无仪器，点击去绑定</div>
          <div class="colb lh28 fs18">立即绑定</div>
        </div>
        <div @click="toChange" class="part2 cursor tc mr20 part_h">
          <div class="fs24 col0 fwb">以旧换新</div>
          <div class="fs20 lh40 col62 mb17">选择你换新的产品，填写问卷</div>
          <div class="colb lh28 fs18">立即换新</div>
        </div>
      </div>
      <div class="tc fs40 col0 fwb lh86">推荐门店</div>
      <div @click="toShop" class="tc colb fs20 mb30 cursor">
        查看更多门店
      </div>
      <div class="tab-all">
        <div class="tab-four mb70 ml30 mr20">
          <swiper :loop="false" :autoplay="false" :slides-per-view="3" :slides-per-group="1" :space-between="20"
            :centered-slides="false" :modules="modules">
            <swiper-slide v-for="(item, index) in shopList" :key="index" class="part3  cursor ">
              <img class="store_imgs mb30 part_h" :src="oss + item.image" alt="" />
              <div class="m-ellipsis fs26 col0 fwb lh34 mb15">
                {{ item.shop_name }}
              </div>
              <div class="pr85 m-ellipsis-l2 lh28 fs16 col85 mb30">
                {{ item.address }}
              </div>
              <!-- <div class="fs18 col3 lh24">上午09:30 - 下午 18:30</div> -->
            </swiper-slide>
          </swiper>
        </div>
      </div>
      <!-- <div
        v-if="more_shop_btn"
        @click="getShopList"
        class="tc colb fs22 mb30 cursor"
      >
        查看更多门店
      </div> -->

    </div>
    <Footer></Footer>
  </div>
</template>
<script>
import { useHead } from "@unhead/vue";
import {
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
  EffectFade,
  Parallax,
} from "swiper";
import { Swiper, SwiperSlide } from "../../node_modules/swiper/vue/swiper-vue";
import "swiper/swiper.min.css";
import "swiper/swiper-bundle.min.css";
// import axios from 'axios';
import axios from "@/axios";
import { getCurrentInstance } from "vue";
import router from "@/router";
import { ElMessage } from "element-plus";
var shop_page;
export default {
  name: "index",
  components: {
    Swiper,
    SwiperSlide,
  },
  setup() {
    useHead({
      title: "首页",
      meta: [
        {
          name: "description",
          content: "This is my page description",
        },
        {
          property: "og:title",
          content: "My Page Title",
        },
        {
          property: "og:description",
          content: "This is my page description",
        },
        {
          property: "og:url",
          content: "https://example.com/my-page",
        },
        {
          property: "og:image",
          content: "https://example.com/my-image.jpg",
        },
      ],
      link: [
        {
          rel: "canonical",
          href: "https://example.com/my-page",
        },
      ],
    });
  },
  data() {
    return {
      oss: getCurrentInstance().appContext.config.globalProperties.$oss,
      modules: [Navigation, Pagination, Scrollbar, A11y, EffectFade, Parallax],
      midActive: 0,
      swiperList1: [
        {
          banner: require("../assets/static/images/index_banner.png"),
        },
        {
          banner: require("../assets/static/images/serve_imgs1.png"),
        },
        {
          banner: require("../assets/static/images/serve_imgs1.png"),
        },
      ],
      bannerList: [],
      recList: [],
      newList: [],
      shopList: [],
      more_shop_btn: true,
    };
  },
  
  created() {
    shop_page = 1;
    this.nindex = 0;
    this.getBannerList();
    this.getRecList();
    this.getNewList();
    this.getShopList();
  },
  methods: {
    bannerJump(index) {
      var minapp_url = this.bannerList[index].minapp_url
      if (minapp_url) {
        router.push(minapp_url);
      }
    },
    toAcare() {
      router.push({ name: "AGCARE" });
    },
    toShop() {
      router.push({
        name: "storelist", params: {
          home: 1,
          shop_country_id: 0,
          shop_city_id: 0,
          city_name: '选择城市',
        }
      });
    },
    getShopList() {
      axios
        .post("index/shop_list_pc", {
          page: shop_page,
        })
        .then((res) => {
          var data = res.data.data;
          if (data) {
            if (shop_page == 1) {
              this.shopList = data;
            } else {
              this.shopList = this.shopList.concat(data);
            }
            shop_page++;
          } else {
            this.more_shop_btn = false;
          }
        });
    },
    toBind() {
      router.push({ name: "bindDevice" });
    },
    toChange() {
      router.push({ name: "tradeInTheOld", params: { goods_id: 0 } });
    },
    toGoodsDetail(id) {
      router.push({ name: "productDetail", params: { goods_id: id } });
    },
    getBannerList() {
      axios
        .post("xilushop/banner/banners", { type: 2 })
        .then((res) => {
          this.bannerList = res.data;
        })
        .catch((error) => {
          console.error(error);
        });
    },
    getRecList() {
      axios
        .post("xilushop/goods/goods_lists", {
          rec: 1,
        })
        .then((res) => {
          this.recList = res.data.data;
        })
        .catch((error) => {
          console.error(error);
        });
    },
    getNewList() {
      axios
        .post("xilushop/goods/goods_lists", {
          new: 1,
        })
        .then((res) => {
          this.newList = res.data.data;
        })
        .catch((error) => {
          console.error(error);
        });
    },
    onSwiper(e) {
      // console.log(e);
    },
    onSlideChange(e) {
      console.log(e.realIndex);
    },
    changeItem(e) {
      this.scrollIndex = e;
    },

    slideChange() {
      console.log(this.$refs.mySwiperMid.swiper.realIndex);
      this.midActive = this.$refs.mySwiperMid.swiper.realIndex;
    },
    input(e) {
      console.log(e);
    },
    a() {
      console.log("a");
    },
    chooseMid(index) {
      this.nindex = index;
    },
  },
};
</script>
<style lang="less" scoped>
.container {
  background: #fff;
  width: 100vw;
  overflow-x: hidden;

  .customer {
    position: fixed;
    right: 0;
    top: 50%;
    z-index: 2;
    width: 108px;
    height: 60px;
  }
}

.swiper_link {
  width: 100vw;
  height: 557px;
  position: relative;

  .swiper_sw {
    width: 100%;
    height: 100%;
    position: relative;
  }

  .swiper_sw_item {
    width: 100%;
    height: 100%;
  }

  .top_banner {
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-position: center;
  }
}

.index_imgs1 {
  width: 100%;
  height: auto;
  display: block;
  object-fit: cover;
}

.part1 {
  // width: calc((100vw - 60px) / 2);
  width: 690px;
  height: 617px;

  .imgs1 {
    width: 100%;
    height: 617px;
    display: block;
    object-fit: contain;
  }
}

.part2 {
  width: calc((100vw - 60px) / 2);
  padding: 70px 0;
  background: #f5f5f5;
}

.part1:nth-of-type(2n),
.part2:nth-of-type(2n) {
  margin-right: 0;
}

.scroll_part {
  width: calc(100vw - 50px);
  white-space: nowrap;
  overflow-x: scroll;
}

.part3 {
  width: 450px;
  display: inline-block;

  .store_imgs {
    width: 450px;
    height: 312px;
    object-fit: cover;
  }
}

.part3:last-of-type {
  margin-right: 0;
}

.tab-all {
  width: calc(100vw - 50px);

  .swiper {
    width: 100%;
  }

  .part3 {
    width: 450px;
    display: inline-block;
    transition: all 0.5s;
    -webkit-transition: all 0.5s;

    .store_imgs {
      width: 450px;
      height: 312px;
      object-fit: cover;
    }
  }

  .part3:last-of-type {
    margin-right: 0;
  }

  /deep/ .swiper-slide {
    position: relative;

    overflow: hidden;
    width: 450px;
  }

  /deep/ .swiper-slide-active {
    position: relative;
    overflow: visible;
    z-index: 11;
    width: 450px;

    .img {
      transform-origin: left !important;
      -webkit-transform-origin: left !important;
      position: absolute;
      left: 30px;
    }

    z-index: 111;
  }

  .swiper-slide-active::after {
    display: none;
  }

  .tab-four {
    width: 100%;

    .swiper-slide-active {
      .img {
        border: none;
      }
    }
  }

  .icon {
    width: 55px;
    height: 55px;
    display: block;
    object-fit: contain;
  }
}
</style>
<style scoped>
/* 第一个轮播 */
.swiper_sw .swiper-pagination {
  height: 0;
}

.swiper_sw .swiper-pagination .swiper-pagination-bullet {
  width: 0;
  height: 0;
  opacity: 0;
}

.swiper_sw .swiper-pagination {
  height: 0;
}

.swiper_sw .swiper-pagination .swiper-pagination-bullet-active {
  width: 0;
  height: 0;
}

/* .swiper-button-prev, .swiper-button-next {
position: absolute;
top: 57%;
width: 27px;
height: 25px;
margin-top: -22px;
z-index: 10;
cursor: pointer;
background-size: 27px 44px;
background-position: center;
background-repeat: no-repeat;
} */
</style>