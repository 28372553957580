<template>
  <div class="container pr">
    <Header @input="input"></Header>
    <div class="flex-box plr120">
      <div class="flex-grow-1 fs28 col10 fwb lh86">以旧换新</div>
      <div @click="toRule" class="colb fs20 lh30 cursor">进一步了解？</div>
    </div>
    <div class="main">
      <div
        v-for="(item, index) in list"
        :key="index"
        class="ptb30 part1 flex cursor"
      >
        <img class="img" :src="oss + item.thumb_image + w500" alt="" />
        <div class="flex-grow-1">
          <div class="fs24 col10 fwb lh44 m-ellipsis mb10">
            {{ item.name }}
          </div>
          <div class="fs24 col10 fwb lh44 mb43">{{ item.sub_name }}</div>
          <div class="flex-box mb20">
            <div class="flex-grow-1 fs20 col85 lh28">
              序列号：{{ item.goods_no }}
            </div>
            <div class="flex-grow-1 fs20 col85 lh28">
              绑定日期：{{ item.date }}
            </div>
          </div>
          <div class="flex-box flex-end">
            <div v-if="item.bind_status != 1" class="ml10 btn fs16 tc">
              <block v-if="item.bind_status == 0">绑定待审核</block>
              <block v-else-if="item.bind_status == 1">绑定成功</block>
              <block v-else-if="item.bind_status == 2">绑定失败</block>
            </div>
            <div
              @click="change(index)"
              v-if="item.bind_status == 1 && item.change_status != 1"
              class="ml10 btn fs16 colb tc"
            >
              <block v-if="item.change_status == 0">以旧换新</block>
              <block v-else-if="item.change_status == 2"
                >已申请寄回运输中</block
              >
              <block v-else-if="item.change_status == 3">已完成</block>
              <block v-else-if="item.change_status == 4">已关闭</block>
            </div>
            <div
              @click="change(index)"
              v-if="item.bind_status == 1 && item.change_status == 1"
              class="ml10 btn fs16 colb tc cursor"
            >
              待寄回
            </div>
            <div
              v-if="item.bind_status == 1 && item.change_status == 1"
              @click.stop="toShop(index)"
              class="ml10 btn fs16 colb tc cursor"
            >
              选择新套餐
            </div>
          </div>
        </div>
      </div>
    </div>
    <Popup
      :visible="sucPop"
      :confirmText="false"
      :cancleText="false"
      @close="closeSuc"
    >
      <div class="popup">
        <div class="fs28 col10 mb30">{{ question_list[q_index].question }}</div>
        <div class="flex flex-wrap mb40">
          <div
            class="pop_part1 mr30 mb20 fs18 col10 cursor"
            v-for="(item, index) in question_list[q_index].option"
            :key="index"
            @click="testTabClick(index)"
            :class="a_index == index ? 'active' : 'unactive'"
          >
            {{ item }}
          </div>
        </div>
        <!-- <div class="fs28 col10 mb30">您是否同意以旧换新？</div>
                <div class="flex flex-wrap mb40">
                    <div class="pop_part1 mr30 mb20 fs18 col10 cursor" v-for="(item, index) in tablist2" :key="index"
                        @click="testTabClick2(index)" :class="tabIndex2 == index ? 'active' : 'unactive'">
                        {{ item }}
                    </div>
                </div> -->
        <div @click="next" class="pop_btn colf tc fs20 cursor">下一步</div>
      </div>
    </Popup>
    <Popup
      :visible="sucPop2"
      :confirmText="false"
      :cancleText="false"
      @close="closeSuc2"
    >
      <div class="popup2">
        <img
          class="sucess_icon"
          src="../assets/static/icon/sucess_icon.png"
          alt=""
        />
        <div class="tc fs20 col10 mb10 tc fwb lh20">问卷已完成</div>
        <div class="tc fs16 col89 mb60">
          以旧换新的问卷已完成，请选购您的商品
        </div>
        <div class="flex flex-between">
          <div @click="closeSuc2" class="btn1 cursor">我再想想</div>
          <div @click="toDetail" class="btn2 cursor">选购商品</div>
        </div>
      </div>
    </Popup>
    <Popup
      :visible="sucPop3"
      :confirmText="false"
      :cancleText="false"
      @close="closeSuc3"
    >
      <div class="popup3">
        <div v-html="content"></div>
        <view @click="closeSuc3" class="tc btn_click cursor"> 下一步 点击换新 </view>
      </div>
    </Popup>
    <Footer></Footer>
  </div>
</template>
<script>
import { useHead } from "@unhead/vue";
import Popup from "../components/popup.vue";
import { getCurrentInstance } from "vue";
import axios from "@/axios";
import router from "@/router";
import { ElMessage, ElMessageBox } from "element-plus";

export default {
  name: "productDetail",
  setup() {
    useHead({
      title: "以旧换新",
      meta: [
        { name: "description", content: "This is my page description" },
        { property: "og:title", content: "My Page Title" },
        { property: "og:description", content: "This is my page description" },
        { property: "og:url", content: "https://example.com/my-page" },
        { property: "og:image", content: "https://example.com/my-image.jpg" },
      ],
      link: [{ rel: "canonical", href: "https://example.com/my-page" }],
    });
  },
  components: {
    Popup,
  },
  data() {
    return {
      oss: getCurrentInstance().appContext.config.globalProperties.$oss,
      w1000: getCurrentInstance().appContext.config.globalProperties.$w1000,
      w500: getCurrentInstance().appContext.config.globalProperties.$w500,
      sucPop: false,
      sucPop2: false,
      tabIndex: 0,
      tablist: ["非常满意", "很满意", "满意", "一般", "差"],
      tabIndex2: 0,
      tablist2: ["同意", "拒绝"],
      device_id: "",
      goods_id: "",
      list: [],
      question_list: [],
      answer: [],
      q_index: 0,
      a_index: 0,
      sucPop3: true,
      id: "",
      name: "",
      content: "",
    };
  },
  created() {
    this.goods_id = this.$route.params.goods_id;
    this.getDeviceList();
    this.getQuestion();
    this.getDetail();
  },
  methods: {
    getDetail() {
      axios
        .post("xilushop/Singlepage/get_article_by_id", {
          singlepage_id: 3,
        })
        .then((res) => {
          this.name = res.data.name;
          this.content = res.data.content;
        });
    },
    closeSuc3() {
      this.sucPop3 = !this.sucPop3;
    },
    toRule(id) {
      // router.push({
      //     name: 'rule',
      //     params: {
      //         id: id
      //     }
      // })
      this.sucPop3 = true;
    },
    toShop(index) {
      if (this.list[index].change_status == 1) {
        if (parseInt(this.list[index].coupon_use_status) == 1) {
          ElMessageBox.alert("该设备优惠券已使用，请更换其他设备", "提示", {
            confirmButtonText: "确定",
          });
          return false;
        } else {
          router.push({
            name: "buy",
            params: {
              goods_id: 22,
              device_id: this.list[index].id,
              coupon_id: 0,
              coupon_price: 0,
              coupon_name: 0,
            },
          });
        }
      }
    },
    change(index) {
      if (this.list[index].bind_status == 1) {
        if (this.list[index].change_status == 0) {
          this.device_id = this.list[index].id;
          this.closeSuc();
        } else if (this.list[index].change_status == 1) {
          if (this.goods_id > 0) {
            if (parseInt(this.list[index].coupon_use_status) == 1) {
              ElMessageBox.alert("该设备优惠券已使用，请更换其他设备", "提示", {
                confirmButtonText: "确定",
              });
              return false;
            }
            // var pages = getCurrentPages();
            // var prevPage = pages[pages.length - 2]; //上一个页面
            // prevPage.$vm.change = 1;
            // prevPage.$vm.device_id = this.list[index].id;
            // uni.navigateBack({
            //     delta: 1
            // })
            router.push({
              name: "buy",
              params: {
                goods_id: this.goods_id,
                device_id: this.list[index].id,
                coupon_id: 0,
                coupon_price: 0,
                coupon_name: 0,
              },
            });
          } else {
            ElMessageBox.confirm("请选择顺丰上门收件地址", "寄回设备", {
              confirmButtonText: "去选地址",
              cancelButtonText: "暂不寄件",
              type: "warning",
            })
              .then(() => {
                // 确定按钮被点击的回调
                console.log("确定按钮被点击");
                router.push({
                  name: "myAddress",
                  params: {
                    order: 0,
                    device_id: this.list[index].id,
                    repair_id: 0,
                    order_goods_id: 0,
                  },
                });
              })
              .catch(() => {
                // 取消按钮被点击的回调
                console.log("取消按钮被点击");
              });
          }
        } else {
          if (this.goods_id > 0) {
            if (parseInt(this.list[index].coupon_use_status) == 1) {
              uni.showModal({
                title: "提示",
                content: "该设备优惠券已使用，请更换其他设备",
                showCancel: false,
              });
              return false;
            }

            // var pages = getCurrentPages();
            // var prevPage = pages[pages.length - 2]; //上一个页面
            // prevPage.$vm.change = 1;
            // prevPage.$vm.device_id = this.list[index].id;
            // uni.navigateBack({
            //     delta: 1
            // })

            router.push({
              name: "buy",
              params: {
                goods_id: this.goods_id,
                device_id: this.list[index].id,
                coupon_id: 0,
                coupon_price: 0,
                coupon_name: 0,
              },
            });
          }
        }
      }
    },
    getDeviceList() {
      axios
        .post("ag/device_list", {
          is_change: 1,
        })
        .then((res) => {
          this.list = res.data;
        })
        .catch((error) => {
          console.error(error);
        });
    },
    getQuestion() {
      axios
        .post("ag/question_list", {})
        .then((res) => {
          if (res.code == 1) {
            this.question_list = res.data;
          }
        })
        .catch((error) => {
          console.error(error);
        });
    },
    next() {
      var item = {
        q: this.question_list[this.q_index].question,
        a: this.question_list[this.q_index].option[this.a_index],
      };
      this.answer.push(item);
      if (this.q_index + 1 < this.question_list.length) {
        this.a_index = 0;
        this.q_index++;
      } else {
        axios
          .post("ag/create_answer", {
            device_id: this.device_id,
            content: this.answer,
          })
          .then((res) => {
            if (res.code == 1) {
              this.closeSuc();
              this.getDeviceList();
              this.closeSuc2();
            } else {
              ElMessage.error(res.msg);
            }
          })
          .catch((error) => {
            console.error(error);
          });
      }
    },
    toDetail() {
      axios
        .post("ag/confirm_change", {
          device_id: this.device_id,
        })
        .then((res) => {
          if (res.code == 1) {
            if (this.goods_id > 0) {
              // var pages = getCurrentPages();
              // var prevPage = pages[pages.length - 3]; //上一个页面
              // prevPage.$vm.change = 1;
              // prevPage.$vm.device_id = this.device_id;
              // uni.navigateBack({
              //     delta: 2
              // })
              router.push({
                name: "buy",
                params: {
                  goods_id: this.goods_id,
                  device_id: this.device_id,
                  coupon_id: 0,
                  coupon_price: 0,
                  coupon_name: 0,
                },
              });
            } else {
              router.push({
                name: "buy",
                params: {
                  goods_id: 22,
                  device_id: this.device_id,
                  coupon_id: 0,
                  coupon_price: 0,
                  coupon_name: 0,
                },
              });
            }
          } else {
            uni.showModal({
              title: "提示",
              content: res.msg,
              showCancel: false,
            });
          }
        })
        .catch((error) => {
          console.error(error);
        });
    },
    testTabClick(index) {
      console.log(index);
      this.a_index = index;
    },
    closeSuc() {
      this.sucPop = !this.sucPop;
    },
    closeSuc2() {
      this.sucPop2 = !this.sucPop2;
    },
  },
};
</script>
<style lang="less" scoped>
.container {
  background: #fff;
  width: 100vw;
  overflow-x: hidden;
  padding-top: 103px;
}

.main {
  width: 770px;
  margin: 0px auto 103px;
}

.add_device {
  width: 16px;
  height: 16px;
  display: block;
  object-fit: cover;
}

.part1 {
  border-bottom: 1px solid #e3e3e3;

  .img {
    width: 173px;
    height: 173px;
    display: block;
    object-fit: cover;
    margin-right: 53px;
  }
}

.btn {
  padding: 0 17px;
  text-align: center;
  height: 34px;
  line-height: 34px;
  background: #f0f0f7;
  border-radius: 23px;
}

.popup {
  width: 1001px;
  padding: 49px 47px 55px 47px;
  background: #ffffff;
  box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.11);
  border-radius: 6px;
}

.pop_part1 {
  width: 282px;
  height: 78px;
  border-radius: 9px;
}

.pop_part1:nth-of-type(3n) {
  margin-right: 0;
}

.active,
.pop_part1:hover {
  background: #ffffff;
  border: 1px solid #bba17b;
  line-height: 76px;
  padding: 0 23px;
}

.unactive {
  background: #f1f2f4;
  line-height: 78px;
  padding: 0 24px;
}

.pop_btn {
  width: 342px;
  text-align: center;
  height: 57px;
  line-height: 57px;
  background: #bba17b;
  border-radius: 5px;
  margin: 5px auto 0;
}

.popup2 {
  width: 446px;
  padding: 58px 30px 40px 30px;
  background: #ffffff;
  box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.11);
  border-radius: 6px;

  .sucess_icon {
    width: 60px;
    height: 60px;
    display: block;
    object-fit: contain;
    margin: 0 auto 25px;
  }

  .btn1,
  .btn2 {
    width: 183px;
    text-align: center;
    height: 57px;
    line-height: 55px;
    border-radius: 5px;
    border: 1px solid #bba17b;
    font-size: 20px;
    color: #bba17b;
  }

  .btn2 {
    line-height: 57px;
    color: #fff;
    border: none;
    background: #bba17b;
  }
}
// 弹窗
.popup3 {
  width: 810px;
  height: 90vh;
  overflow-y: scroll;
  background: #ffffff;
  box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.11);
  border-radius: 6px;
  padding: 16px 16px 62px 52px;

  .close_icon {
    width: 24px;
    height: 24px;
    display: block;
    object-fit: contain;
    margin-left: auto;
    margin-right: 0;
  }

  .code {
    width: 212px;
    height: 212px;
    display: block;
    object-fit: cover;
    margin: 0 auto 10px;
  }

  .pop_btn {
    width: 220px;
    text-align: center;
    height: 55px;
    line-height: 55px;
    background: #bba17b;
    border-radius: 5px;
    margin: 0 auto;
  }

  .ml145 {
    margin-left: 145px;
  }
  .btn_click {
    display: block;
    width: 600px;
    text-align: center;
    height: 75px;
    line-height: 75px;
    border-radius: 5px;
    font-size: 26px;
    background: #bba17b;
    color: #fff;
    margin: 20px auto;
  }
}
</style>