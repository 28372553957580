<template>
  <div class="container">
    <Header @input="input"></Header>
    <div class="main pr pt68 flex"> 
      <Mine></Mine>
      <div class="right_part flex-grow-1 ml20">
        <div class="fs20 lh28 fwb mb20">
          <span class="col6 cursor">A CARE/</span>
          <span class="col3 cursor">A CARE详情</span>
        </div>
        <div class="fs20 col10 fwb lh40 mb30">我的设备</div>
        <div class="flex mb40">
          <img class="img" src="../assets/static/images/device1.png" alt="" />
          <div class="flex-grow-1">
            <div class="fs20 col10 fwb lh44 m-ellipsis mb10">
              DrArrivo 家用脸面部提拉紧致微电流 宙斯二代美容仪
            </div>
            <div class="fs18 col85 lh28 mb15">m-ellipsis</div>
            <div class="flex flex-between">
              <div class="fs18 col85 lh28">仪器编号:Zhousi0019830M</div>
              <div class="fs18 col85 lh28">购买日期:2024.03.37 18:17</div>
            </div>
          </div>
        </div>
        <div class="fs20 col10 fwb lh40 mb5">购买日期:2024.03.37 18:17</div>
        <div class="fs18 col3 lh28 mb20">RMB 1,999</div>
        <div class="fs16 col6 lh26 mb40">
          ·
          硬件维修：免费维修屏幕（仅宙斯III代），导头芯片，机身各1次，购买服务后，系统自动生成各项免费维修服务的优惠券，用户在申请维保时，售后生成维修服务订单，用户可选择对应的维修项目优惠抵扣券进行抵扣，抵扣后即实际支付金额为0。
          <br />
          ·
          以旧换新：宙斯，宙斯II代，宙斯III代美容仪，赫露丝美容仪，魅影美容仪，幻影美容仪，缪斯美眼仪，缪斯美容仪有最高折算参考价，按照不同版本和实际情况，送检后得出实际折算价（参考表格）
          <br />
          · 服务期限：购买服务计划起2年，每份服务，保障期限为2年；
          <br />
          ·
          2年内未触发维保售后的计划购买者，按剩余月份比例退款至零钱钱包，可申请退款或以旧换新购物时进行抵扣；
        </div>
        <div class="fs20 col10 fwb lh40 mb20">维保记录</div>
        <div v-for="(item, index) in 2" :key="index" class="jl_part mb20">
          <div class="fs18 col10 fwb lh18 mb20">03.21 16:20</div>
          <div class="fs14 col6 lh14 mb10">03.21 16:20</div>
          <div class="fs14 col10 lh22 mb25">
            美容无法开机，没有任何反应。充电也没有反应没有摔过，前几天还在正常使用。
          </div>
          <div class="fs14 col6 lh15 mb20">故障照片</div>
          <div class="flex flex-wrap mb20">
            <div
              v-for="(item, index) in 3"
              :key="index"
              class="mb20 img_part mr15"
            >
              <img src="../assets/static/images/imgs1.png" alt="" />
              <div class="tc fs14 col10 m-ellipsis">设备照片</div>
            </div>
          </div>
          <div class="fs14 colcc lh14 mb15">处理结果</div>
          <div class="fs14 col10">已返厂维修，维修正常</div>
        </div>
        <div class="flex-box flex-center mt5">
          <div class="btn_confirm colf tc fs16 cursor">以旧换新</div>
          <div class="btn_confirm colf tc fs16 cursor">申请维保</div>
          <div class="btn_confirm colf tc fs16 cursor">申请退款</div>
        </div>
      </div>
    </div>
    <Footer></Footer>
  </div>
</template> <script>
import { useHead } from "@unhead/vue";
export default {
  name: "index",
  setup() {
    useHead({
      title: "A CARE详情",
      meta: [
        { name: "description", content: "This is my page description" },
        { property: "og:title", content: "My Page Title" },
        { property: "og:description", content: "This is my page description" },
        { property: "og:url", content: "https://example.com/my-page" },
        { property: "og:image", content: "https://example.com/my-image.jpg" },
      ],
      link: [{ rel: "canonical", href: "https://example.com/my-page" }],
    });
  },
  data() {
    return {};
  },

  methods: {},
};
</script>
      
      <style lang="less" scoped>
.container {
  background: #f6f6f6;
  width: 100vw;
}
.main {
  width: 1200px;
  margin: 20px auto;
}
.mw200 {
  max-width: 200px;
}
.border_b {
  border-bottom: 1px solid #eeeff0;
}

.right_part {
  background: #fdfdfd;
  border-radius: 13px;
  overflow: hidden;
  padding: 35px 30px;
  .img {
    width: 129px;
    height: 129px;
    display: block;
    object-fit: cover;
    margin-right: 23px;
  }
  .jl_part {
    width: 100%;
    background: #f5f5f5;
    border-radius: 8px;
    padding: 20px;
  }
  .img_part {
    width: 88px;
    img {
      width: 88px;
      height: 88px;
      display: block;
      object-fit: cover;
      margin: 0 auto 10px;
      border-radius: 8px;
      border: 1px solid #dddddd;
    }
  }
  .btn_confirm {
    margin-right: 12px;
    width: 138px;
    text-align: center;
    height: 48px;
    line-height: 48px;
    background: #bba17b;
    border-radius: 5px;
  }
  .btn_confirm:last-of-type {
    margin-right: 0;
  }
}
</style>
                
      
        
        