<template>
  <div class="container pr pt68">
    <Header @input="input"></Header>
    <div class="main">
      <div class="fs28 col10 fwb lh60 mb10">
        {{ articleDetail.name }}
      </div>
      <div class="fs12 col85 pb30 border_b mb20">
        <span class="mr30">{{ articleDetail.createtime_text }}</span><span>{{ articleDetail.view_num }}人已阅读</span>
      </div>
      <div class="markDiv" v-html="articleDetail.content"></div>
      <!-- <div class="fs20 col10 fwb lh30 mb15">美与人的可能性</div>
      <div class="fs16 col6 lh32 mb40">
        我们希望把“美丽”带给每一个人。本着“美丽与人的可能性”为理念，不断超越，实现美的无限可能。A.GLOBAL始终致力于创造更具安全性，更突显性能的高科技美容产品，让更多人的真实绽放自我和美丽。我们认为在开始成为自己时，就是一个人的美真正的开始。
      </div>
      <img class="imgs mb40" src="../assets/static/images/index_banner.png" alt="" />
      <div class="fs20 col10 fw lh30 mb15">MADE IN JANPAN 100%的高品质。</div>
      <div class="fs16 col6 lh32 mb30">
        A.GLOBAL的美容仪，从企划、设计、制造都是在日本岐阜县的总公司工厂进行的。每一台仪器，都由技师手工操作，仔细检测后出厂。生产高性能，耐用且性能强大的美容仪器是A.GLOBAL是自豪的荣耀。
      </div>
      <div class="fs20 col10 fw lh30 mb15">为了可以安心安全使用而努力。</div>
      <div class="fs16 col6 lh32 mb30">
        A.GLOBAL的美容仪，从企划、设计、制造都是在日本岐阜县的总公司工厂进行的。每一台仪器，都由技师手工操作，仔细检测后出厂。生产高性能，耐用且性能强大的美容仪器是A.GLOBAL是自豪的荣耀。
      </div>
      <div class="fs20 col10 fw lh30 mb15">
        追求更高端技术，更卓越品质的美容仪器。
      </div>
      <div class="fs16 col6 lh32 mb30">
        A.GLOBAL的美容仪，从企划、设计、制造都是在日本岐阜县的总公司工厂进行的。每一台仪器，都由技师手工操作，仔细检测后出厂。生产高性能，耐用且性能强大的美容仪器是A.GLOBAL是自豪的荣耀。
      </div> -->
      <div @click="praise()" class="good_part cursor flex-box flex-center">
        <img v-if="articleDetail.praise" class="icon mr5" src="../assets/static/icon/good_on.png" alt="" />
        <img v-else class="icon mr5" src="../assets/static/icon/good.png" alt="" />
        <div class="fs16 col89">{{ articleDetail.praise_num }}</div>
      </div>
      <div class="pr textarea_part mb30">
        <textarea class="textarea fs16 col89 pt25 plr30" placeholder="请输入你的评论" v-model="comment_text" name=""
          id=""></textarea>
        <div @click="comment" class="btn colf tc fs16 cursor">发表</div>
      </div>
      <div class="fs24 col3 fwb lh34 mb10">评论 {{ articleDetail.comment_num }}</div>
      <div v-for="(item, index) in comment_list" :key="index" class="flex ptb20 cursor border_b">
        <img class="avatar mr10" :src="item.avatar" alt="" />
        <div class="flex-grow-1">
          <div class="mb10 lh15">
            <span class="fs15 col5 mr10">{{ item.nickname }}</span><span class="fs12 cola">{{ item.createtime }}</span>
          </div>
          <div class="fs15 col10 lh20">
            {{ item.content }}
          </div>
        </div>
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>
<script>
import { useHead } from "@unhead/vue";
import axios from "@/axios";
import router from "@/router";
import { getCurrentInstance } from "vue";
import { ElMessage, ElMessageBox } from "element-plus";
export default {
  name: "aboutUs",
  setup() {
    useHead({
      title: "教程",
      meta: [
        { name: "description", content: "This is my page description" },
        { property: "og:title", content: "My Page Title" },
        { property: "og:description", content: "This is my page description" },
        { property: "og:url", content: "https://example.com/my-page" },
        { property: "og:image", content: "https://example.com/my-image.jpg" },
      ],
      link: [{ rel: "canonical", href: "https://example.com/my-page" }],
    });
  },
  data() {
    return {
      show: false,
      oss: getCurrentInstance().appContext.config.globalProperties.$oss,
      article_id: '',
      articleDetail: '',
      comment_list: [],
      comment_text: '',
      data: ''
    };
  },
  created() {
    this.article_id = this.$route.params.article_id
    this.getArticleDetail()
    this.getCommentList()
  },
  methods: {
    clickshow() {
      this.show = !this.show;
    },
    getArticleDetail() {
      axios.post('xilushop/article/article_detail', {
        article_id: this.article_id,
      })
        .then(res => {
          this.articleDetail = res.data
        })
        .catch(error => {
          console.error(error); F
        });
    },
    getCommentList() {
      axios.post('xilushop/article/comment_list', {
        article_id: this.article_id,
      })
        .then(res => {
          this.comment_list = res.data
        })
        .catch(error => {
          console.error(error);
        });
    },
    praise(comment_id = '') {
      axios.post('xilushop/article/article_praise', {
        article_id: this.article_id,
        comment_id: comment_id
      })
        .then(res => {
          ElMessage.success(res.msg);
          setTimeout(res => {
            if (comment_id) {
              this.getCommentList()
            } else {
              this.getArticleDetail()
            }
          }, 1500)
        })
        .catch(error => {
          console.error(error);
        });
    },
    comment() {
      if (!this.comment_text) {
        ElMessage.error('评论内容不能为空');
        return;
      }
      axios.post('xilushop/article/create_comment', {
        article_id: this.article_id,
        content: this.comment_text
      })
        .then(res => {
          this.comment_text = ''
          ElMessage.success(res.msg);
          this.getCommentList()
        })
        .catch(error => {
          console.error(error);
        });
    },
  },
};
</script>
<style lang="less" scoped>
 /deep/ video{
width: 100%!important;
height: auto!important;
max-height: 80vh;
}

.container {
  background: #fff;
  width: 100vw;
  overflow-x: hidden;
}

.main {
  width: 1208px;
  margin: 30px auto 75px;
}

.border_b {
  border-bottom: 1px solid #eeeff0;
}

.imgs {
  width: 100%;
  height: auto;
  display: block;
  border-radius: 20px;
  overflow: hidden;
}

.good_part {
  width: 86px;
  height: 86px;
  border-radius: 50%;
  background: #000000;
  overflow: hidden;
  margin: 10px auto 40px;

  .icon {
    width: 17px;
    height: 17px;
    display: block;
    object-fit: contain;
  }
}

.textarea,
.textarea_part {
  width: 1208px;
  height: 194px;
  background: #f1f2f4;
  border-radius: 12px;
}

.btn {
  width: 74px;
  height: 40px;
  line-height: 40px;
  background: #bba17b;
  border-radius: 20px;
  position: absolute;
  z-index: 2;
  right: 32px;
  bottom: 18px;
}

.avatar {
  width: 40px;
  height: 40px;
  display: block;
  object-fit: cover;
  border-radius: 50%;
  overflow: hidden;
}
</style>