
<template>
  <div class="container pr pt68">
    <Header @input="input"></Header>
    <div class="flex-box flex-center pt10 mb35 pb35">
      <a
        href="#site"
        class="fs16 labe1"
        :class="true ? 'active_labe1' : 'unactive_labe1'"
      >
        品牌故事
      </a>
      <a href="#site2" class="fs16 labe1"> 品牌历程 </a>
      <a href="#site3" class="fs16 labe1"> 品牌荣誉 </a>
    </div>
    <div class="pr" id="site">
      <img
        class="aboutUs_banner"
        src="../assets/static/images/aboutUs_banner.png"
        alt=""
      />
      <img
        class="title"
        src="../assets/static/images/aboutUs_title.png"
        alt=""
      />
    </div>
    <div class="mw120 flex pt90 pb110">
      <img
        class="imgs1"
        src="../assets/static/images/aboutUs_imgs1.png"
        alt=""
      />
      <div class="flex-grow-1 tr pt30">
        <img class="add" src="../assets/static/icon/add_aboutUs.png" alt="" />
        <div class="fs42 col3 fwb lh60 mb20 tr">品牌介绍</div>
        <div class="fs16 col3 tr lh30">
          品牌介绍文字品牌介绍文字品牌介绍文字品牌介绍文字品牌介mw绍文字品牌介绍文字品牌介绍文字品牌介绍文字品牌介绍文字品牌介绍文字品牌介绍文字品牌介绍文字品牌介绍文字品牌介绍文字品牌介绍文字品牌介绍文字品牌介绍文字品牌介绍文字品牌介绍文字品牌介绍文字
          <br />品牌介绍文字品牌介绍文字品牌介绍文字品牌介绍文字品牌介绍文字品牌介绍文字品牌介绍文字品牌介绍文字品牌介绍文字品牌介绍文字品牌介绍文字品牌介绍文字品牌介绍文字品牌介绍文字品牌介绍文字品牌介绍文字品牌介绍文字品牌介绍文字品牌介绍文字品牌介绍文字
        </div>
      </div>
    </div>
    <div class="bg-main"></div>
    <div class="mw120 flex mt_415">
      <div class="flex-grow-1">
        <div class="fs38 col3 op5 fwb lh53 mb15">
          INTRODUCTION TO THE COMPANY'S PHILOSOPHY
        </div>
        <div class="fs24 colbba lh35 fwb mb15">一句话的企业理念介绍</div>
        <div class="fs16 col3 lh30">
          品牌介绍文字品牌介绍文字品牌介绍文字品牌介绍文字品牌介绍文字品牌介绍文字品牌介绍文字品牌介绍文字品牌介绍文字品牌介
        </div>
      </div>
      <img
        class="imgs1 imgs2"
        src="../assets/static/images/aboutUs_imgs1.png"
        alt=""
      />
    </div>
    <div id="site2" class="tc fs38 col3 fwb lh54 mb10">发展历程</div>
    <div class="tc fs26 col3 fwb op5 lh38 mb50">Development History</div>
    <div class="pr line">
      <img
        class="progress_icon1"
        src="../assets/static/icon/progress_icon1.png"
        alt=""
      />
      <div v-for="(item, index) in 5" :key="index" class="part pr part_info">
        <div class="lh54 fs38 colbba mb10">
          <span class="fwb colbba font_i">2012</span>年
        </div>
        <div class="fs18 col3 op5 lh24 fwb pb40">企业成立</div>
        <img
          class="progress_icon1 progress_icon"
          src="../assets/static/icon/progress_icon2.png"
          alt=""
        />
      </div>
      <img
        class="progress_icon1 progress_icon3"
        src="../assets/static/icon/progress_icon1.png"
        alt=""
      />
    </div>
    <div id="site3" class="bg_b tc">
      <div class="fs38 colf fwb lh54 mb10">品牌荣誉</div>
      <div class="fs26 colf fwb op5 lh38">Brand Honors</div>
    </div>
    <!-- <div class="scroll_part">
      <div v-for="(item, index) in 12" :key="index" class="part1 mr25">
        <img src="../assets/static/images/aboutUs_imgs2.png" alt="" />
      </div>
    </div> -->
    <!--  -->
    <div class="tab-all">
      <div class="tab-four">
        <swiper
          :loop="true"
          :autoplay="false"
          :slides-per-view="5"
          :slides-per-group="1"
          :space-between="13"
          :centered-slides="true"
          :modules="modules"
        >
          <swiper-slide v-for="(item, index) in fourList" :key="index">
            <div class="img cursor">
              <img
                class="img"
                src="../assets/static/images/aboutUs_imgs2.png"
                alt=""
              />
            </div>
          </swiper-slide>
        </swiper>
      </div>
    </div>

    <Footer></Footer>
  </div>
</template>
        <script>
import { useHead } from "@unhead/vue";
import { Autoplay, Scrollbar } from "swiper";
import { Swiper, SwiperSlide } from "../../node_modules/swiper/vue/swiper-vue";
import "swiper/swiper.min.css";
import "swiper/swiper-bundle.min.css";
export default {
  name: "aboutUs",
  setup() {
    useHead({
      title: "关于我们",
      meta: [
        { name: "description", content: "This is my page description" },
        { property: "og:title", content: "My Page Title" },
        { property: "og:description", content: "This is my page description" },
        { property: "og:url", content: "https://example.com/my-page" },
        { property: "og:image", content: "https://example.com/my-image.jpg" },
      ],
      link: [{ rel: "canonical", href: "https://example.com/my-page" }],
    });
  },
  components: {
    Swiper,
    SwiperSlide,
  },
  data() {
    return {
      modules: [Autoplay, Scrollbar],
      midActive: 0,
      fourList: [
        {
          imgurl: require("../assets/static/icon/v1.png"),
          title: "V1会员",
        },
        {
          imgurl: require("../assets/static/icon/v2.png"),
          title: "V2会员",
        },
        {
          imgurl: require("../assets/static/icon/v3.png"),
          title: "V3会员",
        },
      ],
    };
  },
  created() {},
  methods: {
    onSwiper(e) {
      // console.log(e);
    },
    onSlideChange(e) {
      console.log(e.realIndex);
    },
    changeItem(e) {
      this.scrollIndex = e;
    },

    slideChange() {
      console.log(this.$refs.mySwiperMid.swiper.realIndex);
      this.midActive = this.$refs.mySwiperMid.swiper.realIndex;
    },
  },
};
</script>
        <style lang="less" scoped>
.container {
  background: #fff;
  width: 100vw;
  overflow-x: hidden;
}
// .main {
//   width: 805px;
//   margin: 40px auto 85px;
// }
.labe1 {
  margin-right: 65px;
  cursor: pointer;
}
.labe1:last-of-type {
  margin-right: 0;
}
.active_labe1,
.labe1:hover {
  width: 86px;
  text-align: center;
  height: 86px;
  line-height: 84px;
  border: 1px solid #bba17b;
  color: #bba17b;
  border-radius: 50%;
}
.unactive_labe1 {
  color: rgba(0, 0, 0, 0.4);
}
.aboutUs_banner {
  width: 100vw;
  height: auto;
  display: block;
  object-fit: cover;
}
.title {
  width: 480px;
  height: 236px;
  display: block;
  object-fit: cover;
  position: absolute;
  z-index: 2;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
}
.pt90 {
  padding-top: 90px;
}
.pb110 {
  padding-bottom: 110px;
}
.imgs1 {
  width: 388px;
  height: 498px;
  display: block;
  object-fit: cover;
  margin-right: 200px;
}
.imgs2 {
  margin-right: unset;
  margin-left: 270px;
}
.add {
  width: 25px;
  height: 25px;
  display: block;
  object-fit: cover;
  margin-left: auto;
  margin-right: 0;
}
.mw120 {
  max-width: 1200px;
  margin: 0 auto;
}
.bg-main {
  width: 100vw;
  height: 461px;
  background: #f1f2f4;
}
.colbba {
  color: #bba17b;
}
.mt_415 {
  margin-top: -415px;
  position: relative;
  z-index: 2;
  margin-bottom: 100px;
}
.progress_icon1 {
  width: 17px;
  height: 17px;
  display: block;
  object-fit: contain;
  margin: 0 auto 68px;
}

.part {
  width: 50%;
  text-align: right;
  margin-left: 0;
  margin-right: auto;
  padding-right: 40px;
}

.part:nth-of-type(2n) {
  text-align: left;
  margin-left: auto;
  margin-right: 0;
  padding-left: 40px;
}
.part .progress_icon {
  position: absolute;
  top: 20px;
  right: -8.5px;
  z-index: 2;
}

.part:nth-of-type(2n) .progress_icon {
  position: absolute;
  top: 20px;
  right: unset;
  left: -8.5px !important;
}
.line::after {
  content: "";
  display: block;
  width: 1px;
  height: calc(100% - 34px);
  position: absolute;
  top: 17px;
  left: 50%;
  transform: translateX(-50%);
  background: rgba(151, 151, 151, 0.5);
}
.bg_b {
  min-height: 324px;
  background: #000000;
  padding-top: 68px;
}
.scroll_part {
  position: relative;
  z-index: 2;
  width: calc(100vw - 240px);
  margin: -75px auto 169px;
  white-space: nowrap;
  overflow-x: scroll;
  .part1 {
    display: inline-block;
    width: 280px;
    height: 389px;
    img {
      width: 100%;
      height: 100%;
      display: block;
      object-fit: cover;
    }
  }
  .part1:last-of-type {
    margin-right: 0;
  }
}
//

.tab-all {
  width: 1505px;
  height: 389px;
  margin: -75px auto 169px;
  .swiper {
    width: 100%;
    height: 389px;
  }
  .img {
    width: 280px;
    height: 389px;
    display: block;
   
    transition: all 0.5s;
    -webkit-transition: all 0.5s;
  }
  /deep/ .swiper-slide {
    position: relative;

    overflow: hidden;
    width: 280px;
    height: 389px;
  }
  /deep/ .swiper-slide-active {
    position: relative;
    overflow: visible;
    z-index: 11;
    width: 280px;
    height: 389px;
    .img {
      transform-origin: center !important;
      -webkit-transform-origin: center !important;
      position: absolute;
      left: 0;
    }
    z-index: 111;
  }
  .swiper-slide-active::after {
    display: none;
  }
  .tab-four {
    width: 100%;
    .swiper-slide-active {
      .img {
        border: none;
       
      }
    }
  }
  .icon {
    width: 55px;
    height: 55px;
    display: block;
    object-fit: contain;
  }
}
</style>
       