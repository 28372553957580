<template>
  <div class="container pr pt68">
    <Header @input="input"></Header>
    <div class="main">
      <div class="fs28 col10 fwb mb20">完善您的会员信息</div>
      <div class="lh22 fs16 col6 mb40">请完善你的会员信息以便激活你的设备</div>
      <div class="flex flex-wrap mb15">
        <div class="part1 mr30 mb20 flex-box fs14">
          <div class="col6">姓名</div>
          <input class="flex-grow-1 tr col10" v-model="nickname" type="text" />
        </div>
        <div class="part1 mr30 mb20 flex-box fs14">
          <div class="col6">联系电话</div>
          <input class="flex-grow-1 tr col10" disabled :value="user_mobile" type="number" />
        </div>
        <div class="part1  pr mr30 cursor">
          <div class="mb20 flex-box fs14">
            <div class="col6">省/市/区</div>
            <input v-if="!city_show" @click="city_show = true" readonly class="flex-grow-1 tr col10 mr10" :value="city"
              type="text" />
            <el-cascader v-if="city_show" class="jlpMySelect jlpMySelect2 jlpMySelect3 mr30 mb20 flex-box fs14"
              size="large" :options="options" v-model="selectedOptions" @change="addressChange"
              clear-icon="el-icon-minus" style="
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
              height: 48px;
              z-index: 2;
              border: none;
              background-color: transparent;
            text-align: right!important; ">
            </el-cascader>
            <!-- <img
              class="arrow_down"
              src="../assets/static/icon/arrow_down.png"
              alt=""
            /> -->
          </div>
        </div>
        <div class="part1 mr30 mb20 flex-box fs14">
          <div class="col6">详细地址</div>
          <input class="flex-grow-1 tr col10" v-model="address" type="text" />
        </div>
      </div>
      <div class="fs28 col10 fwb lh86 mb10">选择设备</div>
      <div class="flex flex-wrap mb20">
        <div @click="choose_goods(index)" v-for="(item, index) in list" :key="index" :class="goods_index === index?'active_device':''" class="part2 cursor mb10">
          <img class="img" :src="oss + item.images_arr[0] + w500" alt="" />
          <div class="tc fs16 col10 ">{{ item.sub_name }}</div>
        </div>
      </div>
      <div class="fs28 col10 fwb lh86 mb10">上传美容仪照片（非必填）</div>
      <div class="flex flex-wrap mb20">
        <div v-for="(item, index) in goods_images" :key="index" class="part2 mb10">
          <div class="pr">
            <img class="img" :src="item" alt="" />
            <div @click="uploadDel(index)" class="close_part cursor">
              <img class="icon" src="../assets/static/icon/close_icon.png" alt="" />
            </div>
          </div>
        </div>
        <el-upload class="avatar-uploader" action="https://ag-test.p.xilukeji.com/api/common/upload"
          :show-file-list="false" multiple="true" :on-change="handleChange" :on-success="handleAvatarSuccess">
          <div class="upload cursor">
            <img class="icon" src="../assets/static/icon/uppload_icon.png" alt="" />
          </div>
        </el-upload>
      </div>
      <div class="fs28 col10 fwb lh86 mb10">上传购买发票或防伪卡图片（非必填）</div>
      <div class="flex flex-wrap mb20">
        <div v-for="(item, index) in buy_images" :key="index" class="part2 mb10">
          <div class="pr">
            <img class="img" :src="item" alt="" />
            <div @click="uploadDel2(index)" class="close_part cursor">
              <img class="icon" src="../assets/static/icon/close_icon.png" alt="" />
            </div>
          </div>
        </div>
        <el-upload class="avatar-uploader" action="https://ag-test.p.xilukeji.com/api/common/upload"
          :show-file-list="false" multiple="true" :on-change="handleChange" :on-success="handleAvatarSuccess2">
          <div class="upload cursor">
            <img class="icon" src="../assets/static/icon/uppload_icon.png" alt="" />
          </div>
        </el-upload>
      </div>
      <div class="fs28 col10 fwb lh86 mb15">购买渠道&序列编号</div>
      <div class="flex flex-wrap mb15">
        <!-- <div class="part1 mr30 mb20 flex-box fs14">
          <div class="col6 flex-grow-1">购买渠道</div>
          <img
            class="arrow_down"
            src="../assets/static/icon/arrow_down.png"
            alt=""
          />
        </div> -->
        <el-dropdown @command="handleCommand">
          <span class="part1 mr30 mb20 flex-box fs14 cursor">
            <div class="col6 flex-grow-1">{{ channel_index === '' ? '购买渠道' : channel_list[channel_index].name }}</div>
            <el-icon class="el-icon--right">
              <img class="arrow_down" src="../assets/static/icon/arrow_down.png" alt="" />
            </el-icon>
          </span>
          <template #dropdown>
            <el-dropdown-menu style="width: 200px">
              <el-dropdown-item v-for="(item, index) in channel_list" :command="index">{{ item.name
                }}</el-dropdown-item>
            </el-dropdown-menu>
          </template>
        </el-dropdown>
        <div class="part1 mr30 mb20 flex-box fs14">
          <div class="col6">产品序列号</div>
          <input class="flex-grow-1 tr col10" v-model="goods_no" placeholder="请填写序列号" type="text" />
        </div>
        <div class="part1 mr30 mb20 flex-box fs14">
          <div class="col6">手机号</div>
          <input class="flex-grow-1 tr col10" v-model="mobile" placeholder="请填写手机号" type="number" />
        </div>
        <div class="part1 mr30 mb20 flex-box fs14">
          <div class="col6">验证码</div>
          <input class="flex-grow-1 tr col10" v-model="code" placeholder="请填写验证码" type="number" maxlength="4" />
        </div>
        <div @click="getCode" class="part1 part3 colb fs14 tr mb50 cursor plans">{{ tips }}</div>
      </div>
      <div @click="submit" class="btn cursor fs20 colf">立即绑定</div>
    </div>
    <Footer></Footer>
  </div>
</template>
<script>
import { useHead } from "@unhead/vue";
import { regionData, CodeToText } from "element-china-area-data";
import { ElMessage } from "element-plus";
import axios from "@/axios";
import router from "@/router";
import { getCurrentInstance } from "vue";
var btn;
//引用 element-china-area-data

export default {
  name: "productDetail",
  setup() {
    useHead({
      title: "绑定设备",
      meta: [
        { name: "description", content: "This is my page description" },
        { property: "og:title", content: "My Page Title" },
        { property: "og:description", content: "This is my page description" },
        { property: "og:url", content: "https://example.com/my-page" },
        { property: "og:image", content: "https://example.com/my-image.jpg" },
      ],
      link: [{ rel: "canonical", href: "https://example.com/my-page" }],
    });
  },
  data() {
    return {
      options: regionData,
      selectedOptions: [],
      nickname: '',
      city: '',
      city_show: false,
      user_mobile: '',
      address: '',
      list: [],
      oss: getCurrentInstance().appContext.config.globalProperties.$oss,
      w1000: getCurrentInstance().appContext.config.globalProperties.$w1000,
      w500: getCurrentInstance().appContext.config.globalProperties.$w500,
      channel_list: [],
      channel_index: '',
      goods_no: '',
      mobile: '',
      code: '',
      secend: 60,
      tips: '发送验证码',
      canGetCode: true,
      goods_index: '',
      goods_images: [],
      buy_images: []
    };
  },
  created() {
    btn = true
    this.getUser()
    this.getList()
    this.getChannelList()
  },
  methods: {
    uploadDel(index) {
      (this.goods_images).splice(index, 1)
    },
    uploadDel2(index) {
      (this.buy_images).splice(index, 1)
    },
    handleAvatarSuccess(e) {
      this.goods_images.push(e.data.fullurl)
    },
    handleAvatarSuccess2(e) {
      this.buy_images.push(e.data.fullurl)
    },
    choose_goods(index) {
      this.goods_index = index
    },
    getUser() {
      axios.post('xilushop/user/info', {})
        .then(res => {
          if (res.data) {
            this.nickname = res.data.nickname;
            if (res.data.city) {
              this.city_show = false
            }
            this.city = res.data.city;
            this.user_mobile = res.data.mobile;
            this.address = res.data.address;
          }
        })
        .catch(error => {
          console.error(error);
        });
    },
    getList() {
      axios.post('xilushop/goods/goods_lists', {
        device: 1,
        pagesize: 999,
      })
        .then(res => {
          this.list = res.data.data
        })
        .catch(error => {
          console.error(error);
        });
    },
    getChannelList() {
      axios.post('index/channel_list', {})
        .then(res => {
          this.channel_list = res.data
        })
        .catch(error => {
          console.error(error);
        });
    },
    getCode() {
      if (this.canGetCode) {
        if (this.mobile == '') {
          ElMessage.error('请填写手机号码')
          return;
        }
        axios.post('sms/send', {
          mobile: this.mobile
        })
          .then(res => {
            if (res.code == 1) {
              this.canGetCode = false;
              ElMessage.success('验证码已发送')
              this.secend = 59;
              var s = setInterval(res => {
                this.tips = this.secend + '秒'
                this.secend--;
                if (this.secend < 0) {
                  clearInterval(s)
                  this.canGetCode = true;
                  this.tips = '发送验证码';
                }
              }, 1000)
            } else {
              ElMessage.error(res.msg)
              return;
            }
          })
          .catch(error => {
            console.error(error);
          });
      } else {
        ElMessage.error('倒计时结束后再发送')
      }
    },
    submit() {
      if (!this.nickname || !this.address || !this.city) {
        ElMessage.error('请填写完整会员信息')
        return false
      }
      var data = {};
      data.nickname = this.nickname
      data.city = this.city
      data.address = this.address
      axios.post('xilushop/user/profile', data)
        .then(res => {
          if (res.code == 1) {
            this.submit2()
          } else {
            ElMessage.error(res.msg)
          }
        })
        .catch(error => {
          console.error(error);
        });
    },
    submit2() {
      if (this.goods_index === '') {
        ElMessage.error('请选择设备')
        return false
      }
      if (this.channel_index === '') {
        ElMessage.error('请选择购买渠道')
        return false
      }
      if (!this.goods_no) {
        ElMessage.error('请输入仪器序列编码')
        return false
      }
      if (!this.mobile) {
        ElMessage.error('请输入手机号')
        return false
      }
      // var goods_images = uni.getStorageSync('goods_images');
      // if (goods_images[0]) {
      //   var goods_images_str = goods_images.join(',')
      // } else {
      var goods_images_str = ''
      // }
      // var buy_images = uni.getStorageSync('buy_images');
      // if (buy_images[0]) {
      //   var buy_images_str = buy_images.join(',')
      // } else {
      var buy_images_str = ''
      // }
      if (!btn) {
        return false
      }
      btn = false
      axios.post('ag/create_device', {
        channel_id: this.channel_list[this.channel_index].id,
        goods_id: this.list[this.goods_index].id,
        goods_no: this.goods_no,
        goods_images: goods_images_str,
        buy_images: buy_images_str,
        mobile: this.mobile,
        code: this.code,
      })
        .then(res => {
          btn = true
          if (res.code == 1) {
            ElMessage.success(res.msg)
            setTimeout(res => {
              router.replace({
                name: 'myDeviceList'
              })
            }, 1500)
          } else {
            ElMessage.error(res.msg)
          }
        })
        .catch(error => {
          console.error(error);
        });
    },
    addressChange(arr) {
      console.log(arr);
      var arr = []
      var list = this.options;
      for (var i in list) {
        if (list[i].value == arr[0]) {
          arr.push(list[i].label)
          var list2 = list[i].children
          for (var i2 in list2) {
            if (list2[i2].value == arr[1]) {
              arr.push(list2[i2].label)
              var list3 = list2[i2].children
              for (var i3 in list3) {
                if (list3[i3].value == arr[2]) {
                  arr.push(list3[i3].label)
                }
              }
            }
          }
        }
      }
      this.city = arr.join('-');
    },
    handleCommand(command) {
      console.log(command)
      this.channel_index = command
    }
  },
};
</script>
<style lang="less" scoped>
.container {
  background: #fff;
  width: 100vw;
  overflow-x: hidden;
}

.main {
  width: 805px;
  margin: 38px auto 144px;
}

.part1 {
  width: 386px !important;
  height: 48px !important;
  line-height: 48px !important;
  padding: 0 25px 0 20px !important;
  background: #f1f2f4 !important;
  border-radius: 8px !important;

  input {
    background: transparent;
    padding: 0;
    margin: 0;
    font-weight: 400;
    font-size: 14px;
    color: #101010;
  }
}

.part1:nth-of-type(2n) {
  margin-right: 0;
  line-height: 20px;
}

.part2 {
  width: 108px;
  margin-right: 10px;

  .img {
    width: 108px;
    height: 108px;
    display: block;
    object-fit: cover;
    border-radius: 9px;
    overflow: hidden;
    border: 1px solid #dddddd;
    margin: 0 auto 12px;
  }

  .close_part {
    width: 100%;
    height: 20px;
    background: rgba(0, 0, 0, 0.4);
    border-radius: 0px 0px 9px 9px;
    position: absolute;
    z-index: 2;
    left: 0;
    bottom: 0;
    padding: 3px 0;

    .icon {
      width: 13px;
      height: 13px;
      display: block;
      object-fit: cover;
      margin: 0 auto;
    }
  }
}

.upload {
  width: 106px;
  height: 106px;
  background: #f1f2f4;
  border-radius: 9px;
  padding: 44px 0;

  .icon {
    width: 18px;
    height: 18px;
    display: block;
    object-fit: cover;
    margin: 0 auto;
  }
}

.arrow_down {
  width: 10px;
  height: 7px;
  display: block;
  object-fit: cover;
}

.btn {
  width: 236px;
  text-align: center;
  height: 60px;
  line-height: 60px;
  background: #bba17b;
  border-radius: 5px;
  margin: 0 auto;
}

.part3 {
  background: none !important;
}
</style>
<style lang="less">
.jlpMySelect2 {
  text-align: right !important;

  input,
  .el-input__wrapper {
    text-align: right !important;
    padding-right: 20px;
  }

  input:focus,
  .el-input__wrapper {
    border: none !important;
    box-shadow: none !important
  }

  .el-input__suffix {
    display: none;
  }

}
</style>