<template>
  <div class="container">
    <Header @input="input"></Header>
    <div class="main pr pt68 flex">
      <Mine></Mine>
      <div class="right_part flex-grow-1 ml20">
        <div class="fs22 col3 fwb lh30 mb25">我的维保</div>
        <div v-for="(item, index) in 2" :key="index" class="jl_part mb20">
          <div class="fs18 col10 fwb lh18 mb20">03.21 16:20</div>
          <div class="fs14 col6 lh14 mb10">03.21 16:20</div>
          <div class="fs14 col10 lh22 mb25">
            美容无法开机，没有任何反应。充电也没有反应没有摔过，前几天还在正常使用。
          </div>
          <div class="fs14 col6 lh15 mb20">故障照片</div>
          <div class="flex flex-wrap mb20">
            <div
              v-for="(item, index) in 3"
              :key="index"
              class="mb20 img_part mr15 cursor"
            >
              <img src="../assets/static/images/imgs1.png" alt="" />
              <div class="tc fs14 col10 m-ellipsis">设备照片</div>
            </div>
          </div>
          <div class="fs14 colb lh14 mb15">处理结果</div>
          <div class="fs14 col10">已返厂维修，维修正常</div>
        </div>
      </div>
    </div>
    <Footer></Footer>
  </div>
</template> <script>
import { useHead } from "@unhead/vue";
export default {
  name: "index",
  setup() {
    useHead({
      title: "我的维保",
      meta: [
        { name: "description", content: "This is my page description" },
        { property: "og:title", content: "My Page Title" },
        { property: "og:description", content: "This is my page description" },
        { property: "og:url", content: "https://example.com/my-page" },
        { property: "og:image", content: "https://example.com/my-image.jpg" },
      ],
      link: [{ rel: "canonical", href: "https://example.com/my-page" }],
    });
  },
  data() {
    return {};
  },

  methods: {},
};
</script>
        
        <style lang="less" scoped>
.container {
  background: #f6f6f6;
  width: 100vw;
}
.main {
  width: 1200px;
  margin: 20px auto;
}
.mw200 {
  max-width: 200px;
}
.border_b {
  border-bottom: 1px solid #eeeff0;
}

.right_part {
  background: #fdfdfd;
  border-radius: 13px;
  overflow: hidden;
  padding: 30px 30px;
  .img {
    width: 129px;
    height: 129px;
    display: block;
    object-fit: cover;
    margin-right: 23px;
  }
  .jl_part {
    width: 100%;
    background: #f5f5f5;
    border-radius: 8px;
    padding: 20px;
  }
  .img_part {
    width: 88px;
    img {
      width: 88px;
      height: 88px;
      display: block;
      object-fit: cover;
      margin: 0 auto 10px;
      border-radius: 8px;
      border: 1px solid #dddddd;
    }
  }
  .btn_confirm {
    margin-right: 12px;
    width: 138px;
    text-align: center;
    height: 48px;
    line-height: 48px;
    background: #bba17b;
    border-radius: 5px;
  }
  .btn_confirm:last-of-type {
    margin-right: 0;
  }
}
</style>
                  
        
          
          