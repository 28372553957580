import { createRouter, createWebHistory, createWebHashHistory } from 'vue-router'
import index from '../views/index.vue'
import login from '../views/login.vue'
import productDetail from '../views/productDetail.vue'
import bindDevice from '../views/bindDevice.vue'
import myDevice from '../views/myDevice.vue'
import tradeInTheOld from '../views/tradeInTheOld.vue'
import noticeDetail from '../views/noticeDetail.vue'
import shoppingCentre from '../views/shoppingCentre.vue'
import productsList from '../views/productsList.vue'
import buy from '../views/buy.vue'
import shoppingCart from '../views/shoppingCart.vue'
import confirmOrder from '../views/confirmOrder.vue'
import AGCARE from '../views/AGCARE.vue'
import AGCAREDetail from '../views/AGCAREDetail.vue'
import confirmAGCAREOrder from '../views/confirmAGCAREOrder.vue'
import afterSales from '../views/afterSales.vue'
import applyAfterSales from '../views/applyAfterSales.vue'
import aboutUs from '../views/aboutUs.vue'
import tutorial from '../views/tutorial.vue'
import tutorialDetail from '../views/tutorialDetail.vue'
import activities from '../views/activities.vue'
import joinActive from '../views/joinActive.vue'
import confirmActive from '../views/confirmActive.vue'
import myPage from '../views/myPage.vue'
import orderDetail from '../views/orderDetail.vue'
import orderApplyAfterSales from '../views/orderApplyAfterSales.vue'
import refundDetails from '../views/refundDetails.vue'
import AGCAREorderDetail from '../views/AGCAREorderDetail.vue'
import myDeviceList from '../views/myDeviceList.vue'
import dateOfUse from '../views/dateOfUse.vue'
import useReport from '../views/useReport.vue'
import myIncome from '../views/myIncome.vue'
import AGCAREList from '../views/AGCAREList.vue'
import AGCAREListDetail from '../views/AGCAREListDetail.vue'
import myActivities from '../views/myActivities.vue'
import myActivitiesDeatil from '../views/myActivitiesDeatil.vue'
import myAddress from '../views/myAddress.vue'
import myMaintenance from '../views/myMaintenance.vue'
import myCoupons from '../views/myCoupons.vue'
import myAfterSales from '../views/myAfterSales.vue'
import myAfterSalesDetail from '../views/myAfterSalesDetail.vue'
import memberIntroduction from '../views/memberIntroduction.vue'
import customer from '../views/customer.vue'
import storelist from '../views/storelist.vue'
import rule from '../views/rule.vue'
import servicesList from '../views/servicesList.vue'
import selectCity from '../views/selectCity.vue'












const routes = [
  {
    path: '/',
    name: 'index',
    component: index
  },
  {
    path: '/selectCity',
    name: 'selectCity',
    component: selectCity
  },
  {
    path: '/servicesList/:acare_log_id',
    name: 'servicesList',
    component: servicesList
  },
  {
    path: '/storelist/:home/:shop_country_id/:shop_city_id/:city_name',
    name: 'storelist',
    component: storelist
  },
  {
    path: '/customer',
    name: 'customer',
    component: customer
  },
  {
    path: '/memberIntroduction',
    name: 'memberIntroduction',
    component: memberIntroduction
  },
  {
    path: '/myAfterSalesDetail/:id',
    name: 'myAfterSalesDetail',
    component: myAfterSalesDetail
  },
  {
    path: '/myAfterSales',
    name: 'myAfterSales',
    component: myAfterSales
  },
  {
    path: '/myCoupons/:order/:pay_price/:user_coupon_ids',
    name: 'myCoupons',
    component: myCoupons
  },
  {
    path: '/myMaintenance',
    name: 'myMaintenance',
    component: myMaintenance
  },
  {
    path: '/myAddress/:order/:device_id/:repair_id/:order_goods_id',
    name: 'myAddress',
    component: myAddress
  },
  {
    path: '/myActivitiesDeatil/:order_id',
    name: 'myActivitiesDeatil',
    component: myActivitiesDeatil
  },
  {
    path: '/myActivities',
    name: 'myActivities',
    component: myActivities
  },
  {
    path: '/AGCAREListDetail',
    name: 'AGCAREListDetail',
    component: AGCAREListDetail
  },
  {
    path: '/AGCAREList',
    name: 'AGCAREList',
    component: AGCAREList
  },
  {
    path: '/myIncome',
    name: 'myIncome',
    component: myIncome
  },
  {
    path: '/useReport',
    name: 'useReport',
    component: useReport
  },
  {
    path: '/dateOfUse',
    name: 'dateOfUse',
    component: dateOfUse
  },
  {
    path: '/myDeviceList',
    name: 'myDeviceList',
    component: myDeviceList
  },
  {
    path: '/AGCAREorderDetail/:order_id',
    name: 'AGCAREorderDetail',
    component: AGCAREorderDetail
  },
  {
    path: '/refundDetails',
    name: 'refundDetails',
    component: refundDetails
  },
  {
    path: '/orderApplyAfterSales/:order_id/:order_goods_id',
    name: 'orderApplyAfterSales',
    component: orderApplyAfterSales
  },
  {
    path: '/orderDetail/:order_id',
    name: 'orderDetail',
    component: orderDetail
  },
  {
    path: '/myPage/:tab',
    name: 'myPage',
    component: myPage
  },
  {
    path: '/confirmActive/:activity_id',
    name: 'confirmActive',
    component: confirmActive
  },
  {
    path: '/joinActive/:activity_id',
    name: 'joinActive',
    component: joinActive
  },
  {
    path: '/activities',
    name: 'activities',
    component: activities
  },
  {
    path: '/tutorialDetail/:article_id',
    name: 'tutorialDetail',
    component: tutorialDetail
  },
  {
    path: '/tutorial',
    name: 'tutorial',
    component: tutorial
  },
  {
    path: '/aboutUs',
    name: 'aboutUs',
    component: aboutUs
  },
  {
    path: '/applyAfterSales/:device_id',
    name: 'applyAfterSales',
    component: applyAfterSales
  },
  {
    path: '/afterSales',
    name: 'afterSales',
    component: afterSales
  },
  {
    path: '/confirmAGCAREOrder',
    name: 'confirmAGCAREOrder',
    component: confirmAGCAREOrder
  },
  {
    path: '/AGCAREDetail/:goods_id',
    name: 'AGCAREDetail',
    component: AGCAREDetail
  },
  {
    path: '/AGCARE',
    name: 'AGCARE',
    component: AGCARE
  },
  {
    path: '/confirmOrder/:device_id/:coupon_id/:ids/:type/:goods_sku_id/:acare_goods_id/:acare_device_id/:goods_num',
    name: 'confirmOrder',
    component: confirmOrder
  },
  {
    path: '/shoppingCart',
    name: 'shoppingCart',
    component: shoppingCart
  },
  {
    path: '/buy/:goods_id/:device_id/:coupon_id/:coupon_price/:coupon_name',
    name: 'buy',
    component: buy
  },
  {
    path: '/productsList',
    name: 'productsList',
    component: productsList
  },
  {
    path: '/shoppingCentre',
    name: 'shoppingCentre',
    component: shoppingCentre
  },
  {
    path: '/noticeDetail/:notice_id',
    name: 'noticeDetail',
    component: noticeDetail
  },
  {
    path: '/tradeInTheOld/:goods_id',
    name: 'tradeInTheOld',
    component: tradeInTheOld
  },
  {
    path: '/myDevice/:type/:ids/:goods_num',
    name: 'myDevice',
    component: myDevice
  },
  {
    path: '/bindDevice',
    name: 'bindDevice',
    component: bindDevice
  },
  {
    path: '/productDetail/:goods_id',
    name: 'productDetail',
    component: productDetail
  },
  {
    path: '/login',
    name: 'login',
    component: login
  },
  {
    path: '/rule/:id',
    name: 'rule',
    component: rule
  }
]

const router = createRouter({
  // history: createWebHistory(),
  history: createWebHashHistory(),
  routes
})

export default router