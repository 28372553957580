<template>
  <div class="container pr">
    <Header @input="input"></Header>
    <div class="flex-box plr120">
      <div class="flex-grow-1 fs28 col10 fwb lh86">售后</div>
      <div @click="toRule(5)" class="colb fs20 lh30 cursor">进一步了解？</div>
    </div>
    <div class="main">
      <div v-for="(item, index) in list" :key="index" class="ptb30 part1 flex">
        <img class="img" :src="oss + item.thumb_image" alt="" />
        <div class="flex-grow-1">
          <div class="fs24 col10 fwb lh44 m-ellipsis mb10">
            {{ item.name }}
          </div>
          <div class="fs24 col10 fwb lh44 mb43">{{ item.sub_name }}</div>
          <div class="flex-box">
            <div class="flex-grow-1 fs20 col85 lh28">{{ item.date }}</div>
            <div @click="toDetail(index)" class="btn fs16 colb tc cursor">{{ item.repair_status_text }}</div>
          </div>
        </div>
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>
<script>
import { useHead } from "@unhead/vue";
import { getCurrentInstance } from "vue";
import axios from "@/axios";
import router from "@/router";
import { ElMessage, ElMessageBox } from "element-plus";
export default {
  name: "productDetail",
  setup() {
    useHead({
      title: "售后",
      meta: [
        { name: "description", content: "This is my page description" },
        { property: "og:title", content: "My Page Title" },
        { property: "og:description", content: "This is my page description" },
        { property: "og:url", content: "https://example.com/my-page" },
        { property: "og:image", content: "https://example.com/my-image.jpg" },
      ],
      link: [{ rel: "canonical", href: "https://example.com/my-page" }],
    });
  },
  data() {
    return {
      oss: getCurrentInstance().appContext.config.globalProperties.$oss,
      w1000: getCurrentInstance().appContext.config.globalProperties.$w1000,
      w500: getCurrentInstance().appContext.config.globalProperties.$w500,
      list: []
    };
  },
  created() {
    this.getList()
  },
  methods: {
    toRule(id) {
      router.push({
        name: 'rule',
        params: {
          id: id
        }
      })
    },
    getList() {
      axios.post('ag/device_list', {
        is_shouhou: 1
      })
        .then(res => {
          this.list = res.data
        })
        .catch(error => {
          console.error(error);
        });
    },
    toDetail(index) {
      if (this.list[index].repair_check_status == 1 || this.list[index].repair_check_status == 0) {
        router.push({ name: 'myAfterSalesDetail', params: { id: this.list[index].repair_id } });
      } else {
        router.push({ name: 'applyAfterSales', params: { device_id: this.list[index].id } });
      }

    },
  },
};
</script>
<style lang="less" scoped>
.container {
  background: #fff;
  width: 100vw;
  overflow-x: hidden;
  padding-top: 103px;
}

.main {
  width: 770px;
  margin: 0px auto 103px;
}

.add_device {
  width: 16px;
  height: 16px;
  display: block;
  object-fit: cover;
}

.part1 {
  border-bottom: 1px solid #e3e3e3;

  .img {
    width: 173px;
    height: 173px;
    display: block;
    object-fit: cover;
    margin-right: 53px;
  }
}

.btn {
  padding: 0 17px;
  text-align: center;
  height: 34px;
  line-height: 34px;
  background: #f0f0f7;
  border-radius: 23px;
}
</style>