<template>
    <div class="container">
        <Header @input="input"></Header>
        <div @click="toBuy" class="container pr pt68">
            <img class="imgs1 cursor" :src="oss + goodsDetail.pc1_image" alt="">
            <!--            <img v-for="item in goodsDetail.images_arr" class="imgs1 cursor" :src="oss+item" alt="">-->
            <div v-html="goodsDetail.pc_content"></div>
        </div>
        <Footer></Footer>
    </div>
</template>
<script>
import { useHead } from "@unhead/vue";
import router from "@/router";
import axios from "@/axios";
import { getCurrentInstance } from "vue";

export default {
    name: "productDetail",
    setup() {
        useHead({
            title: "商品详情",
            meta: [
                { name: "description", content: "This is my page description" },
                { property: "og:title", content: "My Page Title" },
                { property: "og:description", content: "This is my page description" },
                { property: "og:url", content: "https://example.com/my-page" },
                { property: "og:image", content: "https://example.com/my-image.jpg" },
            ],
            link: [{ rel: "canonical", href: "https://example.com/my-page" }],
        });
    },
    data() {
        return {
            oss: getCurrentInstance().appContext.config.globalProperties.$oss,
            goods_id: '',
            goodsDetail: ''
        };
    },
    created() {
        this.goods_id = this.$route.params.goods_id
        this.getGoodsDetail()
    },
    methods: {
        getGoodsDetail() {
            axios.post('xilushop/goods/goods_detail', {
                goods_id: this.goods_id
            })
                .then(res => {
                    this.goodsDetail = res.data
                })
                .catch(error => {
                    console.error(error);
                });
        },
        toBuy() {
            router.push({ name: 'buy', params: { goods_id: this.goods_id, device_id: 0, coupon_id: 0, coupon_price: 0, coupon_name: 0 } });
        }
    },
};
</script>
<style lang="less" scoped>
.container {
    background: #fff;
    width: 100vw;
    overflow-x: hidden;

    .imgs1 {
        widows: 100%;
        display: block;
        height: auto;
    }
}
</style>