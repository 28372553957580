<template>
  <div class="container">
    <Header @input="input"></Header>
    <div class="main pr">
      <div class="flex-box part1">
        <div class="w50 fwb tc fs14 col-3">A CARE保障</div>
        <div class="w25 fwb tc fs14 col-3">服务权益</div>
        <div class="w25 fwb tc fs14 col-3">已使用</div>
      </div>
      <div v-for="(item, index) in list" class="flex-box bg-white ptb20">
        <div class="w50 tc fs16 col-3">
          <div class="fs16">
            {{ item.name }}
          </div>
          <div v-if="item.desc" class="fs12">
            {{ item.desc }}
          </div>
        </div>
        <div class="w25 fs16 col-3 tc">
          {{ item.total_num }}
        </div>
        <div class="w25 fs16 col-3 tc">
          {{ item.use_num }}
        </div>
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>
<script>
import { useHead } from "@unhead/vue";
import Pagination from "../components/pagination.vue";
import axios from "@/axios";
import { getCurrentInstance } from "vue";
import router from "@/router";
import { ElMessage } from "element-plus";
export default {
  name: "index",

  setup() {
    useHead({
      title: "服务列表",
      meta: [
        { name: "description", content: "This is my page description" },
        { property: "og:title", content: "My Page Title" },
        { property: "og:description", content: "This is my page description" },
        { property: "og:url", content: "https://example.com/my-page" },
        { property: "og:image", content: "https://example.com/my-image.jpg" },
      ],
      link: [{ rel: "canonical", href: "https://example.com/my-page" }],
    });
  },
  components: {
    Pagination,
  },
  data() {
    return {
      oss: getCurrentInstance().appContext.config.globalProperties.$oss,
      w1000: getCurrentInstance().appContext.config.globalProperties.$w1000,
      w500: getCurrentInstance().appContext.config.globalProperties.$w500,
      list: [],
      acare_log_id: ''
    };
  },
  created() {
    this.acare_log_id = this.$route.params.acare_log_id
    this.getList()
  },
  methods: {
    getList() {
      axios.post('acare/acare_service', {
        acare_log_id: this.acare_log_id
      }).then(res => {
        this.list = res.data
      })
    },
  },
};
</script>

<style lang="less" scoped>
.container {
  background: #fff;
  width: 100vw;
  padding-top: 68px;
}

.main {
  width: calc(100vw - 240px);
  margin: 30px auto 60px;
  border-radius: 4px;
  border: 1px solid #e2e2e2;
}

.part1 {
  width: 100%;
  height: 44px;
  line-height: 44px;
  background: #f3f3f3;
  border-radius: 4px;
}

.w50 {
  width: 50%;
}

.w25 {
  width: 25%;
}
</style>