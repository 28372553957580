
<template>
  <div class="container pr pt68">
    <Header @input="input"></Header>
    <div class="main">
      <div class="fs32 col10 fwb lh86">选购 面部美容仪</div>
      <div v-for="(item,index) in 4" :key="index" class="part bgf mb20 flex cursor">
        <img class="img" src="../assets/static/images/device1.png" alt="" />
        <div class="flex-grow-1 pt47">
          <div class="fs24 lh24 col838 m-ellipsis">新款宙斯Ⅲ代美容仪</div>
          <div class="fs40 col10 fwb lh86 m-ellipsis">
            DrArrivo 家用脸面部提拉紧致新品
          </div>
          <div class="fs22 col0 lh26 m-ellipsis mb20">
            宙燃「芯」生 全塑逆龄
          </div>
          <div class="flex-box col10 mb55">
            <span class="fs18 col10">RMB</span>
            <span class="fs28 col10 fwb mlr2">7999</span>
            <span class="fs20 col10">起</span>
          </div>
          <div class="btn colf tc fs20 cursor">购买</div>
        </div>
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>
              <script>
import { useHead } from "@unhead/vue";
export default {
  name: "productDetail",
  setup() {
    useHead({
      title: "商品列表",
      meta: [
        { name: "description", content: "This is my page description" },
        { property: "og:title", content: "My Page Title" },
        { property: "og:description", content: "This is my page description" },
        { property: "og:url", content: "https://example.com/my-page" },
        { property: "og:image", content: "https://example.com/my-image.jpg" },
      ],
      link: [{ rel: "canonical", href: "https://example.com/my-page" }],
    });
  },

  data() {
    return {};
  },
  created() {},
  methods: {},
};
</script>
    <style lang="less" scoped>
.container {
  background: #f6f6f6;
  width: 100vw;
  overflow-x: hidden;
}
.main {
  width: 1200px;
  margin: 33px auto 37px;
}
.img {
  width: 366px;
  height: 366px;
  display: block;
  object-fit: cover;
  margin-right: 37px;
}
.pt47 {
  padding-top: 47px;
}
.mlr2 {
  margin-left: 2px;
  margin-right: 2px;
}
.btn {
  width: 116px;
  text-align: center;
  height: 48px;
  line-height: 48px;
  background: #bba17b;
  border-radius: 34px;
  margin-left: 5px;
}
</style>
             