<template>
  <div class="container pr pt68">
    <Header @input="input"></Header>
    <div class="main">
      <div class="tc fs32 col10 fwb lh86 mb10">
        您的购物车{{ cartList.length }}件商品共计 RMB {{ total_price }}
      </div>
      <div @click="sure" class="btn_buy colf tc fs18 cursor">立即结算</div>
      <div v-for="(item, index) in cartList" class="ptb30 border_t flex cursor">
        <img class="img"
          :src="item.is_acare == 1 ? oss + item.acare_goods.image + w500 : oss + item.goods.thumb_image + w500"
          alt="" />
        <div class="flex-grow-1">
          <div class="fs24 col10 fwb lh44 m-ellipsis">
            {{ item.is_acare == 1 ? item.acare_goods.name : item.goods.name }}
          </div>
          <div class="fs24 col10 fwb lh44 mb10">RMB
            {{ item.is_acare == 1 ? item.acare_goods.price : item.goods_sku.goods_price }}</div>
          <div class="flex-box fs20">
            <div class="flex-grow-1 col85">{{ item.is_acare == 1 ? '服务期限：' + item.acare_goods.day + '天 * ' +
              item.goods_num :
              item.goods_sku.sku_title + ' * ' + item.goods_num }}</div>
            <div @click="del(item.id)" class="colb cursor">移除</div>
          </div>
        </div>
      </div>
      <!-- <div class="ptb30 border_t flex">
        <img class="img" src="../assets/static/images/device1.png" alt="" />
        <div class="flex-grow-1">
          <div class="fs24 col10 fwb lh44 m-ellipsis">
            DrArrivo 家用脸面部提拉紧致微电流 宙斯二代美容仪
          </div>
          <div class="fs24 col10 fwb lh44 mb10">RMB 7,999</div>
          <div class="flex-box fs20 mb30">
            <div class="flex-grow-1 col85">黑色*1</div>
            <div class="colb cursor">移除</div>
          </div>
          <div class="border_t pt30">
            <div class="fs24 col10 fwb lh44">A CARE服务计划</div>
            <div class="flex-box lh44 mb8">
              <div class="flex-grow-1 fs24 col10 fwb lh44">RMB 1,999</div>
              <div class="colb fs20 cursor">移除</div>
            </div>
            <div class="fs20 col85 lh28 ">
              ·
              硬件维修：免费维修屏幕（仅宙斯III代），导头芯片，机身各1次，购买服务后，系统自动生成各项免费维修服务的优惠券，用户在申请维保时，售后生成维修服务订单，用户可选择对应的维修项目优惠抵扣券进行抵扣，抵扣后即实际支付金额为0。
              <br />
              ·
              以旧换新：宙斯，宙斯II代，宙斯III代美容仪，赫露丝美容仪，魅影美容仪，幻影美容仪，缪斯美眼仪，缪斯美容仪有最高折算参考价，按照不同版本和实际情况，送检后得出实际折算价（参考表格）
              <br />· 服务期限：购买服务计划起2年，每份服务，保障期限为2年；
              <br />·
              2年内未触发维保售后的计划购买者，按剩余月份比例退款至零钱钱包，可申请退款或以旧换新购物时进行抵扣；
            </div>
          </div>
        </div>
      </div> -->
      <div class="border_t pt20 pl223">
        <!-- <div class="flex-box mb10">
          <div class="fs18 col85 lh24">小计</div>
          <div class="flex-grow-1 fs18 col10 fwb lh44 tr">RMB 1,9990</div>
        </div>
        <div class="flex-box mb10">
          <div class="fs18 col85 lh24">优惠</div>
          <div class="flex-grow-1 fs18 col10 fwb lh44 tr">RMB 5990</div>
        </div>
        <div class="flex-box mb30">
          <div class="fs18 col85 lh24">运费</div>
          <div class="flex-grow-1 fs18 col10 fwb lh44 tr">RMB 24</div>
        </div> -->
        <div class="border_t pt30 flex-box pb37">
          <div class="fs24 col10 fwb lh44">总计</div>
          <div class="flex-grow-1 tr fs24 col10 fwb lh44">RMB {{ total_price }}</div>
        </div>
        <div @click="sure" class="btn_buy2 colf tc fs20 cursor">立即结算</div>
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>
<script>
import { useHead } from "@unhead/vue";
import axios from "@/axios";
import router from "@/router";
import { getCurrentInstance } from "vue";
import { ElMessage, ElMessageBox } from "element-plus";

export default {
  name: "shoppingCart",
  setup() {
    useHead({
      title: "购物车",
      meta: [
        { name: "description", content: "This is my page description" },
        { property: "og:title", content: "My Page Title" },
        { property: "og:description", content: "This is my page description" },
        { property: "og:url", content: "https://example.com/my-page" },
        { property: "og:image", content: "https://example.com/my-image.jpg" },
      ],
      link: [{ rel: "canonical", href: "https://example.com/my-page" }],
    });
  },

  data() {
    return {
      oss: getCurrentInstance().appContext.config.globalProperties.$oss,
      w1000: getCurrentInstance().appContext.config.globalProperties.$w1000,
      w500: getCurrentInstance().appContext.config.globalProperties.$w500,
      cartList: [],
      total_price: 0,
    };
  },
  created() {
    this.getCartList();
  },
  methods: {
    getCartList() {
      axios.post('xilushop/cart/cart_list', {})
        .then(res => {
          for (var i in res.data) {
            if (res.data[i].is_acare) {
              res.data[i].acare_goods.price = parseFloat(res.data[i].acare_goods.price)
            }
          }
          this.cartList = res.data;
          this.countPrice();
        })
        .catch(error => {
          console.error(error);
        });
    },
    countPrice() {
      var list = this.cartList
      var total_price = 0
      for (var i in list) {
        if (list[i].is_acare == 1) {
          total_price += parseInt(list[i].goods_num) * parseFloat(list[i].acare_goods.price)
        } else {
          total_price += parseInt(list[i].goods_num) * parseFloat(list[i].goods_sku.goods_price)
        }
      }
      this.total_price = total_price
    },
    del(id) {
      ElMessageBox.confirm('确定要移除吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        // 确定按钮被点击的回调
        axios.post('xilushop/cart/del', {
          cart_ids: id
        })
          .then(res => {
            if (res.code == 1) {
              ElMessage.success(res.msg)
              this.getCartList()
            } else {
              ElMessage.error(res.msg)
            }
          })
          .catch(error => {
            console.error(error);
          });
      }).catch(() => {
        // 取消按钮被点击的回调
        console.log('取消按钮被点击');
      });
    },
    sure() {
      var list = this.cartList
      var ids = []
      for (var i in list) {
        ids.push(list[i].id)
      }
      if (ids.length == 0) {
        ElMessage.error('购物车是空的');
        return false
      }
      var ids_str = ids.join(',')
      router.push({
        name: 'confirmOrder', params: {
          ids: ids_str,
          device_id: 0,
          goods_sku_id: 0,
          goods_num: 0,
          acare_device_id: 0,
          coupon_id: 0,
          acare_goods_id: 0,
          type: 'cart'
        }
      });
    },
  },
};
</script>
<style lang="less" scoped>
.container {
  background: #fff;
  width: 100vw;
  overflow-x: hidden;
}

.main {
  width: 960px;
  margin: 33px auto 100px;
}

.btn_buy {
  width: 160px;
  text-align: center;
  height: 48px;
  line-height: 48px;
  background: #bba17b;
  border-radius: 5px;
  margin: 0 auto 55px;
}

.border_t {
  border-top: 1px solid #e3e3e3;
}

.img {
  width: 173px;
  height: 173px;
  display: block;
  object-fit: cover;
  margin-right: 53px;
}

.mb8 {
  margin-bottom: 8px;
}

.pl223 {
  padding-left: 223px;
}

.pb37 {
  padding-bottom: 37px;
}

.btn_buy2 {
  width: 236px;
  text-align: center;
  height: 60px;
  line-height: 60px;
  background: #bba17b;
  border-radius: 5px;
  margin-left: auto;
  margin-right: 0;
}
</style>