<template>
  <div class="container pr pt68">
    <Header @input="input"></Header>
    <div class="main">
      <div v-for="(item, index) in list" :key="index" class="flex mb30">
        <img @click="toDetail(item.id)" class="img cursor part_h" :src="oss+item.image" alt="" />
        <div class="flex-grow-1 pt20">
          <div class="fs28 col10 fwb lh52 mb20">
            {{item.name}}
          </div>
          <div class="fs16 col6 lh20 mb20">
            {{item.address}}
          </div>
          <div v-if="item.price > 0" class="flex  mb25">
            <div class="fs14 col0 lh15">RMB</div>
            <div class="fs22 fwb col0 ml2 lh18">7999</div>
            <div class="fs16 lh16 col0">起</div>
          </div>
          <div v-if="item.price == 0" class="flex  mb25">
            <div class="fs14 col0 lh15"></div>
            <div class="fs22 fwb col0 ml2 lh18">免费</div>
            <div class="fs16 lh16 col0"></div>
          </div>
          <div class="fs14 col6 lh20 mb115"><span
              class="mr30">{{ item.starttime_text }}</span><span>{{ item.order_num }}人已报名</span></div>
          <div @click="toDetail(item.id)" class="btn colf tc fs15 cursor">立即报名</div>
        </div>
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>
<script>
import { useHead } from "@unhead/vue";
import { getCurrentInstance } from "vue";
import axios from "@/axios";
import router from "@/router";
import { ElMessage, ElMessageBox } from "element-plus";
var page;
export default {
  name: "productDetail",
  setup() {
    useHead({
      title: "活动",
      meta: [
        { name: "description", content: "This is my page description" },
        { property: "og:title", content: "My Page Title" },
        { property: "og:description", content: "This is my page description" },
        { property: "og:url", content: "https://example.com/my-page" },
        { property: "og:image", content: "https://example.com/my-image.jpg" },
      ],
      link: [{ rel: "canonical", href: "https://example.com/my-page" }],
    });
  },
  data() {
    return {
      oss: getCurrentInstance().appContext.config.globalProperties.$oss,
      list: []
    };
  },
  created() {
    page = 1;
    this.getList();
  },
  methods: {
    getList() {
      axios.post('activity/activity_list', {
        page: page
      }).then(res => {
        for (var i in res.data.data) {
          res.data.data[i].price = parseFloat(res.data.data[i].price)
        }
        if (page == 1) {
          this.list = res.data.data
        } else {
          this.list = this.list.concat(res.data.data)
        }
      })
    },
    toDetail(id) {
      router.push({ name: 'joinActive', params: { activity_id: id } });
    }
  },
};
</script>
<style lang="less" scoped>
.container {
  background: #fff;
  width: 100vw;
  overflow-x: hidden;
}

.main {
  width: 1200px;
  margin: 20px auto 20px;

  .img {
    width: 830px;
    height: 434px;
    display: block;
    object-fit: cover;
    border-radius: 20px;
    overflow: hidden;
    margin-right: 45px;
  }
}

.btn {
  width: 176px;
  text-align: center;
  height: 48px;
  line-height: 48px;
  background: #bba17b;
  border-radius: 5px;
}

.mb115 {
  margin-bottom: 115px;
}
</style>