<template>
  <div class="container pr pt68">
    <Header @input="input"></Header>
    <div class="flex-box flex-center pt10 mb30">
      <div v-for="(item, index) in categoryList" class="fs16 labe1"
        :class="tabIndex == index ? 'active_labe1' : 'unactive_labe1'" :key="index" @click="testTabClick(index)">
        {{ item.name }}
      </div>
      <!-- <div class="fs16 labe1" :class="tabIndex == 1 ? 'active_labe1' : 'unactive_labe1'" :key="index"
        @click="testTabClick(1)">
        护肤教程
      </div> -->
    </div>
    <div class="main">
      <!-- <template v-if="tabIndex == 0">
        <img v-for="(item, index) in list" :key="index" class="imgs1" src="../assets/static/images/tutorial_imgs1.png"
          alt="" />
      </template> -->
      <div class="flex flex-wrap">
        <div @click="toDetail(item.id)" v-for="(item, index) in list" :key="index" class="part2 mb30">
          <img :key="index" class="imgs2 mb10 part_h" :src="oss+item.thumb_image" alt="" />
          <div class="fs18 col10 fwb lh24 m-ellipsis">
            {{ item.name }}
          </div>
        </div>
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>
<script>
var page;
import { useHead } from "@unhead/vue";
import axios from '@/axios';
import { getCurrentInstance } from "vue";
import router from "@/router";
export default {
  name: "aboutUs",
  setup() {
    useHead({
      title: "教程",
      meta: [
        { name: "description", content: "This is my page description" },
        { property: "og:title", content: "My Page Title" },
        { property: "og:description", content: "This is my page description" },
        { property: "og:url", content: "https://example.com/my-page" },
        { property: "og:image", content: "https://example.com/my-image.jpg" },
      ],
      link: [{ rel: "canonical", href: "https://example.com/my-page" }],
    });
  },
  data() {
    return {
      oss: getCurrentInstance().appContext.config.globalProperties.$oss,
      tabIndex: 0,
      categoryList: [],
      list: []
    };
  },
  created() {
    page = 1;
    this.getCategoryList()
  },
  methods: {
    toDetail(id) {
      router.push({ name: 'tutorialDetail', params: { article_id: id } });
    },
    testTabClick(index) {
      console.log(index)
      this.tabIndex = index
      page = 1;
      this.list = []
      this.getList()
    },
    getCategoryList() {
      axios.post('xilushop/article_category/index', {})
        .then(res => {
          this.categoryList = res.data
          this.getList()
        })
        .catch(error => {
          console.error(error);
        });
    },
    getList() {
      axios.post('xilushop/article/get_articles_by_id', {
        category_id: this.categoryList[this.tabIndex].id,
        page: page,
        pagesize: 999
      })
        .then(res => {
          if (page == 1) {
            this.list = res.data.data
          } else {
            this.list.push.apply(this.list, res.data.data);
          }
        })
        .catch(error => {
          console.error(error);
        });

    },
  },
};
</script>
<style lang="less" scoped>
.container {
  background: #fff;
  width: 100vw;
  overflow-x: hidden;
}

.labe1 {
  margin-right: 65px;
  cursor: pointer;
}

.active_labe1,
.labe1:hover {
  width: 86px;
  text-align: center;
  height: 86px;
  line-height: 84px;
  border: 1px solid #bba17b;
  color: #bba17b;
  border-radius: 50%;
}

.labe1:last-of-type {

  margin-right: 0px;
}

.labe1:last-of-type {
  margin-right: 0;
}

.unactive_labe1 {
  color: rgba(0, 0, 0, 0.4);
}

.main {
  width: 1200px;
  margin: 0px auto;
}

.imgs1 {
  width: 1200px;
  height: 383px;
  display: block;
  object-fit: cover;
  margin-bottom: 43px;
}

.imgs2 {
  width: 280px;
  height: 375px;
  display: block;
  object-fit: cover;
  border-radius: 5px;
  overflow: hidden;
}

.part2 {
  width: 280px;
  margin-right: 26px;
}

.part2:nth-of-type(4n) {
  margin-right: 0;
}
</style>