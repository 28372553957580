<template>
  <div class="container pr pt68">
    <Header @input="input"></Header>
    <div class="main">
      <div class="flex-box">
        <div class="fs32 col10 fwb lh86 flex-grow-1">A CARE</div>
        <div @click="toRule(4)" class="colb fs18 cursor">进一步了解</div>
      </div>
    <div class="flex flex-between flex-wrap">
      <div v-for="(item, index) in list" :key="index" class="part bgf mb20 flex  cursor part_h">
        <img class="img" :src="oss + item.image + w500" alt="" />
        <div class="flex-grow-1 pt40">
          <div class="fs28 h66 col10 fwb  m-ellipsis-l2  mb20">
            {{ item.name }}
          </div>
          <div class="flex-box col10 mb35">
            <span class="fs28 col10">RMB</span>
            <span class="fs28 col10 ml10">{{ item.price }}</span>
          </div>
          <div @click="toDetail(item.id)" class="btn colf tc fs18 cursor">
            购买
          </div>
        </div>
      </div>
      </div>
    </div>
    <Popup :visible="sucPop" :confirmText="false" :cancleText="false" @close="closeSuc">
      <div class="popup">
        <div v-html="content"></div>
        <view @click="closeSuc" class="tc btn_click cursor"> 下一步 点击购买 </view>
      </div>
    </Popup>
    <Footer></Footer>
  </div>
</template>
<script>
import Popup from "../components/popup.vue";
import { useHead } from "@unhead/vue";
import axios from "@/axios";
import router from "@/router";
import { getCurrentInstance } from "vue";
import { ElMessage, ElMessageBox } from "element-plus";
export default {
  name: "productDetail",
  setup() {
    useHead({
      title: "AGCARE",
      meta: [
        { name: "description", content: "This is my page description" },
        { property: "og:title", content: "My Page Title" },
        { property: "og:description", content: "This is my page description" },
        { property: "og:url", content: "https://example.com/my-page" },
        { property: "og:image", content: "https://example.com/my-image.jpg" },
      ],
      link: [{ rel: "canonical", href: "https://example.com/my-page" }],
    });
  },
  components: {
    Popup,
  },
  data() {
    return {
      oss: getCurrentInstance().appContext.config.globalProperties.$oss,
      w1000: getCurrentInstance().appContext.config.globalProperties.$w1000,
      w500: getCurrentInstance().appContext.config.globalProperties.$w500,
      list: [],
      sucPop: true,
      id: "",
      name: "",
      content: "",
    };
  },
  created() {
    this.getList();
    this.getDetail();
  },
  methods: {
    getDetail() {
      axios
        .post("xilushop/Singlepage/get_article_by_id", {
          singlepage_id: 4,
        })
        .then((res) => {
          this.name = res.data.name;
          this.content = res.data.content;
        });
    },
    closeSuc() {
      this.sucPop = !this.sucPop;
    },
    toRule(id) {
      router.push({
        name: "rule",
        params: {
          id: id,
        },
      });
    },
    toDetail(id) {
      router.push({
        name: "AGCAREDetail",
        params: {
          goods_id: id,
        },
      });
    },
    getList() {
      axios.post("acare/acare_goods_list", {}).then((res) => {
        for (var i in res.data) {
          res.data[i].price = parseFloat(res.data[i].price);
        }
        this.list = res.data;
      });
    },
  },
};
</script>
<style lang="less" scoped>
.h66{height: 76px;line-height: 38px;}
.container {
  background: #f6f6f6;
  width: 100vw;
  overflow-x: hidden;
}

.main {
  width: 1200px;
  margin: 33px auto 37px;
}

.part {
  width: calc(50% - 18px);
  border-radius: 30px;
  overflow: hidden;
}

.img {
  width: 280px;
  height: 280px;
  display: block;
  object-fit: cover;
  margin-right: 15px;
}

.pt47 {
  padding-top: 47px;
}

.mlr2 {
  margin-left: 2px;
  margin-right: 2px;
}

.btn {
  width: 118px;
  text-align: center;
  height: 46px;
  line-height: 46px;
  background: #bba17b;
  border-radius: 34px;
  margin-left: 5px;
}

// 弹窗
.popup {
  width: 810px;
  height: 90vh;
  overflow-y: scroll;
  background: #ffffff;
  box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.11);
  border-radius: 6px;
  padding: 16px 16px 62px 52px;

  .close_icon {
    width: 24px;
    height: 24px;
    display: block;
    object-fit: contain;
    margin-left: auto;
    margin-right: 0;
  }

  .code {
    width: 212px;
    height: 212px;
    display: block;
    object-fit: cover;
    margin: 0 auto 10px;
  }

  .pop_btn {
    width: 220px;
    text-align: center;
    height: 55px;
    line-height: 55px;
    background: #bba17b;
    border-radius: 5px;
    margin: 0 auto;
  }

  .ml145 {
    margin-left: 145px;
  }

  .btn_click {
    display: block;
    width: 600px;
    text-align: center;
    height: 75px;
    line-height: 75px;
    border-radius: 5px;
    font-size: 26px;
    background: #bba17b;
    color: #fff;
    margin: 20px auto;
  }
}
</style>