<template>
  <div class="container">
    <Header @input="input"></Header>
    <div class="main pr pt68 flex">
      <Mine></Mine>
      <div class="right_part flex-grow-1 ml20">
        <div class="flex-box mt10 mb45">
          <div class="fs20 col3 fwb lh28 flex-grow-1">我的设备</div>
          <img @click="toBind" class="add_device cursor" src="../assets/static/icon/add_device.png" alt="" />
          <div @click="toBind" class="fs20 colb lh30 cursor">添加设备</div>
          <div @click="closeSuc3" class="fs20 colb ml20 lh30 cursor">设备易主</div>
        </div>
        <div v-for="(item, index) in list" :key="index" class="ml5 flex mb40 product_part">
          <img class="imgs1 mr20" :src="oss + item.thumb_image + w500" alt="" />
          <div class="flex-grow-1">
            <div class="m-ellipsis fs20 col10 fwb lh44">
              {{ item.name }}
            </div>
            <div class="fs18 col10 fwb lh44">{{ item.sub_name }}</div>
            <div class="flex-box">
              <div class="fs18 col85 lh28 flex-grow-1">序列号：{{ item.goods_no }}</div>
              <!-- <div class="label ml20 fs14 tc colb cursor">使用日历</div> -->
              <div v-if="item.bind_status == 1 && item.change_status != 3" @click="shouhou(item.id)"
                class="label ml20 fs14 tc colb cursor">申请售后</div>
              <div v-if="item.change_auth == 1 && item.bind_status == 1" @click="toChange()"
                class="label ml20 fs14 tc colb cursor">
                <text v-if="item.change_status == 0">以旧换新</text>
                <text v-else-if="item.change_status == 1">以旧换新待寄回</text>
                <text v-else-if="item.change_status == 2">以旧换新寄回运输中</text>
                <text v-else-if="item.change_status == 3">以旧换新已完成</text>
                <text v-else-if="item.change_status == 4">以旧换新已关闭</text>
              </div>
              <div v-if="item.change_status == 0 && item.bind_status == 1" @click="transactionShow(item.id)"
                class="label ml20 fs14 tc colb cursor">设备易主</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 选择交易方式 -->
    <Popup :visible="sucPop" :confirmText="false" :cancleText="false" @close="closeSuc">
      <div class="popup">
        <div class="fs18 col10 lh28 ml5 mb25">选易主方式</div>
        <div class="flex mb30">
          <div class="pop_tablist mr20 cursor" v-for="(item, index) in tablist" :key="index"
            @click="testTabClick(index)" :class="tabIndex == index ? 'pop_active' : 'pop_unactive'">
            {{ item }}
          </div>
        </div>
        <!-- <template v-if="tabIndex == 0">
          <div class="fs18 col10 lh20 mb15">手输入手机号</div>
          <input class="phone_input fs18 col10 mb40" v-model="check_mobile" placeholder="请填写对方手机号" type="number" />
        </template> -->
        <!-- <template v-if="tabIndex == 1"> -->
          <div class="fs18 col10 lh20 mb15">交易码</div>
          <div class="flex flex-between">
            <input class="num_input fs18 col10 mb40" v-for="item in code_arr" :value="item" disabled type="number" />
          </div>
        <!-- </template> -->
        <div class="flex-box flex-end">
          <div class="pop_btn2 fs18 col6 ml20 cursor" @click="closeSuc">关闭</div>
          <!-- <div @click="device_check_mobile" v-if="tabIndex == 0" class="pop_btn1 fs18 colf ml20 cursor">
            确定易主
          </div> -->
          <div @click="copy()"  class="pop_btn1 fs18 colf ml20 cursor">
            复制交易码
          </div>
        </div>
      </div>
    </Popup>
    <!-- 选择身份 -->
    <Popup :visible="sucPop2" :confirmText="false" :cancleText="false" @close="closeSuc2">
      <div class="popup2">
        <div class="fs16 col10 lh18 mb20">选择身份</div>
        <div class="pop_tablist mb20 fs18 tc cursor" v-for="(item, index) in tablist2" :key="index"
          @click="testTabClick2(index)" :class="tabIndex2 == index ? 'pop_active' : 'pop_unactive'">
          {{ item }}
        </div>
        <div @click="closeSuc2" class="fs16 col6 pb25 pt5 tc lh16 cursor">关闭</div>
      </div>
    </Popup>
    <!-- 输入交易码 -->
    <Popup :visible="sucPop3" :confirmText="false" :cancleText="false" @close="closeSuc3">
      <div class="popup3">
        <div class="fs16 col10 lh18 mb20">输入交易码</div>
        <!-- <div class="flex flex-between"> -->
        <input class="num_input1 fs24 fwb col10 mb30" v-model="change_code" placeholder="请填写交易码" maxlength="6"
          type="number" />
        <!-- </div> -->
        <div @click="device_check_code" class="pop_confirm colf tc fs18 cursor">确定</div>
        <div @click="closeSuc3" class="fs16 col6 pb25 pt10 tc lh16 cursor">关闭</div>
      </div>
    </Popup>
    <Footer></Footer>
  </div>
</template>
<script>
import { useHead } from "@unhead/vue";
import Popup from "../components/popup.vue";
import { ElMessage } from "element-plus";
import axios from "@/axios";
import router from "@/router";
import { getCurrentInstance } from "vue";
export default {
  name: "index",

  setup() {
    useHead({
      title: "我的设备",
      meta: [
        { name: "description", content: "This is my page description" },
        { property: "og:title", content: "My Page Title" },
        { property: "og:description", content: "This is my page description" },
        { property: "og:url", content: "https://example.com/my-page" },
        { property: "og:image", content: "https://example.com/my-image.jpg" },
      ],
      link: [{ rel: "canonical", href: "https://example.com/my-page" }],
    });
  },
  components: {
    Popup,
  },
  data() {
    return {
      oss: getCurrentInstance().appContext.config.globalProperties.$oss,
      w1000: getCurrentInstance().appContext.config.globalProperties.$w1000,
      w500: getCurrentInstance().appContext.config.globalProperties.$w500,
      state: 3, //0待支付  1待发货  2待收货  3已完成
      sucPop: false,
      tabIndex: 0,
      // tablist: ["输入手机号码易主", "生成交易码易主"],
      tablist: ["生成交易码易主"],
      sucPop2: false,
      tabIndex2: 0,
      tablist2: ["我是卖家", "我是买家"],
      sucPop3: false,
      list: [],
      device_id: '',
      check_mobile: '',
      code: '',
      code_arr: ['', '', '', '', '', ''],
      change_code: ''
    };
  },
  created() {
    this.getList()
  },
  methods: {
    shouhou(id) {
      router.push({ name: 'applyAfterSales', params: { device_id: id } });
    },
    toChange() {
      router.push({ name: 'tradeInTheOld', params: { goods_id: 0 } });
    },
    toBind() {
      router.push({
        name: 'bindDevice'
      })
    },
    copy() {
      navigator.clipboard.writeText((this.code).toString());
      ElMessage.success('复制成功')
    },
    transactionShow(id) {
      this.device_id = id
      this.closeSuc()
    },
    getList() {
      axios.post('ag/device_list', {
        is_mine: 1
      })
        .then(res => {
          this.list = res.data
        })
        .catch(error => {
          console.error(error);
        });
    },
    closeSuc() {
      this.sucPop = !this.sucPop;
      if (!this.sucPop) {
        this.tabIndex = 0;
        this.device_id = '';
        this.check_mobile = '';
        this.code = '';
        this.code_arr = ['', '', '', '', '', ''];
      } else {
        axios.post('ag/device_create_code', {
          device_id: this.device_id
        })
          .then(res => {
            if (res.code == 1) {
              this.code = res.data
              var code_str = (res.data).toString()
              this.code_arr = code_str.split("")
              this.open2()
            } else {
              ElMessage.error(res.msg)
            }
          })
          .catch(error => {
            console.error(error);
          });
      }
    },
    testTabClick(index) {
      console.log(index);
      this.tabIndex = index;
      if (index == 1) {
        axios.post('ag/device_create_code', {
          device_id: this.device_id
        })
          .then(res => {
            if (res.code == 1) {
              this.code = res.data
              var code_str = (res.data).toString()
              this.code_arr = code_str.split("")
              this.open2()
            } else {
              ElMessage.error(res.msg)
            }
          })
          .catch(error => {
            console.error(error);
          });
      }
    },
    device_check_mobile() {
      if (!this.check_mobile) {
        ElMessage.error('请填写手机号')
        return false
      }
      axios.post('ag/device_check_mobile', {
        mobile: this.check_mobile,
        device_id: this.device_id
      })
        .then(res => {
          if (res.code == 1) {
            ElMessage.success(res.msg)
            this.closeSuc()
            this.getList()
          } else {
            ElMessage.error(res.msg)
          }
        })
        .catch(error => {
          console.error(error);
        });
    },
    device_check_code() {
      if (parseInt(this.change_code) < 100000) {
        ElMessage.error('请输入6位交易码')
        return false
      }
      axios.post('ag/device_check_code', {
        code: this.change_code
      })
        .then(res => {
          if (res.code == 1) {
            ElMessage.success(res.msg)
            this.closeSuc3()
            this.getList()
          } else {
            this.change_code = ''
            ElMessage.error(res.msg)
          }
        })
        .catch(error => {
          console.error(error);
        });
    },
    closeSuc2() {
      this.sucPop2 = !this.sucPop2;
    },
    testTabClick2(index) {
      console.log(index);
      this.tabIndex2 = index;
    },
    closeSuc2() {
      this.sucPop2 = !this.sucPop2;
    },
    closeSuc3() {
      this.sucPop3 = !this.sucPop3;
      if (!this.sucPop3) {
        this.change_code = '';
      }
    },
  },
};
</script>

<style lang="less" scoped>
.container {
  background: #f6f6f6;
  width: 100vw;
}

.main {
  width: 1200px;
  margin: 20px auto;
}

.mw200 {
  max-width: 200px;
}

.border_b {
  border-bottom: 1px solid #eeeff0;
}

.right_part {
  background: #fdfdfd;
  border-radius: 13px;
  overflow: hidden;
  padding: 25px 20px;

  .add_device {
    width: 16px;
    height: 16px;
    display: block;
    object-fit: contain;
  }

  .imgs1 {
    width: 122px;
    height: 122px;
    display: block;
    object-fit: cover;
  }

  .label {
    padding: 0 9px;
    height: 23px;
    line-height: 23px;
    background: #f0f0f7;
    border-radius: 12px;
  }

  .product_part:last-of-type {
    margin-bottom: 45px;
  }
}

.popup {
  width: 598px;
  background: #ffffff;
  border-radius: 5px;
  padding: 20px 40px 25px 38px;

  .pop_tablist {
    width: 250px;
    text-align: center;
    height: 48px;
    line-height: 48px;
    border-radius: 5px;
    font-size: 15px;
  }

  .pop_active,
  .pop_tablist:hover {
    background: #bba17b;
    color: #fff;
  }

  .pop_unactive {
    background: #f1f2f4;
    color: #101010;
  }

  .pop_tablist:nth-of-type(2n) {
    margin-right: 0;
  }

  .phone_input {
    width: 100%;
    height: 45px;
    line-height: 45px;
    background: #f1f2f4;
    border-radius: 8px;
    padding: 0 18px;
  }

  .num_input {
    width: 78px;
    text-align: center;
    height: 43px;
    line-height: 43px;
    background: #f1f2f4;
    border-radius: 8px;
    font-family: PingFangSC, PingFang SC;
    font-weight: 500;
    font-size: 25px;
    color: #101010;
  }

  .pop_btn1,
  .pop_btn2 {
    width: 125px;
    text-align: center;
    height: 48px;
    line-height: 48px;
    background: #bba17b;
    border-radius: 5px;
  }

  .pop_btn2 {
    line-height: 46px;
    border: 1px solid #cbcbcb;
    background: #fff;
  }
}

.popup2 {
  width: 325px;
  padding: 20px 37px 0 20px;
  background: #ffffff;
  border-radius: 5px;

  .pop_tablist {
    height: 48px;
    line-height: 48px;
    border-radius: 5px;
  }

  .pop_active,
  .pop_tablist:hover {
    background: #bba17b;
    color: #fff;
  }

  .pop_unactive {
    background: #f1f2f4;
    color: #101010;
  }
}

.popup3 {
  width: 325px;
  text-align: center;
  padding: 20px 19px 0 20px;
  background: #ffffff;
  border-radius: 5px;

  .num_input1 {
    width: 200px;
    // width: 43px;
    padding: 0;
    text-align: center;
    height: 43px;
    line-height: 43px;
    background: #f1f2f4;
    border-radius: 8px;
    color: #101010;
  }
}

.pop_confirm {
  width: 250px;
  text-align: center;
  height: 48px;
  line-height: 48px;
  background: #bba17b;
  border-radius: 5px;
  margin: 0 auto 10px;
}
</style>