<template>
  <div class="container bg-white">
    <Header @input="input"></Header>
    <div class="main pr pt68">
      <div class="flex-box mb20">
        <div class="col-10 change flex-1">切换城市</div>
        <div class="search_part flex-box">
          <img src="../assets/static/icon/head_search.png" alt="" />
          <input class="flex-1 tl" placeholder-class="fs16 col-6" v-model="keyword2" placeholder="请输入你要搜索的城市"
            type="text" />
          <div @click="search" class="search_btn cursor">搜索</div>
        </div>
      </div>
      <div class="plr30">
        <!-- <div class="flex-box fs20 col-10 lh20 mb30 fwb">
          <div style="color: #101010;" class="mr30">定位城市</div>
          <div style="color: #101010;">上海</div>
        </div> -->
        <template v-for="(item, index) in list">
          <div @click="choose(index, -1)" class="fs20 col-10 fwb lh20 mb20">
            {{ item.country_name }}
          </div>
          <div class="flex flex-wrap mb15">
            <div @click="choose(index, index2)" v-for="(item2, index2) in item.city" :key="index2"
              class="tablist cursor m-ellipsis unactive">
              {{ item2.city_name }}
            </div>
          </div>
        </template>
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>
<script>
import { useHead } from "@unhead/vue";
import Pagination from "../components/pagination.vue";
import axios from "@/axios";
import { getCurrentInstance } from "vue";
import router from "@/router";
import { ElMessage } from "element-plus";
export default {
  name: "index",

  setup() {
    useHead({
      title: "门店列表",
      meta: [
        { name: "description", content: "This is my page description" },
        { property: "og:title", content: "My Page Title" },
        { property: "og:description", content: "This is my page description" },
        { property: "og:url", content: "https://example.com/my-page" },
        { property: "og:image", content: "https://example.com/my-image.jpg" },
      ],
      link: [{ rel: "canonical", href: "https://example.com/my-page" }],
    });
  },
  components: {
    Pagination,
  },
  data() {
    return {
      tabIndex: 0,
      keyword2: "",
      keyword: "",
      list: [],
    };
  },
  created() {
    this.getList();
  },
  methods: {
    testTabClick(index) {
      console.log(index);
      this.tabIndex = index;
    },
    search() {
      this.keyword = this.keyword2
      this.getList()
    },
    getList() {
      axios
        .post("index/shop_city", {
          keyword: this.keyword,
        })
        .then((res) => {
          this.list = res.data;
        });
    },
    choose(index, index2) {
      if (index2 == -1) {
        var shop_country_id = this.list[index].id;
        var city_name = this.list[index].country_name;
        router.push({
          name: "storelist",
          params: {
            home: 0,
            shop_country_id: shop_country_id,
            shop_city_id: 0,
            city_name: city_name,
          },
        });
      } else {
        var shop_city_id = this.list[index].city[index2].id;
        var city_name = this.list[index].city[index2].city_name;
        router.push({
          name: "storelist",
          params: {
            home: 0,
            shop_country_id: 0,
            shop_city_id: shop_city_id,
            city_name: city_name,
          },
        });
      }
    },
  },
};
</script>

<style lang="less" scoped>
.col-10 {
  color: #101010 !important;
}

.fs16 {
  font-size: 16px;
  color: #666;
}

.change {
  line-height: 86px;
  font-size: 32px;
  color: #101010;
  font-weight: bold;
}

.container {
  background: #fff;
  width: 100vw;
  padding-top: 10px;
}

.main {
  width: calc(100vw - 240px);
  margin: 33px auto 40px;
}

.search_part {
  width: 500px;
  height: 60px;
  line-height: 60px;
  background: #f1f2f4;
  border-radius: 6px;
  padding: 0 0 0 20px;
  margin-left: auto;
  margin-right: 0;
  position: relative;

  .search_btn {
    padding: 0 20px;
    position: relative;
    font-size: 20px;
    font-weight: bold;
    color: #bba17b;
  }


  img {
    widows: 20px;
    height: 20px;
    display: block;
    object-fit: contain;
    margin-right: 5px;
  }

  input {
    width: 75%;
    height: 60px;
    line-height: 60px;
    padding: 0;
  }
}


.unactive,
.active {
  background: #f1f2f4;
  border-radius: 5px;
  font-size: 18px;
  color: #101010;
}

.active {
  background: #bba17b;
  color: #fff;
}

.tablist {
  width: 130px;
  text-align: center;
  height: 60px;
  line-height: 60px;
  margin-right: 14px;
  margin-bottom: 15px;
}

.tablist:nth-of-type(8n) {
  margin-right: 0;
}

.tablist:hover {
  background: #bba17b;
  color: #fff;
}
</style>