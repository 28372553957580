
<template>
  <div class="container pr">
    <Header @input="input"></Header>
    <div class="main flex">
      <div class="part1 mr30">
        <div v-for="(item, index) in 3" :key="index" class="message_part flex cursor">
          <img
            class="icon"
            src="../assets/static/icon/message_icon.png"
            alt=""
          />
          <div class="flex-grow-1">
            <div class="fs14 col10 fwb mb5">系统消息</div>
            <div class="fs14 col85 lh20 mb5 m-ellipsis-l3">
              最大的错误就是停在原地不动，就是不犯错误关键在于总结、反思，错误还得犯。
            </div>
            <div class="flex-box">
              <div class="flex-grow-1 colb fs14 cursor">查看详情</div>
              <div class="fs12 cola">05.11 11:20</div>
            </div>
          </div>
        </div>
      </div>
      <div class="flex-grow-1 right">
        <div class="ptb20 plr30 border_b">
            <div class="fs18 col10 fwb lh24 mb10">系统消息标题系统消息标题系统消息标题系统消息标题</div>
            <div class="fs12 col85 ">
                <span class="mr25">2024.03.26 16:20</span>
                <span class="mr25">1229人已阅读</span>
            </div>
        </div>
        <div class="ptb15 plr30">
            <div class="fs15 col10 fwb mb20 lh18">美与人的可能性</div>
            <div class="fs15 col6 lh24 mb15">我们希望把“美丽”带给每一个人。本着“美丽与人的可能性”为理念，不断超越，实现美的无限可能。A.GLOBAL始终致力于创造更具安全性，更突显性能的高科技美容产品，让更多人的真实绽放自我和美丽。我们认为在开始成为自己时，就是一个人的美真正的开始。</div>
            <div class="fs15 col10 fwb mb20 lh18">MADE IN JANPAN 100%的高品质。</div>
            <div class="fs15 col6 lh24 mb15">A.GLOBAL的美容仪，从企划、设计、制造都是在日本岐阜县的总公司工厂进行的。每一台仪器，都由技师手工操作，仔细检测后出厂。生产高性能，耐用且性能强大的美容仪器是A.GLOBAL是自豪的荣耀。</div>

        </div>
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>
          <script>
import { useHead } from "@unhead/vue";
export default {
  name: "productDetail",
  setup() {
    useHead({
      title: "消息详情",
      meta: [
        { name: "description", content: "This is my page description" },
        { property: "og:title", content: "My Page Title" },
        { property: "og:description", content: "This is my page description" },
        { property: "og:url", content: "https://example.com/my-page" },
        { property: "og:image", content: "https://example.com/my-image.jpg" },
      ],
      link: [{ rel: "canonical", href: "https://example.com/my-page" }],
    });
  },

  data() {
    return {};
  },
  created() {},
  methods: {},
};
</script>
<style lang="less" scoped>
.container {
  background: #f7f7f7;
  width: 100vw;
  overflow-x: hidden;
  padding-top: 88px;
}
.main {
  width: 1200px;
  margin: 0px auto 20px;
  min-height: 935px;
}
.part1 {
  width: 375px;
  background: #fdfdfd;
  border-radius: 13px;
  .message_part {
    border-bottom: 5px solid #f1f2f4;
    padding: 30px 20px 20px 30px;
    .icon {
      width: 45px;
      height: 45px;
      display: block;
      object-fit: cover;
      margin-right: 15px;
    }
  }
}
.right {
  background: #fdfdfd;
  border-radius: 13px;
}
.border_b{border-bottom: 1px solid #EEEFF0;}

</style>
         