<template>
  <div class="container pr pt68">
    <Header @input="input"></Header>
    <div class="main">
      <block v-if="type != 'acare'" @click="toAddress">
        <div class="fs28 col10 fwb lh86 mb10">收货地址</div>
        <div v-if="data.address" class="add_part mb40 cursor plr40" style="padding-top: 3cap;">
          <div class="tl fs30 col6 col-10  mb10 m-ellipsis">
            {{ data.address.address }}
          </div>
          <div class="tl fs30 col6 col-10  mb10 m-ellipsis">
            {{data.address.Area.province+data.address.Area.city+data.address.Area.region}}
          </div>
          <div class="col-89 fs18">
            <text class="mr10">{{data.address.name}}</text>
            <text>{{data.address.mobile}}</text>
          </div>
        </div>
        <div v-else class="add_part mb40 cursor">
          <img
            class="icon"
            src="../assets/static/icon/add_address.png"
            alt=""
          />
          <div class="tc fs16 col6 lh22">请选择收货地址</div>
        </div>
      </block>

      <div class="fs28 col10 fwb lh86 mb20">您购买的商品</div>
      <div
        v-for="(item, index) in data.deliveryGoodsList"
        :key="index"
        class="ptb30 flex part"
      >
        <img class="img" :src="item.image" alt="" />
        <div class="flex-grow-1">
          <div class="fs24 col10 fwb lh44 m-ellipsis">
            {{ item.name }}
          </div>
          <div class="fs24 col10 fwb lh44 mb10">
            RMB {{ item.is_acare == 1 ? item.price : item.goods_price }}
          </div>
          <div class="flex-box fs20">
            <div class="flex-grow-1 col85">
              {{
                item.is_acare == 1
                  ? "服务期限：" + item.day + "天"
                  : item.sku_title
              }}
              * {{ item.goods_num }}
            </div>
          </div>
        </div>
      </div>
      <!-- <div class="ptb30 border_t flex part">
        <img class="img" src="../assets/static/images/device1.png" alt="" />
        <div class="flex-grow-1">
          <div class="fs24 col10 fwb lh44 m-ellipsis">
            DrArrivo 家用脸面部提拉紧致微电流 宙斯二代美容仪
          </div>
          <div class="fs24 col10 fwb lh44 mb10">RMB 7,999</div>
          <div class="flex-box fs20 mb30">
            <div class="flex-grow-1 col85">黑色*1</div>
          </div>
          <div class="border_t pt30">
            <div class="fs24 col10 fwb lh44">A CARE服务计划</div>
            <div class="flex-box lh44 mb8">
              <div class="flex-grow-1 fs24 col10 fwb lh44">RMB 1,999</div>
            </div>
            <div class="fs20 col85 lh28">
              ·
              硬件维修：免费维修屏幕（仅宙斯III代），导头芯片，机身各1次，购买服务后，系统自动生成各项免费维修服务的优惠券，用户在申请维保时，售后生成维修服务订单，用户可选择对应的维修项目优惠抵扣券进行抵扣，抵扣后即实际支付金额为0。
              <br />
              ·
              以旧换新：宙斯，宙斯II代，宙斯III代美容仪，赫露丝美容仪，魅影美容仪，幻影美容仪，缪斯美眼仪，缪斯美容仪有最高折算参考价，按照不同版本和实际情况，送检后得出实际折算价（参考表格）
              <br />· 服务期限：购买服务计划起2年，每份服务，保障期限为2年；
              <br />·
              2年内未触发维保售后的计划购买者，按剩余月份比例退款至零钱钱包，可申请退款或以旧换新购物时进行抵扣；
            </div>
          </div>
        </div>
      </div> -->
      <div class="border_t pt20 pl223">
        <div class="flex-box mb10">
          <div class="fs18 col85 lh24">小计</div>
          <div class="flex-grow-1 fs18 col10 fwb lh44 tr">
            RMB {{ data.total_price }}
          </div>
        </div>
        <div @click="toCoupon" class="flex-box mb10">
          <div class="fs18 col85 lh24">优惠券</div>
          <div
            v-if="data.coupon_price"
            class="flex-grow-1 fs18 col10 fwb lh44 tr"
          >
            RMB {{ data.coupon_price }}
          </div>
          <div v-else class="flex-grow-1 fs18 col10 fwb lh44 tr">未选择</div>
        </div>
        <!-- <div class="flex-box mb30">
          <div class="fs18 col85 lh24">运费</div>
          <div class="flex-grow-1 fs18 col10 fwb lh44 tr">RMB 24</div>
        </div> -->
        <div class="border_t pt30 flex-box pb37">
          <div class="fs24 col10 fwb lh44">总计</div>
          <div class="flex-grow-1 tr fs24 col10 fwb lh44">
            RMB {{ data.pay_price }}
          </div>
        </div>
      </div>
      <div class="flex-box mt40 mb60">
        <div class="fs28 col10 fwb lh86">选择支付方式</div>
        <div
          @click="set_pay(1)"
          class="pay_part cursor ml45 flex-box flex-center" :class="pay_type==1?'pay_part_hover':''"
        >
          <img class="icon" src="../assets/static/icon/pay_icon1.png" alt="" />
          <div class="lh28 fs20 col3 fwb">微信支付</div>
        </div>
        <div
          @click="set_pay(2)"
          class="pay_part cursor ml45 flex-box flex-center" :class="pay_type==2?'pay_part_hover':''"
        >
          <img class="icon" src="../assets/static/icon/pay_icon2.png" alt="" />
          <div class="lh28 fs20 col3 fwb">支付宝支付</div>
        </div>
      </div>
      <div class="border_t pt40 flex-box flex-end">
        <div class="fs24 col10 fwb lh44 mr45">
          需支付: RMB {{ data.pay_price }}
        </div>
        <div @click="submit" class="btn_buy2 colf tc fs20 cursor">立即支付</div>
      </div>
    </div>
    <Popup
      :visible="sucPop"
      :confirmText="false"
      :cancleText="false"
      @close="closeSuc"
    >
      <div class="popup">
        <img
          @click="closeSuc"
          class="close_icon mb10 cursor"
          src="../assets/static/icon/close_pop.png"
          alt=""
        />
        <div class="fs24 col3 fwb lh34 mb30">订单信息</div>
        <div class="fs14 col3 lh20 mb50">
          <span>订单号: {{ order_no }}</span
          ><span class="ml145">支付金额: ¥{{ pay_price }}</span>
        </div>
        <div class="fs24 col3 fwb lh34 mb40">支付订单</div>
        <img class="code" src="../assets/static/icon/code_icon.png" alt="" />
        <div class="tc fs16 col3 lh22 mb50">
          打开手机{{ pay_type == 1 ? "微信" : "支付宝" }}扫码支付
        </div>
        <div @click="is_pay" class="pop_btn colf tc fs18 cursor">我已支付</div>
      </div>
    </Popup>
    <Footer></Footer>
  </div>
</template>
<script>
var btn;
import { useHead } from "@unhead/vue";
import Popup from "../components/popup.vue";
import { getCurrentInstance } from "vue";
import axios from "@/axios";
import router from "@/router";
import { ElMessage, ElMessageBox } from "element-plus";
export default {
  name: "shoppingCart",
  setup() {
    useHead({
      title: "购物车",
      meta: [
        { name: "description", content: "This is my page description" },
        { property: "og:title", content: "My Page Title" },
        { property: "og:description", content: "This is my page description" },
        { property: "og:url", content: "https://example.com/my-page" },
        { property: "og:image", content: "https://example.com/my-image.jpg" },
      ],
      link: [{ rel: "canonical", href: "https://example.com/my-page" }],
    });
  },
  components: {
    Popup,
  },
  data() {
    return {
      sucPop: false,
      oss: getCurrentInstance().appContext.config.globalProperties.$oss,
      address_id: 0,
      user_coupon_id: "",
      data: "",
      device_id: "",
      acare_goods_id: 0,
      acare_device_id: 0,
      type: "",
      money_use: false,
      order_no: "",
      pay_price: "",
      pay_type: 1,
    };
  },
  created() {
    btn = true;
    this.device_id = this.$route.params.device_id || "";
    this.user_coupon_id = this.$route.params.coupon_id || "";
    this.ids = this.$route.params.ids || "";
    this.type = this.$route.params.type || "detail";
    this.goods_sku_id = this.$route.params.goods_sku_id || 0;
    this.acare_goods_id = this.$route.params.acare_goods_id || 0;
    this.acare_device_id = this.$route.params.acare_device_id || 0;
    this.goods_num = this.$route.params.goods_num || 0;

    this.sure();
  },
  methods: {
    set_pay(type) {
      this.pay_type = type;
    },
    toAddress() {
      router.push({
        name: "myAddress",
        params: {
          order: 1,
          device_id: 0,
          repair_id: 0,
          order_goods_id: 0,
        },
      });
    },
    toCoupon() {
      if (parseFloat(this.data.total_price) > 0) {
        var arr = [];
        for (var i in this.data.available_coupon) {
          arr.push(this.data.available_coupon[i].user_coupon_id);
        }
        var ids = arr.join(",");
        router.push({
          name: "myCoupons",
          params: {
            order: 1,
            pay_price: parseFloat(this.data.total_price),
            user_coupon_ids: ids,
          },
        });
      }
    },
    sure() {
      axios
        .post("xilushop/order/sure", {
          money_use: this.money_use ? 1 : 0,
          ids: this.ids,
          type: this.type,
          goods_num: this.goods_num,
          goods_sku_id: this.goods_sku_id,
          address_id: this.address_id,
          user_coupon_id: this.user_coupon_id,
          acare_goods_id: this.acare_goods_id,
          acare_device_id: this.acare_device_id,
        })
        .then((res) => {
          if (res.code == 1) {
            res.data.user_money = parseFloat(res.data.user_money);
            for (var i in res.data.deliveryGoodsList) {
              res.data.deliveryGoodsList[i].acare_price = parseFloat(
                res.data.deliveryGoodsList[i].acare_price
              );
              res.data.deliveryGoodsList[i].goods_price = parseFloat(
                res.data.deliveryGoodsList[i].goods_price
              );
            }
            this.data = res.data;
            if (res.data.address) {
              this.address_id = res.data.address.id;
            }
            if (res.data.use_coupon) {
              this.user_coupon_id = res.data.use_coupon.user_coupon_id;
            }
          } else {
            ElMessage.error(res.msg);
          }
        })
        .catch((error) => {
          console.error(error);
        });
    },
    submit() {
      if (this.type != "acare" && !this.address_id) {
        ElMessage.error("请选择收货地址");
        return false;
      }
      if (!btn) {
        return false;
      }
      btn = false;

      axios
        .post("xilushop/order/create_order", {
          method: "scan",
          pay_type: this.pay_type,
          money_use: this.money_use ? 1 : 0,
          address_id: this.address_id,
          ids: this.ids,
          type: this.type,
          goods_num: this.goods_num,
          goods_sku_id: this.goods_sku_id,
          user_coupon_id: this.user_coupon_id,
          acare_goods_id: this.acare_goods_id,
          acare_device_id: this.acare_device_id,
        })
        .then((res) => {
          if (res.code == 1) {
            var tab = this.type == "acare" ? 1 : 0;
            if (res.data == "ok") {
              ElMessage.success("支付成功");
              setTimeout((res) => {
                router.replace({
                  name: "myPage",
                  params: { tab: tab },
                });
              }, 1500);
              return false;
            } else {
              var wxconfig = res.data.wxconfig;
              if (wxconfig == "ok") {
                ElMessage.success("支付成功");
                setTimeout((res) => {
                  router.replace({
                    name: "myPage",
                    params: { tab: tab },
                  });
                }, 1500);
                return false;
              }
            }
          } else {
            ElMessage.error(res.msg);
          }
        })
        .catch((error) => {
          console.error(error);
        });
    },
    bindPickerChange: function (e) {
      console.log("picker发送选择改变，携带值为", e.detail.value);
      this.index = e.detail.value;
    },
    testTabClick(index) {
      console.log(index);
      this.tabIndex = index;
    },
    closeSuc() {
      ElMessageBox.confirm("确认离开支付页面？", "提示", {
        confirmButtonText: "确定离开",
        cancelButtonText: "我再想想",
        type: "warning",
      })
        .then(() => {
          // 确定按钮被点击的回调
          console.log("确定按钮被点击");
          router.replace({
            name: "myPage",
            params: {},
          });
        })
        .catch(() => {
          // 取消按钮被点击的回调
          console.log("取消按钮被点击");
        });
    },
    is_pay() {
      router.replace({
        name: "myPage",
        params: {},
      });
    },
  },
};
</script>
<style lang="less" scoped>
.container {
  background: #fff;
  width: 100vw;
  overflow-x: hidden;
}

.main {
  width: 960px;
  margin: 33px auto 100px;
}

.add_part {
  width: 380px;
  height: 244px;
  background: #f5f5f5;
  border-radius: 17px;
  padding-top: 67px;

  .icon {
    width: 88px;
    height: 88px;
    display: block;
    object-fit: cover;
    margin: 0 auto 10px;
  }
}

.border_t {
  border-top: 1px solid #e3e3e3;
}

.img {
  width: 173px;
  height: 173px;
  display: block;
  object-fit: cover;
  margin-right: 53px;
}

.mb8 {
  margin-bottom: 8px;
}

.pl223 {
  padding-left: 223px;
}

.pb37 {
  padding-bottom: 37px;
}

.btn_buy2 {
  width: 236px;
  text-align: center;
  height: 60px;
  line-height: 60px;
  background: #bba17b;
  border-radius: 5px;
}

.pay_part {
  width: 286px;
  height: 75px;
  border-radius: 10px;
  border: 1px solid #979797;

  .icon {
    width: 32px;
    height: 32px;
    display: block;
    object-fit: contain;
    margin-right: 7px;
  }
}

.pay_part_hover{
  border: 2px solid #bba17b;
}

.popup {
  width: 1210px;
  background: #ffffff;
  box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.11);
  border-radius: 6px;
  padding: 16px 16px 62px 52px;

  .close_icon {
    width: 24px;
    height: 24px;
    display: block;
    object-fit: contain;
    margin-left: auto;
    margin-right: 0;
  }

  .code {
    width: 212px;
    height: 212px;
    display: block;
    object-fit: cover;
    margin: 0 auto 10px;
  }

  .pop_btn {
    width: 220px;
    text-align: center;
    height: 55px;
    line-height: 55px;
    background: #bba17b;
    border-radius: 5px;
    margin: 0 auto;
  }

  .ml145 {
    margin-left: 145px;
  }
}
</style>