<template>
  <div class="container">
    <Header @input="input"></Header>
    <div class="main pr pt68 flex">
      <Mine></Mine>
      <div class="right_part flex-grow-1 ml20">
        <div class="ye_part flex mb20">
          <div class="flex-grow-1">
            <div class="col10 lh56">
              <span class="fs14">¥</span><span class="fs32 fwb">{{ userInfo.money }}</span>
            </div>
            <div class="fs18 col85">余额</div>
          </div>
          <!-- <div class="btn_pay colf tc fs18 cursor">立即提现</div> -->
        </div>
        <div class="sz_part">
          <div class="fs20 col10 lh30 mb15">收支明细</div>
          <div v-for="(item, index) in list" :key="index" class="ptb20 border_b flex-box">
            <div class="flex-grow-1">
              <div class="fs16 col16 lh16 mb15 m-ellipsis">{{ item.memo }}</div>
              <div class="fs12 col85 lh12 m-ellipsis">{{ item.createtime }}</div>
            </div>
            <div v-if="item.money > 0" class="add_num fs18">+{{ item.money }}</div>
            <div v-if="item.money < 0" class="sub_num fs18">{{ item.money }}</div>
          </div>
        </div>
      </div>
    </div>
    <!-- 提现弹窗-->
    <Popup :visible="sucPop" :confirmText="false" :cancleText="false" @close="closeSuc">
      <div class="popup">
        <img class="close cursor" src="../assets/static/icon/close_pop.png" alt="" />
        <div class="fs16 col10 lh16 mb15">提现金额</div>
        <div class="col10 lh28 mb35">
          <span class="fs12">¥</span><span class="fs28 fwb">1280</span>
        </div>
        <div class="fs16 col10 lh18 mb20">提现信息</div>
        <div class="flex flex-wrap mb15">
          <div class="flex-box pop_input mr25 mb20">
            <div class="fs14 col6 pl20">姓名</div>
            <input class="flex-grow-1 fs14 col10 tr pr20" placeholder="请输入" type="text" />
          </div>
          <div class="flex-box pop_input mr25 mb20">
            <div class="fs14 col6 pl20">联系电话</div>
            <input class="flex-grow-1 fs14 col10 tr pr20" value="17506904082" type="number" />
          </div>
          <div class="flex-box pop_input mr25 mb20">
            <div class="fs14 col6 pl20">开户银行</div>
            <input class="flex-grow-1 fs14 col10 tr pr20" value="中国银行徐汇支行" type="text" />
          </div>
          <div class="flex-box pop_input mr25 mb20">
            <div class="fs14 col6 pl20">卡号</div>
            <input class="flex-grow-1 fs14 col10 tr pr20" value="XXXXXXXXXXXXXXXXXX" type="text" />
          </div>
        </div>
        <div class="fs16 col10 lh18 mb15">提现说明</div>
        <div class="fs16 col85 lh18 mb10">1.提现说明后台编辑</div>
        <div class="fs16 col85 lh18 mb10">
          2.提现说明后台编辑提现说明后台编辑
        </div>
        <div class="pop_btn colf tc fs18 cursor">确定提现</div>
      </div>
    </Popup>
    <Footer></Footer>
  </div>
</template>
<script>
import { useHead } from "@unhead/vue";
import Popup from "../components/popup.vue";
import { getCurrentInstance } from "vue";
import axios from "@/axios";
import router from "@/router";
import { ElMessage, ElMessageBox } from "element-plus";
var page;
export default {
  name: "index",

  setup() {
    useHead({
      title: "使用报告",
      meta: [
        { name: "description", content: "This is my page description" },
        { property: "og:title", content: "My Page Title" },
        { property: "og:description", content: "This is my page description" },
        { property: "og:url", content: "https://example.com/my-page" },
        { property: "og:image", content: "https://example.com/my-image.jpg" },
      ],
      link: [{ rel: "canonical", href: "https://example.com/my-page" }],
    });
  },
  components: {
    Popup,
  },
  data() {
    return {
      sucPop: false,
      userInfo: '',
      list: []
    };
  },
  created() {
    page = 1;
    this.getUser()
    this.getList()
  },
  methods: {
    getUser() {
      axios.post('xilushop/user/info', {}).then(res => {
        if (res.data) {
          this.userInfo = res.data;
        } else {
          this.userInfo = ''
        }
      })
    },
    getList() {
      axios.post('xilushop/user/my_commission', {
        page: 1,
        pagesize: 9999
      }).then(res => {
        var data = res.data.data
        if (page == 1) {
          this.list = data
        } else {
          this.list = this.list.concat(data)
        }
      })
    },
    closeSuc() {
      this.sucPop = !this.sucPop;
    },
  },
};
</script>

<style lang="less" scoped>
.container {
  background: #f6f6f6;
  width: 100vw;
}

.main {
  width: 1200px;
  margin: 20px auto;
}

.mw200 {
  max-width: 200px;
}

.border_b {
  border-bottom: 1px solid #eeeff0;
}

.right_part {
  .ye_part {
    width: 100%;
    background: #fdfdfd;
    border-radius: 13px;
    padding: 20px 40px 30px 40px;

    .btn_pay {
      width: 142px;
      text-align: center;
      height: 55px;
      line-height: 55px;
      background: #bba17b;
      border-radius: 5px;
      margin-top: 20px;
    }
  }

  .sz_part {
    width: 100%;
    background: #fdfdfd;
    border-radius: 13px;
    padding: 20px 40px 50px 20px;
  }

  .add_num {
    color: #cc9966;
  }

  .sub_num {
    color: #101010;
  }
}

.popup {
  width: 757px;
  padding: 17px 22px 35px 35px;
  background: #ffffff;
  border-radius: 12px;

  .close {
    width: 24px;
    height: 24px;
    display: block;
    object-fit: contain;
    margin-left: auto;
    margin-right: 0;
  }

  .pop_input {
    width: 335px;
    height: 50px;
    line-height: 50px;
    background: #f5f7f8;
    border-radius: 8px;
  }

  .pop_input:nth-of-type(2n) {
    margin-right: 0;
  }

  .pop_btn {
    width: 168px;
    text-align: center;
    height: 48px;
    line-height: 48px;
    background: #bba17b;
    border-radius: 5px;
    margin: 38px auto 0;
  }
}
</style>