<template>
  <div class="container">
    <Header @input="input"></Header>
    <div class="main pr pt68 flex">
      <Mine></Mine>
      <div class="right_part flex-grow-1 ml20">
        <div class="flex-box mb20">
          <div class="tablist mr40 fs20 fwb col3 lh28">我的售后</div>
          <!-- <div class="selecct1 flex-box">
            <div class="flex-1 flex-grow-1 m-ellipsis fs14 col3 fwb">
              全部订单
            </div>
            <img
              class="arrow"
              src="../assets/static/icon/arrow_down.png"
              alt=""
            />
          </div> -->
          <!-- <div class="selecct1 flex-box cursor pr">
            <el-select class="select"
              style="position: absolute;top: 0;left: 0;width: 100%;height: 100%;background-color: transparent;border: none;box-shadow: none"
              v-model="region" placeholder="请选择">
              <el-option label="全部订单" value="shanghai"></el-option>
              <el-option label="订单1" value="beijing"></el-option>
            </el-select>
          </div> -->
        </div>
        <div v-for="(item, index) in list" :key="index" class="product_part1">
          <div class="product_top flex-box pl20 fs14 col3">
            <div class="tab1">设备</div>
            <div class="tab2 tc">编号</div>
            <div class="tab3 tc">下单时间</div>
            <div class="tab4 tc">状态</div>
            <div class="flex-grow-1 tc">操作</div>
          </div>
          <div class="flex-box pt13 pb20 pl20 fs14 col3 lh20">
            <div class="tab1 flex-box">
              <img class="imgs1" :src="oss + item.device.goods.images + w500" alt="" />
              <div class="flex-grow-1 fs14 col3">
                {{ item.device.goods.name }}
              </div>
            </div>
            <div class="tab2 tc fs14 col3">{{ item.device.goods_no }}</div>
            <div class="tab3 tc fs14 col3">{{ item.createtime_text }}</div>
            <div class="tab4 tc">
              <div class="zt_btn1 pointer cursor ">{{ item.repair_status_text }}</div>
            </div>
            <div class="flex-grow-1 tc">
              <!-- <div class="mb10 fs14 colb lh20 pointer plans cursor plans">取消订单</div> -->
              <!-- <div class="mb10 fs14 colb lh20 pointer plans cursor plans">立即支付</div> -->
              <div @click="toDetail(item.id)" class="lh20 fs14 colb tc pointer plans cursor plans">查看详情</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>
<script>
import { useHead } from "@unhead/vue";
import { ElMessage, ElMessageBox } from "element-plus";
import axios from "@/axios";
import router from "@/router";
import { getCurrentInstance } from "vue";
var page;
export default {
  name: "index",

  setup() {
    useHead({
      title: "我的售后",
      meta: [
        { name: "description", content: "This is my page description" },
        { property: "og:title", content: "My Page Title" },
        { property: "og:description", content: "This is my page description" },
        { property: "og:url", content: "https://example.com/my-page" },
        { property: "og:image", content: "https://example.com/my-image.jpg" },
      ],
      link: [{ rel: "canonical", href: "https://example.com/my-page" }],
    });
  },
  data() {
    return {
      oss: getCurrentInstance().appContext.config.globalProperties.$oss,
      w1000: getCurrentInstance().appContext.config.globalProperties.$w1000,
      w500: getCurrentInstance().appContext.config.globalProperties.$w500,
      tablist: ["商品订单", "A CARE"],
      tabIndex: 0,
      list: []
    };
  },
  created() {
    page = 1;
    this.getList()
  },
  methods: {
    getList() {
      axios.post('ag/repair_list', {
        page: page,
        pagesize: 999,
        // tab: parseInt(this.tabIndex) + 1
      }).then(res => {
        var data = res.data.data
        for (var i in data) {
          if (data[i]['freight_status'] == 4) {
            data[i]['repair_status_text'] = '已寄回';
          } else if (data[i]['repair_status'] == 2) {
            data[i]['repair_status_text'] = '维修完成';
          } else if (data[i]['repair_status'] == 1) {
            data[i]['repair_status_text'] = '已受理检测';
          } else if (data[i]['freight_status'] == 2) {
            data[i]['repair_status_text'] = '运输中';
          } else if (data[i]['freight_status'] == 1) {
            data[i]['repair_status_text'] = '待寄件';
          } else if (data[i]['check_status'] == 2) {
            data[i]['repair_status_text'] = '审核拒绝';
          } else if (data[i]['check_status'] == 0) {
            data[i]['repair_status_text'] = '审核中';
          }
        }
        if (page == 1) {
          this.list = data
        } else {
          this.list = this.list.concat(data)
        }
      })
    },
    testTabClick(index) {
      console.log(index);
      this.tabIndex = index;
    },
    toDetail(id) {
      router.push({ name: 'myAfterSalesDetail', params: { id: id } });
    },
  },
};
</script>

<style lang="less" scoped>
.container {
  background: #f6f6f6;
  width: 100vw;
}

.main {
  width: 1200px;
  margin: 20px auto;
}

.mw200 {
  max-width: 200px;
}

.border_b {
  border-bottom: 1px solid #eeeff0;
}

.right_part {
  background: #fdfdfd;
  border-radius: 13px;
  overflow: hidden;
  padding: 25px 20px;

  .selecct1 {
    width: 169px;
    padding: 0 15px 0 20px;
    margin-left: auto;
    margin-right: 0;
    height: 46px;
    line-height: 46px;
    border-radius: 6px;
    border: 1px solid #e7e7e7;

    .arrow {
      width: 10px;
      height: 7px;
      display: block;
      object-fit: contain;
    }
  }

  .product_part1 {
    border-radius: 4px;
    border: 1px solid #e2e2e2;
    margin-bottom: 20px;
    word-wrap: break-word;
    word-break: normal;

    .pt13 {
      padding-top: 13px;
    }

    .product_top {
      height: 44px;
      line-height: 44px;
      background: #f3f3f3;
      border-radius: 4px;
    }

    .tab1 {
      width: 226px;

      .imgs1 {
        width: 85px;
        height: 85px;
        display: block;
        object-fit: cover;
        overflow: hidden;
        margin-right: 17px;
      }
    }

    .tab2 {
      width: 79px;
    }

    .tab3 {
      width: 126px;
      margin-right: 27px;
    }

    .tab4 {
      width: 82px;
      margin-right: 20px;

      .zt_btn1,
      .zt_btn2,
      .zt_btn3 {
        width: 69px;
        text-align: center;
        height: 26px;
        line-height: 26px;
        background: #0c0c0c;
        border-radius: 5px;
        font-size: 12px;
        color: #ffffff;
        margin: 0 auto;
      }
    }

    .tab5 {
      width: 69px;
      margin-right: 26px;
    }
  }
}
</style>