<template>
  <div class="container">
    <Header @input="input"></Header>
    <div class="main pr pt68 flex">
      <Mine></Mine>
      <div class="right_part flex-grow-1 ml20">
        <div class="lh28 fs20 fwb mt5 mb10">
          <span class="col6">我的设备/</span><span class="col3">使用报告</span>
        </div>
        <div
          v-for="(item, index) in 11"
          :key="index"
          class="use_part ptb20 pl15 border_b flex cursor"
        >
          <img class="img" src="../assets/static/images/device1.png" alt="" />
          <div class="flex-grow-1">
            <div class="fs14 col10 lh14 m-ellipsis mb15">周护理模式</div>
            <div class="lh14 flex">
              <span class="fs14 col85 mr10">使用频次</span>
              <span class="fs14 col10 flex-grow-1">每天一次</span>
              <span class="fs14 col85 mr10">使用时长</span>
              <span class="fs14 col10">60s</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Footer></Footer>
  </div>
</template> <script>
import { useHead } from "@unhead/vue";
export default {
  name: "index",

  setup() {
    useHead({
      title: "使用报告",
      meta: [
        { name: "description", content: "This is my page description" },
        { property: "og:title", content: "My Page Title" },
        { property: "og:description", content: "This is my page description" },
        { property: "og:url", content: "https://example.com/my-page" },
        { property: "og:image", content: "https://example.com/my-image.jpg" },
      ],
      link: [{ rel: "canonical", href: "https://example.com/my-page" }],
    });
  },
  data() {
    return {};
  },

  methods: {},
};
</script>
      
      <style lang="less" scoped>
.container {
  background: #f6f6f6;
  width: 100vw;
}
.main {
  width: 1200px;
  margin: 20px auto;
}
.mw200 {
  max-width: 200px;
}
.border_b {
  border-bottom: 1px solid #eeeff0;
}

.right_part {
  background: #fdfdfd;
  border-radius: 13px;
  overflow: hidden;
  padding: 30px;
  .use_part:last-of-type {
    margin-bottom: 10px;
  }
  .use_part {
    .img {
      width: 43px;
      height: 43px;
      display: block;
      object-fit: contain;
      margin-right: 15px;
    }
  }
}
</style>
                
      
        
        