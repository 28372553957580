<template>
  <div class="container">
    <Header @input="input"></Header>
    <div class="main pr pt68 flex">
      <Mine></Mine>
      <div class="right_part flex-grow-1 ml20">
        <div class="flex-box mb20">
          <div class="tablist mr40 fs20 fwb col3 lh28 cursor" v-for="(item, index) in tablist" :key="index"
            @click="testTabClick(index)" :class="tabIndex == index ? 'active' : 'unactive op5'">
            {{ item }}
          </div>
          <div class="selecct1 flex-box cursor pr">
            <!--
                        <img
                          class="arrow"
                          src="../assets/static/icon/arrow_down.png"
                          alt=""
                        /> -->
            <el-select class="select"
              style="position: absolute;top: 0;left: 0;width: 100%;height: 100%;background-color: transparent;border: none;box-shadow: none"
              v-model="tabIndex2" @change="tab" placeholder="请选择">
              <el-option v-for="(item, index) in tablist2" :key="index" :label="item" :value="index"></el-option>
            </el-select>
          </div>

        </div>
        <div v-if="tabIndex == 0" v-for="(item, index) in list" :key="index" class="product_part1">
          <div class="product_top flex-box pl20 fs14 col3">
            <div class="tab1">商品</div>
            <div class="tab2 tc">规格</div>
            <div class="tab3 tc">下单时间</div>
            <div class="tab4 tc">状态</div>
            <div class="tab5 tl">订单金额</div>
            <div class="flex-grow-1 tc">操作</div>
          </div>
          <div v-for="(item2, index2) in item.order_goods" class="flex-box pt13 pb20 pl20 fs14 col3 lh20">
            <div class="tab1 flex-box">
              <img class="imgs1" :src="item2.image + w500" alt="" />
              <div class="flex-grow-1 pr5">
                {{ item2.goods_name }}
              </div>
            </div>
            <div class="tab2 tc">{{ item2.sku_title }} * {{ item2.total_num }}</div>
            <div class="tab3 tc">
              <block v-if="index2 == 0">{{ item.createtime_text }}</block>
            </div>
            <div class="tab4 tc">
              <div v-if="index2 == 0" class="zt_btn1 pointer cursor">{{ item.state_text }}</div>
              <!-- <div class="fs12 col9 lh18 mt5">
                2小时23分钟后 自动取消订单
              </div> -->
            </div>
            <div class="tab5 tl">
              <block v-if="index2 == 0">¥{{ item.pay_price }}</block>
            </div>
            <div class="flex-grow-1 tc">
              <block v-if="index2 == 0">
                <block v-if="item.state == '0'">
                  <!-- <div @click.stop="cancel(item.id)" class="mb10 fs14 colb lh20 pointer plans cursor">
                    取消订单
                  </div>
                  <div @click.stop="pay(item.id)" class="mb10 fs14 colb lh20 pointer plans cursor">
                    立即支付
                  </div> -->
                </block>
                <div @click.stop="detail(item.id)" class="lh20 fs14 colb tc pointer plans cursor">查看详情</div>
              </block>
            </div>
          </div>
        </div>
        <div v-if="tabIndex == 1" v-for="(item, index) in list2" :key="index" class="product_part1">
          <div class="product_top flex-box pl20 fs14 col3">
            <div class="tab1">商品</div>
            <div class="tab2 tc">规格</div>
            <div class="tab3 tc">下单时间</div>
            <div class="tab4 tc">状态</div>
            <div class="tab5 tl">订单金额</div>
            <div class="flex-grow-1 tc">操作</div>
          </div>
          <div v-for="(item2, index2) in item.order_goods" class="flex-box pt13 pb20 pl20 fs14 col3 lh20">
            <div class="tab1 flex-box">
              <img class="imgs1" :src="item2.image" alt="" />
              <div class="flex-grow-1 pr5">
                {{ item2.goods_name }}
              </div>
            </div>
            <div class="tab2 tc">{{ item2.sku_title }} * {{ item2.total_num }}</div>
            <div class="tab3 tc">
              <block v-if="index2 == 0">{{ item.createtime_text }}</block>
            </div>
            <div class="tab4 tc">
              <div v-if="index2 == 0" class="zt_btn1 pointer cursor">{{ item.state_text }}</div>
              <!-- <div class="fs12 col9 lh18 mt5">
                2小时23分钟后 自动取消订单
              </div> -->
            </div>
            <div class="tab5 tl">
              <block v-if="index2 == 0">¥{{ item.pay_price }}</block>
            </div>
            <div class="flex-grow-1 tc">
              <block v-if="index2 == 0">
                <block v-if="item.state == '0'">
                  <!-- <div @click.stop="cancel(item.id)" class="mb10 fs14 colb lh20 pointer plans cursor">
                    取消订单
                  </div>
                  <div @click.stop="pay(item.id)" class="mb10 fs14 colb lh20 pointer plans cursor">
                    立即支付
                  </div> -->
                </block>
                <div @click.stop="detail2(item.id)" class="lh20 fs14 colb tc pointer plans cursor">查看详情</div>
              </block>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>
<script>
import { useHead } from "@unhead/vue";
import axios from "@/axios";
import { getCurrentInstance } from "vue";
import router from "@/router";
import { ElMessage, ElMessageBox } from "element-plus";
var page;
export default {
  name: "index",

  setup() {
    useHead({
      title: "个人中心",
      meta: [
        { name: "description", content: "This is my page description" },
        { property: "og:title", content: "My Page Title" },
        { property: "og:description", content: "This is my page description" },
        { property: "og:url", content: "https://example.com/my-page" },
        { property: "og:image", content: "https://example.com/my-image.jpg" },
      ],
      link: [{ rel: "canonical", href: "https://example.com/my-page" }],
    });
  },
  data() {
    return {
      oss: getCurrentInstance().appContext.config.globalProperties.$oss,
      w1000: getCurrentInstance().appContext.config.globalProperties.$w1000,
      w500: getCurrentInstance().appContext.config.globalProperties.$w500,
      tablist: ["商品订单", "A CARE"],
      tabIndex: 0,
      tablist2: ["全部", "待支付", "待发货", "待收货", "已完成"],
      tabIndex2: 0,
      userInfo: '',
      list: [],
      list2: [],
    };
  },
  created() {
    page = 1;
    this.tabIndex = this.$route.params.tab
    if (this.tabIndex == 0) {
      this.getList()
    } else if (this.tabIndex == 1) {
      this.getList2()
    }
  },
  methods: {
    tab(e) {
      this.tabIndex2 = e
      page = 1;
      if (this.tabIndex == 0) {
        this.getList()
      } else if (this.tabIndex == 1) {
        this.getList2()
      }
    },
    getList() {
      if (this.tabIndex2 == 0) {
        var state = 9999
      } else if (this.tabIndex2 == 4) {
        var state = 4
      } else {
        var state = this.tabIndex2 - 1
      }
      axios.post('xilushop/order/lists', {
        page: page,
        pagesize: 999,
        state: state
      })
        .then(res => {
          var data = res.data.data
          if (page == 1) {
            this.list = data
          } else {
            this.list = this.list.concat(data)
          }
        })
        .catch(error => {
          console.error(error);
        });
    },
    getList2() {
      if (this.tabIndex2 == 0) {
        var state = 9999
      } else if (this.tabIndex2 == 1) {
        var state = 0
      } else if (this.tabIndex2 == 2) {
        var state = 1
      }
      axios.post('acare/acare_order_list', {
        page: page,
        pagesize: 999,
        state: state
      })
        .then(res => {
          var data = res.data.data
          if (page == 1) {
            this.list2 = data
          } else {
            this.list2 = this.list2.concat(data)
          }
        })
        .catch(error => {
          console.error(error);
        });
    },
    testTabClick(index) {
      this.tabIndex = index
      this.tabIndex2 = 0
      if (index == 0) {
        this.tablist2 = ["全部", "待支付", "待发货", "待收货", "已完成"];
        this.getList()
      } else if (index == 1) {
        this.tablist2 = ["全部", "待支付", "已完成"];
        this.getList2()
      }
    },
    detail(id) {
      router.push({
        name: 'orderDetail', params: {
          order_id: id
        }
      })
    },
    detail2(id) {
      router.push({
        name: 'AGCAREorderDetail', params: {
          order_id: id
        }
      })
    },
  },
};
</script>

<style lang="less" scoped>
.container {
  background: #f6f6f6;
  width: 100vw;
}

.main {
  width: 1200px;
  margin: 20px auto;
}

.mw200 {
  max-width: 200px;
}

.border_b {
  border-bottom: 1px solid #eeeff0;
}

.right_part {
  background: #fdfdfd;
  border-radius: 13px;
  overflow: hidden;
  padding: 25px 20px;

  .selecct1 {
    width: 169px;
    padding: 0 15px 0 20px;
    margin-left: auto;
    margin-right: 0;
    height: 46px;
    line-height: 46px;
    border-radius: 6px;
    border: 1px solid #e7e7e7;

    .arrow {
      width: 10px;
      height: 7px;
      display: block;
      object-fit: contain;
    }
  }

  .product_part1 {
    border-radius: 4px;
    border: 1px solid #e2e2e2;
    margin-bottom: 20px;
    word-wrap: break-word;
    word-break: normal;

    .pt13 {
      padding-top: 13px;
    }

    .product_top {
      height: 44px;
      line-height: 44px;
      background: #f3f3f3;
      border-radius: 4px;
    }

    .tab1 {
      width: 226px;

      .imgs1 {
        width: 85px;
        height: 85px;
        display: block;
        object-fit: cover;
        overflow: hidden;
        margin-right: 17px;
      }
    }

    .tab2 {
      width: 79px;
    }

    .tab3 {
      width: 126px;
      margin-right: 27px;
    }

    .tab4 {
      width: 82px;
      margin-right: 20px;

      .zt_btn1,
      .zt_btn2,
      .zt_btn3 {
        width: 69px;
        text-align: center;
        height: 26px;
        line-height: 26px;
        background: #0c0c0c;
        border-radius: 5px;
        font-size: 12px;
        color: #ffffff;
        margin: 0 auto;
      }
    }

    .tab5 {
      width: 69px;
      margin-right: 26px;
    }
  }
}
</style>
<style lang="less">
.select {
  box-shadow: none;
  line-height: 100%;

  input,
  .el-select__wrapper {
    width: 100%;
    height: 100%;
    box-shadow: none;
    border: none;
  }
}
</style>