<template>
  <div class="container">
    <Header @input="input"></Header>
    <div class="main pr pt68 flex">
      <Mine></Mine>
      <div class="right_part flex-grow-1 ml20">
        <div class="flex-box mb20">
          <div class="tablist fs22 fwb col3 lh30 flex-grow-1">我的优惠券</div>
          <!-- <div class="selecct1 flex-box">
            <div class="flex-1 m-ellipsis fs14 col3 fwb pr40">全部</div>
            <img
              class="arrow"
              src="../assets/static/icon/arrow_down.png"
              alt=""
            />
          </div> -->
          <div class="selecct1 flex-box cursor pr">
            <el-select class="select"
              style="position: absolute;top: 0;left: 0;width: 100%;height: 100%;background-color: transparent;border: none;box-shadow: none"
              v-model="tabIndex" @change="tab" placeholder="请选择">
              <el-option v-for="(item, index) in tablist" :label="item" :value="index"></el-option>
            </el-select>
          </div>
        </div>
        <div class="flex flex-wrap">
          <!-- <div
            v-for="(item, index) in 2"
            :key="index"
            class="pr coupons_part mb15 cursor"
          >
            <img
              class="bj"
              src="../assets/static/icon/coupons_bj1.png"
              alt=""
            />
            <div class="main flex-box">
              <div class="left mr25">
                <div class="fwb lh38 tc mb5">
                  <span class="fs16 col10 lh38">¥</span
                  ><span class="fs38 col10 lh38">15</span>
                </div>
                <div class="tc fs13 lh13 col85">满100可用</div>
              </div>
              <div class="flex-grow-1">
                <div class="fs16 col10 fwb lh16 m-ellipsis mb10">
                  全品类通用券
                </div>
                <div class="colb fs13 lh13 mb5 m-ellipsis">限门店使用</div>
                <div class="fs13 col85 lh13 m-ellipsis">2024.12.31前过期</div>
              </div>
              <div class="pr20">
                <div class="pr code_part">
                <img
                  class="code_img"
                  src="../assets/static/images/code.png"
                  alt=""
                />
                <div class="used_bj"></div>
            </div>
                <div class="tc lh11">
                  <span class="col85 fs11">券码 </span
                  ><span class="colcc fs11">12891091</span>
                </div>
              </div>
            </div>
          </div> -->
          <template v-for="(item, index) in list" :key="index">
            <div class="pr coupons_part mb15 cursor">
              <img v-if="item.use_status == 0" class="bj" src="../assets/static/icon/coupons_bj1.png" alt="" />
              <img v-if="item.use_status != 0" class="bj" src="../assets/static/icon/coupons_bj2.png" alt="" />
              <div class="main flex-box">
                <div v-if="item.coupon.type == '1'" class="left mr25">
                  <div class="fwb lh38 tc mb5">
                    <span class="fs16 col10 lh38">¥</span><span class="fs38 col10 lh38">{{ item.coupon.money }}</span>
                  </div>
                  <div v-if="item.coupon.at_least > 0" class="tc fs13 lh13 col85">满{{ item.coupon.at_least }}可用</div>
                  <div v-else class="tc fs13 lh13 col85">无门槛</div>
                </div>
                <div v-if="item.coupon.type == '2'" class="left mr25">
                  <div class="fwb lh38 tc mb5">
                    <span class="fs38 col10 lh38">{{ item.coupon.discount }}折</span>
                  </div>
                </div>
                <div class="flex-grow-1">
                  <div class="fs16 col10 fwb lh16 m-ellipsis mb10">
                    {{ item.coupon.name }}
                  </div>
                  <!-- <div class="colb fs13 lh13 mb5 m-ellipsis">限门店使用</div> -->
                  <div class="fs13 col85 lh13 m-ellipsis">{{ item.end_time_text }} 过期</div>
                </div>
                <div class="pr20">
                  <div class="btn_use colb tc fs13">去使用</div>
                </div>
              </div>
            </div>
          </template>

          <!-- 


          <div v-for="(item, index) in 2" :key="index" class="pr coupons_part mb15 cursor">
            <img class="bj" src="../assets/static/icon/coupons_bj2.png" alt="" />
            <div class="main flex-box">
              <div class="left mr25">
                <div class="fwb lh38 tc mb5">
                  <span class="fs16 col10 lh38">¥</span><span class="fs38 col10 lh38">15</span>
                </div>
                <div class="tc fs13 lh13 colb7b">满100可用</div>
              </div>
              <div class="flex-grow-1">
                <div class="fs16 col10 fwb lh16 m-ellipsis mb10">
                  全品类通用券
                </div>
                <div class="colb fs13 lh13 mb5 m-ellipsis">限门店使用</div>
                <div class="fs13 col85 lh13 m-ellipsis">2024.12.31前过期</div>
              </div>
              <div class="pr20">
                <div class="btn_used colb tc fs13">已使用</div>
              </div>
            </div>
          </div>
          <div v-for="(item, index) in 2" :key="index" class="pr coupons_part mb15 cursor">
            <img class="bj" src="../assets/static/icon/coupons_bj2.png" alt="" />
            <div class="main flex-box">
              <div class="left mr25">
                <div class="fwb lh38 tc mb5">
                  <span class="fs16 col10 lh38">¥</span><span class="fs38 col10 lh38">15</span>
                </div>
                <div class="tc fs13 lh13 col85">满100可用</div>
              </div>
              <div class="flex-grow-1">
                <div class="fs16 col10 fwb lh16 m-ellipsis mb10">
                  全品类通用券
                </div>
                <div class="colb fs13 lh13 mb5 m-ellipsis">限门店使用</div>
                <div class="fs13 col85 lh13 m-ellipsis">2024.12.31前过期</div>
              </div>
              <div class="pr20">
                <div class="pr code_part">
                  <img class="code_img" src="../assets/static/images/code.png" alt="" />
                  <div class="used_bj"></div>
                </div>
                <div class="tc lh11">
                  <span class="colb7b fs11">券码 </span><span class=" fs11 text_use colb7b">12891091</span>
                </div>
              </div>
            </div>
          </div>
          <div v-for="(item, index) in 2" :key="index" class="pr coupons_part mb15 cursor">
            <img class="bj" src="../assets/static/icon/coupons_bj2.png" alt="" />
            <div class="main flex-box">
              <div class="left mr25">
                <div class="fwb lh38 tc mb5">
                  <span class="fs16 col10 lh38">¥</span><span class="fs38 col10 lh38">15</span>
                </div>
                <div class="tc fs13 lh13 colb7b">满100可用</div>
              </div>
              <div class="flex-grow-1">
                <div class="fs16 col10 fwb lh16 m-ellipsis mb10">
                  全品类通用券
                </div>
                <div class="colb fs13 lh13 mb5 m-ellipsis">限门店使用</div>
                <div class="fs13 col85 lh13 m-ellipsis">2024.12.31前过期</div>
              </div>
              <div class="pr20">
                <div class="btn_used colb tc fs13">已过期</div>
              </div>
            </div>
          </div> -->
        </div>
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>
<script>
import { useHead } from "@unhead/vue";
import axios from "@/axios";
import { getCurrentInstance } from "vue";
import router from "@/router";
import { ElMessage, ElMessageBox } from "element-plus";
export default {
  name: "index",

  setup() {
    useHead({
      title: "个人中心",
      meta: [
        { name: "description", content: "This is my page description" },
        { property: "og:title", content: "My Page Title" },
        { property: "og:description", content: "This is my page description" },
        { property: "og:url", content: "https://example.com/my-page" },
        { property: "og:image", content: "https://example.com/my-image.jpg" },
      ],
      link: [{ rel: "canonical", href: "https://example.com/my-page" }],
    });
  },
  data() {
    return {
      oss: getCurrentInstance().appContext.config.globalProperties.$oss,
      w1000: getCurrentInstance().appContext.config.globalProperties.$w1000,
      w500: getCurrentInstance().appContext.config.globalProperties.$w500,
      list: [],
      tablist: ["待使用", "已使用", "已过期"],
      tabIndex: 0,
      order: '',
      pay_price: 0,
      user_coupon_ids: ''
    };
  },
  created() {
    this.pay_price = this.$route.params.pay_price
    this.user_coupon_ids = this.$route.params.user_coupon_ids
    this.order = this.$route.params.order
    this.getList()
  },
  methods: {
    getList() {
      axios.post('xilushop/user/my_coupon', {
        tab: parseInt(this.tabIndex),
        order: this.order,
        user_coupon_ids: this.user_coupon_ids
      }).then(res => {
        for (var i in res.data.data) {
          res.data.data[i].coupon.at_least = parseFloat(res.data.data[i].coupon.at_least)
          res.data.data[i].coupon.money = parseFloat(res.data.data[i].coupon.money)
          res.data.data[i].coupon.discount = parseFloat(res.data.data[i].coupon.discount / 10)
        }
        this.list = res.data.data
      })
    },
    tab(index) {
      console.log(index);
      this.tabIndex = index;
      this.getList()
    },
  },
};
</script>

<style lang="less" scoped>
.container {
  background: #f6f6f6;
  width: 100vw;
}

.main {
  width: 1200px;
  margin: 20px auto;
}

.mw200 {
  max-width: 200px;
}

.border_b {
  border-bottom: 1px solid #eeeff0;
}

.right_part {
  background: #fdfdfd;
  border-radius: 13px;
  overflow: hidden;
  padding: 25px 20px;

  .selecct1 {
    padding: 0 15px 0 20px;
    margin-left: auto;
    margin-right: 0;
    height: 46px;
    line-height: 46px;
    border-radius: 6px;
    border: 1px solid #e7e7e7;

    .arrow {
      width: 10px;
      height: 7px;
      display: block;
      object-fit: contain;
    }
  }

  .coupons_part {
    width: 350px;
    height: 97px;
    margin-right: 30px;

    .bj {
      width: 350px;
      height: 97px;
      display: block;
      object-fit: contain;
    }

    .main {
      width: 350px;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 2;

      .left {
        width: 95px;
      }

      .code_part {
        width: 41px;
        height: 41px;
        margin: 0 auto 5px;
      }

      .code_img {
        width: 41px;
        height: 41px;
        display: block;
        object-fit: contain;

      }
    }
  }

  .coupons_part:nth-of-type(2n) {
    margin-right: 0;
  }

  .btn_use,
  .btn_used {
    width: 69px;
    text-align: center;
    height: 23px;
    line-height: 23px;
    background: #f0f0f7;
    border-radius: 12px;
    font-size: 13px;
  }

  .btn_used {
    background: #f0f0f7;
    color: #b7bdca;
  }

  .colb7b {
    color: #b7bdca;
  }

  .used_bj {
    width: 100%;
    height: 100%;
    background: #B3B3B3;
    opacity: 0.8;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
  }

  .text_use {
    text-decoration: line-through;
  }
}

.lh38 {
  height: 38px;
}
</style>