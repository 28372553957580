<template>
  <div class="container pr pt68">
    <Header @input="input"></Header>
    <div class="main">
      <div class="flex border_b pb30 mb30">
        <img class="imgs mr50" :src="oss + data.thumb_image + w500" alt="" />
        <div class="flex-grow-1">
          <div class="m-ellipsis fs24 col10 fwb lh44 mb10">
            {{ data.name }}
          </div>
          <div class="fs24 col10 fwb lh44 mb45">{{ data.sub_name }}</div>
          <!-- <div class="fs20 col85">黑色*1</div> -->
        </div>
      </div>
      <div class="fs28 col10 fwb lh60 mb20">
        基本信息：请确认或填写你的信息。
      </div>
      <div class="flex flex-wrap mb20">
        <div class="part1 mr30 mb20 flex-box fs14">
          <div class="col6">姓名</div>
          <input class="flex-grow-1 tr col10" v-model="nickname" type="text" />
        </div>
        <div class="part1 mr30 mb20 flex-box fs14">
          <div class="col6">联系电话</div>
          <input class="flex-grow-1 tr col10" disabled :value="user_mobile" type="number" />
        </div>
        <div class="part1  pr mr30 cursor">
          <div class="mb20 flex-box fs14">
            <div class="col6">省/市/区</div>
            <input v-if="!city_show" @click="city_show = true" readonly class="flex-grow-1 tr col10 mr10" :value="city"
              type="text" />
            <el-cascader v-if="city_show" class="jlpMySelect jlpMySelect2 jlpMySelect3 mr30 mb20 flex-box fs14"
              size="large" :options="options" v-model="selectedOptions" @change="addressChange"
              clear-icon="el-icon-minus" style="
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
              height: 48px;
              z-index: 2;
              border: none;
              background-color: transparent;
            text-align: right!important; ">
            </el-cascader>
            <!-- <img
              class="arrow_down"
              src="../assets/static/icon/arrow_down.png"
              alt=""
            /> -->
          </div>
        </div>
        <div class="part1 mr30 mb20 flex-box fs14">
          <div class="col6">详细地址</div>
          <input class="flex-grow-1 tr col10" v-model="address" type="text" />
        </div>
      </div>
      <div class="fs28 col10 fwb lh60 mb20">
        设备信息:请确认或填写设备信息。
      </div>
      <div class="flex mb30">
        <img class="imgs2 mr20" :src="oss + data.thumb_image + w500" alt="" />
        <div class="flex-grow-1">
          <div class="fs24 col10 fwb lh44 mb20">
            {{ data.name }}
          </div>
          <div class="fs20 col85 lh28 mb20">{{ data.sub_name }}</div>
          <div class="fs20 col85 lh28 mb20">仪器编号：{{ data.goods_no }}</div>
        </div>
      </div>
      <div class="part1 mr30 mb20 flex-box fs14 cursor pr">
        <!-- <div class="col6 flex-grow-1">购买日期</div>
        选择任意日期： -->
        <el-date-picker class="jlpMySelect" style="
            position: absolute;
            top: 0;
            left: 0px;
            width: 100%;
            height: 100%;
            border: none;
            background-color: transparent !important;
            box-shadow: none;
          " prefix-icon='el-icon-minus' clear-icon='' v-model="value1" type="date" placeholder="购买日期">
        </el-date-picker>
        <!--  -->
        <img class="arrow_down" src="../assets/static/icon/arrow_down.png" alt="" />
      </div>
      <textarea class="textarea fs14 mb40" placeholder="请描述您的设备故障" v-model="problem_content" name="" id=""></textarea>
      <div class="fs28 col10 fwb lh60 mb35">
        故障照片：请上传对应的故障照片。
      </div>
      <div class="flex flex-wrap mb20">
        <div v-for="(item, index) in problem_images_arr" :key="index" class="part2 mb20">
          <div class="pr">
            <img class="img" :src="item" alt="" />
            <div @click="uploadDel(index)" class="close_part cursor">
              <img class="icon" src="../assets/static/icon/close_icon.png" alt="" />
            </div>
          </div>
        </div>
        <el-upload class="avatar-uploader" action="https://ag-test.p.xilukeji.com/api/common/upload"
          :show-file-list="false" multiple="true" :on-change="handleChange" :on-success="handleAvatarSuccess">
          <div class="upload cursor">
            <img class="icon" src="../assets/static/icon/uppload_icon.png" alt="" />
          </div>
        </el-upload>
      </div>
      <div class="fs28 col10 fwb lh60 mb20">
        拆机检查：选择是否同意拆机检查。
      </div>
      <div class="flex flex-wrap mb20 tab2">
        <div class="tablist mb20" v-for="(item, index) in tablist1" :key="index" @click="testTabClick1(index)"
          :class="tabIndex1 == index ? 'active' : 'unactive'">
          {{ item }}
        </div>
      </div>
      <div class="fs28 col10 fwb lh60 mb20">
        皮肤测试：选择是否同意皮肤测试。
      </div>
      <div class="flex flex-wrap mb20 tab2">
        <div class="tablist mb20" v-for="(item, index) in tablist2" :key="index" @click="testTabClick2(index)"
          :class="tabIndex2 == index ? 'active' : 'unactive'">
          {{ item }}
        </div>
      </div>
      <div class="fs28 col10 fwb lh60 mb20">
        联系信息：选择方便联系的时间段。
      </div>
      <div class="flex flex-wrap mb15">
        <div class="part1 mr30 mb20 flex-box fs14">
          <div class="col6">联系电话</div>
          <input class="flex-grow-1 tr col10" v-model='call_mobile' type="number" />
        </div>
        <div class="part1 mr30 mb20 flex-box fs14 cursor pr">
          <div class="col6 flex-grow-1">选择时间段</div>
          <el-time-picker prefix-icon="el-icon-minus" format="HH:mm" value-format="HH:mm" style="
              position: absolute;
              top: 0;
              left: 30px;
              width: clac(100% - 20px);
              height: 100%;
              border: 0px;
              background-color: transparent;
              box-shadow: none;
            " is-range v-model="value2" range-separator="至" start-placeholder="开始时间" end-placeholder="结束时间"
            placeholder="选择时间范围">
          </el-time-picker>
          <img class="arrow_down" src="../assets/static/icon/arrow_down.png" alt="" />
        </div>
      </div>
      <div @click="submit" class="btn cursor fs20 colf">提交售后</div>
    </div>
    <Footer></Footer>
  </div>
</template>
<script>
import { useHead } from "@unhead/vue";
import { regionData, CodeToText } from "element-china-area-data";
import { ElMessage } from "element-plus";
import axios from "@/axios";
import router from "@/router";
import { getCurrentInstance } from "vue";
import { ref } from "vue";
const size = (ref < "default") | "large" | ("small" > "default");
const value1 = ref("");

export default {
  name: "applyAfterSales",
  setup() {
    useHead({
      title: "售后申请",
      meta: [
        { name: "description", content: "This is my page description" },
        { property: "og:title", content: "My Page Title" },
        { property: "og:description", content: "This is my page description" },
        { property: "og:url", content: "https://example.com/my-page" },
        { property: "og:image", content: "https://example.com/my-image.jpg" },
      ],
      link: [{ rel: "canonical", href: "https://example.com/my-page" }],
    });
  },
  data() {
    return {
      oss: getCurrentInstance().appContext.config.globalProperties.$oss,
      w1000: getCurrentInstance().appContext.config.globalProperties.$w1000,
      w500: getCurrentInstance().appContext.config.globalProperties.$w500,
      tabIndex1: 1,
      tablist1: ["同意拆机检查", "不同意拆机检查"],
      tabIndex2: 1,
      tablist2: ["同意皮肤测试", "不同意皮肤测试"],
      options: regionData,
      selectedOptions: [],
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now();
        },
        shortcuts: [
          {
            text: "今天",
            onClick(picker) {
              picker.$emit("pick", new Date());
            },
          },
          {
            text: "昨天",
            onClick(picker) {
              const date = new Date();
              date.setTime(date.getTime() - 3600 * 1000 * 24);
              picker.$emit("pick", date);
            },
          },
          {
            text: "一周前",
            onClick(picker) {
              const date = new Date();
              date.setTime(date.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", date);
            },
          },
        ],
      },
      value1: "",
      value2: "",
      nickname: '',
      city: '',
      city_show: false,
      user_mobile: '',
      address: '',
      problem_content: '',
      problem_images: '',
      problem_images_arr: [],
      call_mobile: '',
      call_time_desc: '',
      buy_date: '',
      device_id: '',
      data: ''
    };
  },
  created() {
    this.device_id = this.$route.params.device_id
    this.getUser()
    this.getDetail()
  },
  methods: {
    uploadDel(index) {
      (this.problem_images_arr).splice(index, 1)
    },
    handleAvatarSuccess(e) {
      this.problem_images_arr.push(e.data.fullurl)
    },
    getUser() {
      axios.post('xilushop/user/info', {})
        .then(res => {
          if (res.data) {
            this.nickname = res.data.nickname;
            if (res.data.city) {
              this.city_show = false
            }
            this.city = res.data.city;
            this.user_mobile = res.data.mobile;
            this.address = res.data.address;
          }
        })
        .catch(error => {
          console.error(error);
        });
    },
    getDetail() {
      axios.post('ag/device_detail', {
        device_id: this.device_id
      }).then(res => {
        this.data = res.data
      })
    },
    submit() {
      var data = {};
      data.name = this.nickname;
      data.mobile = this.user_mobile;
      data.city = this.city;
      data.address = this.address;
      if (!data.name || !data.mobile || !data.city || !data.address) {
        ElMessage.error('请填写完整基本信息')
        return false;
      }
      data.buy_date = this.value1;
      data.problem_content = this.problem_content;
      if (!data.problem_content) {
        ElMessage.error('请填写故障内容')
        return false;
      }
      if (this.problem_images_arr[0]) {
        data.problem_images = this.problem_images_arr.join(',');
      } else {
        uni.showToast({
          title: '请上传故障照片',
          icon: 'none'
        })
        return false;
      }
      data.chai_test = this.tabIndex1 == 1 ? 0 : 1;
      data.skin_test = this.tabIndex2 == 1 ? 0 : 1;
      data.call_mobile = this.call_mobile;
      if (!data.call_mobile) {
        ElMessage.error('请填写联系电话')
        return false;
      }
      data.call_time_desc = this.value2 != '' ? this.value2[0] + '-' + this.value2[1] : '';
      data.device_id = this.device_id;
      console.log(data)
      axios.post('ag/create_repair', data)
        .then(res => {
          console.log(res);
          if (res.code == 1) {
            ElMessage.success(res.msg)
            setTimeout(function () {
              router.replace({
                name: 'afterSales'
              })
            }, 1500)
          } else {
            ElMessage.error(res.msg)
          }
        });
    },
    addressChange(arr) {
      console.log(arr);
      var arr = []
      var list = this.options;
      for (var i in list) {
        if (list[i].value == arr[0]) {
          arr.push(list[i].label)
          var list2 = list[i].children
          for (var i2 in list2) {
            if (list2[i2].value == arr[1]) {
              arr.push(list2[i2].label)
              var list3 = list2[i2].children
              for (var i3 in list3) {
                if (list3[i3].value == arr[2]) {
                  arr.push(list3[i3].label)
                }
              }
            }
          }
        }
      }
      this.city = arr.join('-');
    },
    testTabClick1(index) {
      console.log(index);
      this.tabIndex1 = index;
    },
    testTabClick2(index) {
      console.log(index);
      this.tabIndex2 = index;
    },
    handleCommand(command) {
      this.$message("click on item " + command);
      console.log(command);
    },
  },
};
</script>
<style lang="less" scoped>
.container {
  background: #fff;
  width: 100vw;
  overflow-x: hidden;
}

.main {
  width: 805px;
  margin: 40px auto 85px;
}

.imgs {
  width: 173px;
  height: 173px;
  display: block;
  object-fit: cover;
}

.border_b {
  border-bottom: 1px solid #e3e3e3;
}

.imgs2 {
  width: 144px;
  height: 144px;
  display: block;
  object-fit: cover;
}

.part1 {
  width: 386px;
  height: 48px;
  line-height: 48px;
  padding: 0 25px 0 20px;
  background: #f1f2f4;
  border-radius: 8px;

  input {
    background: transparent;
    padding: 0;
    margin: 0;
    font-weight: 400;
    font-size: 14px;
    color: #101010;
  }
}

.part11 {
  padding: 0;
}

.part3 {
  background: none;
}

.part1:nth-of-type(2n) {
  margin-right: 0;
  line-height: 20px;
}

.textarea {
  border: none;
  resize: none;
  /* 去除选中后的边框 */
  outline: none;
  resize: none;
  width: 805px;
  min-height: 147px;
  background: #f5f5f5;
  border-radius: 8px;
  padding: 20px;
}

.part2 {
  width: 108px;
  margin-right: 10px;

  .img {
    width: 108px;
    height: 108px;
    display: block;
    object-fit: cover;
    border-radius: 9px;
    overflow: hidden;
    border: 1px solid #dddddd;
    margin: 0 auto 12px;
  }

  .close_part {
    width: 100%;
    height: 20px;
    background: rgba(0, 0, 0, 0.4);
    border-radius: 0px 0px 9px 9px;
    position: absolute;
    z-index: 2;
    left: 0;
    bottom: 0;
    padding: 3px 0;

    .icon {
      width: 13px;
      height: 13px;
      display: block;
      object-fit: cover;
      margin: 0 auto;
    }
  }
}

.upload {
  width: 106px;
  height: 106px;
  background: #f1f2f4;
  border-radius: 9px;
  padding: 44px 0;

  .icon {
    width: 18px;
    height: 18px;
    display: block;
    object-fit: cover;
    margin: 0 auto;
  }
}

.arrow_down {
  width: 10px;
  height: 7px;
  display: block;
  object-fit: cover;
  margin-left: auto;
  margin-right: 0;
}

.btn {
  width: 236px;
  text-align: center;
  height: 60px;
  line-height: 60px;
  background: #bba17b;
  border-radius: 5px;
  margin: 15px auto 0;
}

.textarea::placeholder {
  color: #666 !important;
}

input::placeholder {
  color: #666 !important;
}

.tab2 {
  .tablist {
    width: 390px;
    height: 76px;
    border-radius: 7px;
    overflow: hidden;
    margin-right: 20px;
    font-size: 15px;
    color: #101010;
    line-height: 15px;
    text-align: center;
  }

  .tablist:nth-of-type(2n) {
    margin-right: 0;
  }

  .active,
  .tablist:hover {
    line-height: 74px;

    background: #ffffff;
    border: 1px solid #bba17b;
  }

  .unactive {
    line-height: 76px;

    background: #f5f5f5;
  }
}

// 日历

.demo-date-picker {
  display: flex;
  width: 100%;
  padding: 0;
  flex-wrap: wrap;
  background: transparent;
  border: none;
  box-shadow: none;
}

.demo-date-picker .block {
  padding: 30px 0;
  text-align: center;
  border-right: solid 1px var(--el-border-color);
  flex: 1;
  background: transparent;
  border: none;
  box-shadow: none;
}

.demo-date-picker .block:last-child {
  border-right: none;
}

.demo-date-picker .demonstration {
  display: block;
  color: var(--el-text-color-secondary);
  font-size: 14px;
  margin-bottom: 20px;
}

// 时间
.el-date-editor .el-icon-date,
.el-date-picker {
  display: none !important;
  border: none !important;
  box-shadow: inset !important;
}

.el-date-editor .el-range__icon {
  font-size: 0px !important;
  opacity: 0 !important;
}
</style>
<style lang="less">
.jlpMySelect {
  border: 1px solid transparent !important;
  background-color: transparent !important;
  height: 100% !important;
  width: 100%;
  border-radius: 4px;
  color: #666666;

  input,
  .el-input__wrapper {
    background-color: transparent !important;
    border: none;
    box-shadow: none;
    color: #666666;
  }

  input::placeholder {
    color: #666666 !important;
  }

}

.jlpMySelect2 {

  // text-align: right!important;
  input,
  .el-input__wrapper {
    // text-align: right!important;
    padding-right: 20px;
  }

  input:focus,
  .el-input__wrapper {
    border: none !important;
    box-shadow: none !important
  }

  .el-input__suffix {
    display: none;
  }

}

.jlpMySelect2:focus {
  border: none !important;
  box-shadow: none !important
}

.jlpMySelect2:hover {
  border: none !important;
  box-shadow: none !important
}
</style>