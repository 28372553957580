import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "../src/assets/css/reset.css";
import "../src/assets/css/style.css";
import Header from "../src/components/header.vue";
import Mine from "../src/components/mine.vue";
import Footer from "../src/components/footer.vue";
import * as api from "./request/api.js"; // 导入api接口
import '@/utils/rem.js';
import { createHead } from '@unhead/vue';
import ElementPlus from 'element-plus';
import zhCn from 'element-plus/es/locale/lang/zh-cn'
import 'element-plus/theme-chalk/index.css';
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'
const app = createApp(App);
const head = createHead()
app.use(head)
app.config.productionTip = false; // 关闭生产提示
app.component("Header", Header);
app.component("Footer", Footer);
app.component("Mine", Mine);
app.config.globalProperties.$api = api;
app.use(store).use(router).mount("#app");
app.use(ElementPlus, {
    locale: zhCn,
  })
app.component('VueDatePicker', VueDatePicker);
app.use(router);

// app.mount('#app')

app.config.globalProperties.$oss = "https://aglobal-oss.oss-cn-shanghai.aliyuncs.com";
app.config.globalProperties.$w1000 = "?x-oss-process=image/resize,m_mfit,w_1000,limit_0";
app.config.globalProperties.$w500 = "?x-oss-process=image/resize,m_mfit,w_500,limit_0";

