<template>
  <div class="container">
    <Header @input="input"></Header>
    <div class="main pr pt68 flex">
      <Mine></Mine>
      <div class="right_part flex-grow-1 ml20">
        <div class="flex-box mb40">
          <div class="flex-grow-1 fs22 col3 fwb lh30">收货地址</div>
          <img @click="sucPop = true" class="add_device cursor" src="../assets/static/icon/add_device.png" alt="" />
          <div @click="sucPop = true" class="colb fs20 cursor">新增地址</div>
        </div>
        <div v-for="(item, index) in list" :key="index" class="flex-box mb40 pl5 pr15 pr address_part">
          <div class="fs15 col9 fwb add_name mr20">地址{{ index + 1 }}</div>
          <div class="flex-grow-1">
            <div class="flex-box mb5">
              <div v-if="item.default_status == 1" class="def_label mr10 colf fs12">默认</div>
              <div class="flex-grow-1 fs16 col10 fwb lh28 m-ellipsis">
                {{ item.Area.province + item.Area.city + item.Area.region + item.address }}
              </div>
            </div>
            <div class="fs14 col89 lh28 flex">
              <div class="mr60 col89 fs14 mw300 m-ellipsis">{{ item.name }}</div>
              <div class="flex-grow-1 col89 fs14">{{ item.mobile }}</div>
            </div>
          </div>
          <div v-if="item.default_status == 0" @click="set_default(item.id)" class="cursor ml10 colb label fs14">设为默认
          </div>
          <div v-if="order == 1 || device_id > 0 || order_goods_id > 0 || repair_id > 0" @click="choose(item.id)" class="cursor ml10 colb label fs14">选用该地址
          </div>
          <div @click="edit(index)" class="cursor ml10 colb label fs14">编辑地址</div>
        </div>
      </div>
    </div>
    <!-- 新增地址弹窗-->
    <Popup :visible="sucPop" :confirmText="false" :cancleText="false" @close="closeSuc">
      <div class="popup">
        <div class="flex-box mb25">
          <div class="flex-grow-1 pl5 fs18 col0 fwb lh26">{{ address_id ? '编辑地址' : '新增地址' }}</div>
          <img @click="closeSuc" class="close cursor" src="../assets/static/icon/close_pop.png" alt="" />
        </div>
        <div class="pl5 fs14 col6 lh14 mb15">*姓名</div>
        <input class="pop_input plr20 mb20 fs14 col10" v-model="name" type="text" />
        <div class="pl5 fs14 col6 lh14 mb15">联系电话</div>
        <input class="pop_input plr20 mb20 fs14 col10" v-model="mobile" type="number" />
        <div class="pl5 fs14 col6 lh14 mb15">所在地区</div>
        <div class="pr pop_input">
          <el-cascader popper-class="tl" placeholder="省/市/区" class="jlpMySelect jlpMySelect2 mr30 mb20 flex-box fs14 tl" size="large"
            :options="options" v-model="selectedOptions" @change="addressChange" clear-icon="el-icon-minus" style="
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
              height: 48px;
              z-index: 2;
              border: none;
              background-color: transparent;
              text-align: left!important;
              
            ">
          </el-cascader>
        </div>
        <!-- <input
                  class="pop_input plr20 mb20 fs14 col10"
                  placeholder="省/市/区"
                  type="text"
                /> -->
        <div class="pl5 fs14 col6 lh14 mb15 mt15">详细地址</div>
        <textarea class="pop_textarea mb25" v-model="address" name="" id=""></textarea>
        <div @click="submit" class="pop_btn colf tc fs18 cursor">保存地址</div>
      </div>
    </Popup>
    <Footer></Footer>
  </div>
</template>
<script>
import { useHead } from "@unhead/vue";
import Popup from "../components/popup.vue";
import { regionData, CodeToText } from "element-china-area-data";
import { ref } from "vue";
import axios from "@/axios";
import { ElMessage, ElMessageBox } from "element-plus";
var btn;
export default {
  name: "index",
  setup() {
    useHead({
      title: "我的地址",
      meta: [
        { name: "description", content: "This is my page description" },
        { property: "og:title", content: "My Page Title" },
        { property: "og:description", content: "This is my page description" },
        { property: "og:url", content: "https://example.com/my-page" },
        { property: "og:image", content: "https://example.com/my-image.jpg" },
      ],
      link: [{ rel: "canonical", href: "https://example.com/my-page" }],
    });
  },
  components: {
    Popup,
  },
  data() {
    return {
      sucPop: false,
      // options: regionData,
      options: [],
      selectedOptions: [],
      address_id: '',
      device_id: '',
      order: '',
      list: [],
      data: '',
      default_status: 1,
      name: '',
      mobile: '',
      address: '',
      order_goods_id: '',
      repair_id: ''
    };
  },
  created() {
    btn = true;
    console.log(regionData)
    this.order_goods_id = this.$route.params.order_goods_id
    this.repair_id = this.$route.params.repair_id
    this.device_id = this.$route.params.device_id
    this.order = this.$route.params.order
    this.getAddressJson()
    this.getList()
  },
  methods: {
    set_default(id) {
      axios.post('xilushop/user_address/set_default', {
        address_id: id
      })
        .then(res => {
          if (res.code == 1) {
            ElMessage.success(res.msg)
            this.getList()
          } else {
            ElMessage.error(res.msg)
          }
        })
        .catch(error => {
          console.error(error);
        });
    },
    edit(index) {
      var data = this.list[index]
      this.address_id = data.id
      this.name = data.name
      this.mobile = data.mobile
      this.selectedOptions = [data.province_id, data.city_id, data.region_id]
      this.default_status = data.default_status
      this.address = data.address
      this.sucPop = true
    },
    addressChange(e) {
      console.log(e)
      this.selectedOptions = e
    },
    getAddressJson() {
      axios.post('index/addressJson2', {
      })
        .then(res => {
          this.options = res.data
        })
        .catch(error => {
          console.error(error);
        });
    },
    getList() {
      axios.post('xilushop/user_address/lists', {
      })
        .then(res => {
          this.list = res.data
        })
        .catch(error => {
          console.error(error);
        });
    },
    closeSuc() {
      this.address_id = ''
      this.name = ''
      this.mobile = ''
      this.selectedOptions = []
      this.address = ''
      this.default_status = 1
      this.sucPop = false;
    },
    submit() {
      var data = {};
      if (!this.name) {
        ElMessage.error('请填写姓名');
        return false;
      }
      data.name = this.name
      var reg = /^1\d{10}$/;
      if (!reg.test(this.mobile)) {
        ElMessage.error('手机号码格式错误');
        return false;
      }
      data.mobile = this.mobile
      if (this.address_id) {
        data.address_id = this.address_id
      }
      data.default_status = this.default_status
      if (!this.selectedOptions[0]) {
        ElMessage.error('请选择地区');
        return false;
      }
      data.province_id = this.selectedOptions[0]
      data.city_id = this.selectedOptions[1]
      data.region_id = this.selectedOptions[2]
      if (!this.address) {
        ElMessage.error('请填写详细地址');
        return false;
      }
      data.address = this.address
      if (!btn) {
        return false;
      }
      btn = false
      axios.post('xilushop/user_address/set_address', data)
        .then(res => {
          if (res.code == 1) {
            ElMessage.success(res.msg)
            this.closeSuc()
            this.getList()
            btn = true
          } else {
            ElMessage.error(res.msg)
            btn = true
          }
        })
        .catch(error => {
          console.error(error);
        });
    },
    choose(id) {
      if (this.order == 1) {
        var pages = getCurrentPages();
        var prevPage = pages[pages.length - 2]; //上一个页面
        prevPage.$vm.address_id = id;
        window.history.back();
      }
      if (this.device_id > 0) {
        ElMessageBox.confirm('确认此地址为顺丰上门收件地址？', '确认提示', {
          confirmButtonText: '确认选择',
          cancelButtonText: '再看看',
          type: 'warning'
        }).then(() => {
          // 确定按钮被点击的回调
          console.log('确定按钮被点击');
          axios.post('ag/send_goods', {
            address_id: id,
            device_id: this.device_id
          })
            .then(res => {
              if (res.code == 1) {
                ElMessageBox.alert(res.msg, '操作成功', {
                  confirmButtonText: '确定',
                  callback: res => {
                    window.history.back();
                  }
                });
              } else {
                ElMessageBox.alert(res.msg, '提示', {
                  confirmButtonText: '确定',
                });
              }
            })
        }).catch(() => {
          // 取消按钮被点击的回调
          console.log('取消按钮被点击');
        });

      }
      if (this.repair_id > 0) {
        ElMessageBox.confirm('确认此地址为顺丰上门收件地址？', '确认提示', {
          confirmButtonText: '确认选择',
          cancelButtonText: '再看看',
          type: 'warning'
        }).then(() => {
          // 确定按钮被点击的回调
          console.log('确定按钮被点击');
          axios.post('ag/repair_send', {
            address_id: id,
            repair_id: this.repair_id
          })
            .then(res => {
              if (res.code == 1) {
                ElMessageBox.alert(res.msg, '操作成功', {
                  confirmButtonText: '确定',
                  callback: res => {
                    window.history.back();
                  }
                });
              } else {
                ElMessageBox.alert(res.msg, '提示', {
                  confirmButtonText: '确定',
                });
              }
            })
        }).catch(() => {
          // 取消按钮被点击的回调
          console.log('取消按钮被点击');
        });
      }
      if (this.order_goods_id > 0) {
        ElMessageBox.confirm('确认此地址为顺丰上门收件地址？', '确认提示', {
          confirmButtonText: '确认选择',
          cancelButtonText: '再看看',
          type: 'warning'
        }).then(() => {
          // 确定按钮被点击的回调
          console.log('确定按钮被点击');
          axios.post('ag/refund_send', {
            address_id: id,
            order_goods_id: this.order_goods_id
          })
            .then(res => {
              if (res.code == 1) {
                ElMessageBox.alert(res.msg, '操作成功', {
                  confirmButtonText: '确定',
                  callback: res => {
                    window.history.back();
                  }
                });
              } else {
                ElMessageBox.alert(res.msg, '提示', {
                  confirmButtonText: '确定',
                });
              }
            })
        }).catch(() => {
          // 取消按钮被点击的回调
          console.log('取消按钮被点击');
        });
      }
    }
  },
};
</script>

<style lang="less" scoped>
.jlpMySelect2,.tl{text-align: left!important;}
.container {
  background: #f6f6f6;
  width: 100vw;
}

.main {
  width: 1200px;
  margin: 20px auto;
}

.mw200 {
  max-width: 200px;
}

.border_b {
  border-bottom: 1px solid #eeeff0;
}

.right_part {
  background: #fdfdfd;
  border-radius: 13px;
  overflow: hidden;
  padding: 35px 30px 35px 20px;

  .add_device {
    width: 16px;
    height: 16px;
    display: block;
    object-fit: contain;
  }

  .address_part::after {
    content: "";
    display: block;
    width: 1px;
    height: 100%;
    background: rgba(151, 151, 151, 0.2);
    position: absolute;
    z-index: 2;
    top: 0;
    left: 62px;
  }

  .add_name {
    width: 58px;
  }

  .label {
    padding: 0 9px;
    height: 23px;
    line-height: 23px;
    background: #f0f0f7;
    border-radius: 12px;
  }

  .def_label {
    width: 31px;
    text-align: center;
    height: 16px;
    line-height: 16px;
    background: #000000;
  }

  .mw300 {
    max-width: 300px;
  }
}

.popup {
  width: 442px;
  padding: 26px 25px 29px 25px;
  background: #ffffff;
  border-radius: 12px;

  .close {
    width: 24px;
    height: 24px;
    display: block;
    object-fit: contain;
    margin-left: auto;
    margin-right: 0;
  }

  .pop_input {
    width: 100%;
    height: 50px;
    line-height: 50px;
    background: #f1f2f4;
    border-radius: 1px;
  }

  .pop_textarea {
    width: 100%;
    padding: 16px 20px;
    height: 112px;
    background: #f1f2f4;
    border-radius: 1px;
    font-weight: 400;
    font-size: 14px;
    color: #101010;
  }

  .pop_btn {
    width: 256px;
    text-align: center;
    height: 55px;
    line-height: 55px;
    background: #bba17b;
    border-radius: 5px;
    margin: 0 auto;
  }
}
</style>