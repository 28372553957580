<template>
  <div class="container">
    <Header @input="input"></Header>
    <div class="main pr pt68 flex">
      <Mine></Mine>
      <div class="right_part flex-grow-1 ml20">
        <div class="lh28 fs20 fwb mt5 mb25">
          <span class="col6">我的设备/</span><span class="col3">使用日历</span>
        </div>
        <div>
          <div class="pr">
            <img class="cursor"
              style="
                width: 20px;
                height: 20px;
                object-fit: contain;
                position: absolute;
                top: 15px;
                right: 70px;
                z-index: 2;
              "
              src="../assets/static/icon/left_icon2.png"
              alt=""
            />
            <img class="cursor"
              style="
                width: 20px;
                height: 20px;
                object-fit: contain;
                position: absolute;
                top: 15px;
                right: 30px;
                z-index: 2;
              "
              src="../assets/static/icon/right_icon2.png"
              alt=""
            />

            <el-calendar class="pr">
              <!-- <template #header="{ date }">
              <span>{{ date }}</span>
              <el-button-group>
             
                <el-button size="small" @click="selectDate('prev-month')">
                  Previous Month
                </el-button>
                <el-button size="small" @click="selectDate('next-month')">
                  Next Month
                </el-button>
  
              </el-button-group>
            </template> -->
              <template #date-cell="{ data }">
                <div :class="data.isSelected ? 'is-selected' : ''">
                  <div class="tc fs14 col10 fwb lh14 pt10 mb10">
                    {{ data.day.split("-").slice(2).join("-") }}
                  </div>

                  <div
                    style="width: 100%"
                    v-for="item in scheduleData"
                    :key="item"
                  >
                    <div
                      :key="item"
                      v-if="
                        item.workingDay.indexOf(
                          data.day.split('-').slice(1).join('-')
                        ) != -1
                      "
                    >
                      <div class="flex flex-center">
                        <div
                          class="mr5 mb5 tips"
                          v-for="(item1, index) in item.info"
                          :key="index"
                          :style="{ background: item1.color }"
                          style="width: 4px; height: 4px; border-radius: 50%"
                        ></div>
                      </div>
                    </div>
                  </div>
                </div>
              </template>
            </el-calendar>
          </div>
        </div>
        <div class="fs20 col10 fwb lh20 mb5">05.09</div>
        <div
          v-for="(item, index) in 5"
          :key="index"
          class="use_part ptb20 border_b pl15 flex"
        >
          <img class="img" src="../assets/static/images/device1.png" alt="" />
          <div class="flex-grow-1">
            <div class="fs14 col10 lh14 m-ellipsis mb15">周护理模式</div>
            <div class="lh14 flex">
              <span class="fs14 col85 mr10">使用时间</span>
              <span class="fs14 col10 flex-grow-1">21:30:26</span>
              <span class="fs14 col85 mr10">使用时长</span>
              <span class="fs14 col10">60s</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
import { useHead } from "@unhead/vue";

export default {
  name: "index",

  setup() {
    useHead({
      title: "订单详情",
      meta: [
        { name: "description", content: "This is my page description" },
        { property: "og:title", content: "My Page Title" },
        { property: "og:description", content: "This is my page description" },
        { property: "og:url", content: "https://example.com/my-page" },
        { property: "og:image", content: "https://example.com/my-image.jpg" },
      ],
      link: [{ rel: "canonical", href: "https://example.com/my-page" }],
    });
  },
  data() {
    return {
      arr: [],
      nowdate: new Date(),
      value: new Date(),
      scheduleData: [
        {
          workingDay: "2024-06-02",
          info: [
            {
              name: "周护理模式",
              color: "#4BA3CC",
            },
            {
              name: "周护理模式",
              color: "#E0419E",
            },
            {
              name: "周护理模式",
              color: "#975998",
            },
            {
              name: "周护理模式",
              color: "#BAD17F",
            },
          ],
        },
        {
          workingDay: "2024-06-03",
          info: [
            {
              name: "周护理模式",
              color: "#4BA3CC",
            },
            {
              name: "周护理模式",
              color: "#E0419E",
            },
          ],
        },
        {
          workingDay: "2024-06-04",
          info: [
            {
              name: "周护理模式",
              color: "#4BA3CC",
            },
            {
              name: "周护理模式",
              color: "#E0419E",
            },
            {
              name: "周护理模式",
              color: "#975998",
            },
            {
              name: "周护理模式",
              color: "#BAD17F",
            },
          ],
        },
        {
          workingDay: "2024-06-05",
          info: [
            {
              name: "周护理模式",
              color: "#4BA3CC",
            },
            {
              name: "周护理模式",
              color: "#E0419E",
            },
            {
              name: "周护理模式",
              color: "#975998",
            },
            {
              name: "周护理模式",
              color: "#BAD17F",
            },
          ],
        },
        {
          workingDay: "2024-06-06",
          info: [
            {
              name: "周护理模式",
              color: "#4BA3CC",
            },
            {
              name: "周护理模式",
              color: "#E0419E",
            },
            {
              name: "周护理模式",
              color: "#975998",
            },
            {
              name: "周护理模式",
              color: "#BAD17F",
            },
          ],
        },
        {
          workingDay: "2024-06-07",
          info: [
            {
              name: "周护理模式",
              color: "#4BA3CC",
            },
            {
              name: "周护理模式",
              color: "#E0419E",
            },
            {
              name: "周护理模式",
              color: "#975998",
            },
            {
              name: "周护理模式",
              color: "#BAD17F",
            },
          ],
        },
      ],
    };
  },
  mounted() {
    this.arr = [
      {
        day: "05",
        icon: require("../assets/static/images/index_banner.png"),
      },
      {
        day: "2024-06-13",
        icon: require("../assets/static/images/index_banner.png"),
      },
    ];
  },

  methods: {},
};
</script>
    
    <style lang="less" scoped>
.container {
  background: #f6f6f6;
  width: 100vw;
}
.main {
  width: 1200px;
  margin: 20px auto;
}
.mw200 {
  max-width: 200px;
}
.border_b {
  border-bottom: 1px solid #eeeff0;
}

.right_part {
  background: #fdfdfd;
  border-radius: 13px;
  overflow: hidden;
  padding: 30px;
  .use_part:last-of-type {
    margin-bottom: 10px;
  }
  .use_part {
    .img {
      width: 43px;
      height: 43px;
      display: block;
      object-fit: contain;
      margin-right: 15px;
    }
  }
}
// .is-selected {
//   width: 78px;
//   height: 50px;
//   background: #ffffff;
//   border-radius: 6px;
//   border: 1px solid #bba17b;
// }
::v-deep .el-calendar {
  border-radius: 5px;
  overflow: hidden;
  margin-bottom: 30px;
}
::v-deep .el-calendar__header {
  border-bottom: none;
  margin-bottom: 30px;
  font-size: 20px;
  color: #101010;
  font-weight: bold;
  padding-top: 20px;
}
::v-deep .el-calendar__header .el-calendar__button-group {
  opacity: 0;
  margin-right: 30px;
  position: absolute;
  z-index: 9;
  top: 29px;
  right: 20px;
}
::v-deep .el-calendar__header .el-calendar__button-group .el-button-group button:nth-of-type(2n){
  font-size: 0;
  widows: 0;
  display: none;
}

/* 一 二 三 四 五 六 日 */
::v-deep .el-calendar-table thead th {
  width: 79px;
  padding: 0;
  font-size: 12px;
  color: #898989;
  padding-bottom: 30px;
  text-align: left;
  padding-left: 30px;
}
::v-deep .el-calendar-table,
::v-deep .el-calendar {
  border: none;
  background: #f8f8f8;
  border-radius: 5px;
}
/* 去除边框 */
::v-deep .el-calendar-table tr td:first-child {
  border: none;
  width: 78px;
  height: 50px;
}
::v-deep .el-calendar-table tr:first-child td {
  border: none;
  width: 78px;
  height: 50px;
}
::v-deep .el-calendar-table__row td {
  border: none;
}
::v-deep .el-calendar-table td .el-calendar-day {
  width: 78px;
  height: 50px;
  padding: 0 !important;
}
::v-deep.el-calendar-table td.is-today,
::v-deep .el-calendar-table td.is-selected .el-calendar-day,
::v-deep .el-calendar-table td .el-calendar-day:hover {
  width: 78px !important;
  height: 50px !important;
  background: #fff;
  border-radius: 6px !important;
  overflow: hidden !important;
  border: 1px solid #bba17b;
}
::v-deep .el-calendar-table td.is-selected {
  background: transparent;
}

/* 当前月样式 */
// ::v-deep .el-calendar-table:not(.is-range){
//   // color: rgba(156, 178, 205, 1);
// //   width: 78px;
// // height: 50px;
// background: #FFFFFF;
// border-radius: 6px;
// border: 1px solid #BBA17B;
// }
</style>


              
    
      
      