
<template>
    <div class="container pr pt68">
      <Header @input="input"></Header>
      <div class="main">
        <div class="fs28 col10 fwb lh86 mb10">您购买的商品</div>
        <div class="ptb30 flex part">
          <img class="img" src="../assets/static/images/device1.png" alt="" />
          <div class="flex-grow-1">
            <div class="fs20 col10 fwb lh44 m-ellipsis">
              DrArrivo 家用脸面部提拉紧致微电流 宙斯二代美容仪
            </div>
            <div class="fs12 col6  lh20 m-ellipsis">
                DrArrivo 家用脸面部提拉紧致微电流 宙斯二代美容仪
            </div>
            <div class="fs24 col10 fwb lh44 mb10">RMB 7,999</div>
            <div class="flex-box fs20">
              <div class="flex-grow-1 col85">黑色*1</div>
            </div>
          </div>
        </div>
        <div class="border_t pt20 pl223">
          <div class="flex-box mb10">
            <div class="fs18 col85 lh24">小计</div>
            <div class="flex-grow-1 fs18 col10 fwb lh44 tr">RMB 1,9990</div>
          </div>
          <div class="flex-box mb10">
            <div class="fs18 col85 lh24">优惠</div>
            <div class="flex-grow-1 fs18 col10 fwb lh44 tr">RMB 5990</div>
          </div>
          <div class="flex-box mb30">
            <div class="fs18 col85 lh24">运费</div>
            <div class="flex-grow-1 fs18 col10 fwb lh44 tr">RMB 24</div>
          </div>
          <div class="border_t pt30 flex-box pb37">
            <div class="fs24 col10 fwb lh44">总计</div>
            <div class="flex-grow-1 tr fs24 col10 fwb lh44">RMB 1,5999</div>
          </div>
        </div>
        <div class="flex-box mt40 mb60 pl5">
          <div class="fs28 col10 fwb lh86">选择支付方式</div>
          <div class="pay_part cursor ml45 flex-box flex-center">
            <img class="icon" src="../assets/static/icon/pay_icon1.png" alt="" />
            <div class="lh28 fs20 col3 fwb">微信支付</div>
          </div>
          <div class="pay_part cursor ml45 flex-box flex-center">
            <img class="icon" src="../assets/static/icon/pay_icon2.png" alt="" />
            <div class="lh28 fs20 col3 fwb">支付宝</div>
          </div>
        </div>
        <div class="border_t pt40 flex-box flex-end ">
          <div class="fs24 col10 fwb lh44 mr45">需支付: RMB 1,5999</div>
          <div class="btn_buy2 colf tc fs20 cursor">立即支付</div>
        </div>
      </div>
      <Footer></Footer>
    </div>
  </template>
       <script>
  import { useHead } from "@unhead/vue";
  export default {
    name: "A CARE",
    setup() {
      useHead({
        title: "确认A CARE订单",
        meta: [
          { name: "description", content: "This is my page description" },
          { property: "og:title", content: "My Page Title" },
          { property: "og:description", content: "This is my page description" },
          { property: "og:url", content: "https://example.com/my-page" },
          { property: "og:image", content: "https://example.com/my-image.jpg" },
        ],
        link: [{ rel: "canonical", href: "https://example.com/my-page" }],
      });
    },

    data() {
      return {  };
    },
    created() {},
    methods: {

    },
  };
  </script>
            <style lang="less" scoped>
  .container {
    background: #fff;
    width: 100vw;
    overflow-x: hidden;
  }
  .main {
    width: 960px;
    margin: 20px auto 100px;
  }
  .add_part {
    width: 380px;
    height: 244px;
    background: #f5f5f5;
    border-radius: 17px;
    padding-top: 67px;
    .icon {
      width: 88px;
      height: 88px;
      display: block;
      object-fit: cover;
      margin: 0 auto 10px;
    }
  }
  
  .border_t {
    border-top: 1px solid #e3e3e3;
  }
  
  .img {
    width: 129px;
    height: 129px;
    display: block;
    object-fit: cover;
    margin-right: 94px;
  }
  .mb8 {
    margin-bottom: 8px;
  }
  .pl223 {
    padding-left: 221px;
  }
  .pb37 {
    padding-bottom: 37px;
  }
  .btn_buy2 {
    width: 236px;
    text-align: center;
    height: 60px;
    line-height: 60px;
    background: #bba17b;
    border-radius: 5px;
  }
  .pay_part {
    width: 286px;
    height: 75px;
    border-radius: 10px;
    border: 1px solid #979797;
    .icon {
      width: 32px;
      height: 32px;
      display: block;
      object-fit: contain;
      margin-right: 7px;
    }
  }
  .pay_part:hover{
    border: 1px solid #bba17b;
  }

  </style>
                     